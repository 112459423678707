import { Injectable } from "@angular/core";
import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";


@Injectable()
export class ResourceTaskOverviewPivot extends PivotProviderBase {

    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "code",
            levelId: "row1",
        };
    }
}
