/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";

import { ResourceCountItem, ResourceItem, SaveArguments, SelectArguments } from "./resource-management-gateway.types";


@Injectable()
export class ResourceManagementGateway extends AppServerGatewayBase {

    selectOverview(args: SelectArguments) {
        return this._postQuery<ResourceItem[]>("resource/list",
            { body: args });
    }

    selectCount(args: SelectArguments) {
        return this._postQuery<ResourceCountItem[]>("resource/count",
            { body: args });
    }

    saveOverview(args: SaveArguments) {
        return this._postQuery<void>("resource/save",
            { body: args });
    }
}
