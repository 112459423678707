import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";

import { SaveOverviewArguments, SelectOverviewArguments, TaskItem } from "./task-management-gateway.types";


@Injectable()
export class TaskManagementGateway extends AppServerGatewayBase {

    selectOverview(args: SelectOverviewArguments) {
        return this._postQuery<TaskItem[]>("resourceTask/overview",
            { body: args });
    }

    saveOverview(args: SaveOverviewArguments) {
        return this._post("resourceTask/save",
            { body: args });
    }
}
