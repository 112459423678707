import { IColumnFilterDictionary, IFilterOption } from "@logex/framework/types";
import { TracingNode } from "./components/tracing-chart/tracing-chart.types";
import { BehaviorSubject, Subscription } from "rxjs";


export enum DataSets {
    SCHEMA = "Schema",
    CENTRAL = "CentralNode",
    FORWARD = "ForwardNodes",
    BACKWARD = "BackwardNodes",
}

export enum TracingColumnEnum {
    ACCOUNT_COST = 0,
    ACCOUNT_COST_AFTER_SPLIT = 1,
    ACCOUNT_COST_AFTER_CORRECTION = 2,
    ACCOUNT_COST_AFTER_TRANSFER = 3,
    RESOURCE_COST = 4,
    RESOURCE_COST_AFTER_TRANSFER = 5,
    RESOURCE_TASK_COST = 6,
    RESOURCE_TASK_COST_AFTER_TRANSFER = 7,
    RESOURCE_TASK_COST_AFTER_SPLIT = 8,
    COST_AND_INCOME = 9,
    ACTIVITY_COST = 10,
}


// ----------------------------------------------------------------------------------
export interface TracingNodeDimensionValue {
    dimension: string;
    value: unknown;
}

export interface TracingNodeDimension extends TracingNodeDimensionValue {
    type: string;
    nameLc: string;
}


export interface TracingNodeExt extends TracingNode {
    incomingCost: number;
    outgoingCost: number;
    dimensions: TracingNodeDimension[];
    grouped?: TracingNodeExt[];
    loaded: boolean;
}


export interface Column {
    column: number;
    titleLc: string;
    allDimensions: string[];
    selectedDimensions: string[];
    blockedBy: Map<string, string[]>;
    options: BehaviorSubject<IFilterOption[]>;
    selectedOptions: IColumnFilterDictionary;
    activeNode: TracingNodeExt | null;
    isCentral: boolean;
    dataSub: Subscription;
    isStale: boolean;
}

