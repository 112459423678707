import * as _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { defer, firstValueFrom, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import {
    IGetVersionsListResponse,
    IVersionResponse,
    IVersionSaveResponse,
    LgScenarioGateway
} from "@logex/framework/lg-layout";
import {
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    UserSettingsService
} from "@logex/framework/lg-application";

import { AppSession } from "@shared/types/app-session";


@Injectable()
export class AppScenarioGateway extends LgScenarioGateway {

    private _session = inject<AppSession>(LG_APP_SESSION);
    private _userSettingsService = inject(UserSettingsService);

    constructor() {
        super();

        const _appConfiguration = inject(LG_APP_CONFIGURATION);

        this._setBaseUrl(_appConfiguration.applicationRoot);
    }


    getVersionsList(): Observable<IGetVersionsListResponse> {
        return this
            .post<IGetVersionsListResponse>("api/version/list",
                {
                    body: { clientId: this._session.clientId }
                })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                            list: null,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }


    setVersion(versionId: number): Observable<IVersionResponse> {
        return defer(async () => {
            try {
                // TODO: Check access rights

                // Store selected scenario in settings, so that it can be applied after reloading
                await firstValueFrom(this._userSettingsService.set({
                    key: { "storageId": "$context" },
                    value: {
                        clientId: this._session.clientId,
                        scenarioId: versionId,
                    }
                }));

                // Patch the current session. After that the page MUST be immediately reloaded,
                // which will be done by LgScenarioDialog.
                this._session.scenarioId = versionId;

                return {
                    ok: true,
                } as IVersionResponse;
            } catch (e) {
                this._console.error("Error saving settings", e);

                return {
                    ok: false,
                } as IVersionResponse;
            }
        });
    }


    updateVersion(versionId: number, scenarioName: string, scenarioDescription: string): Observable<IVersionResponse> {
        return this
            .post<IVersionResponse>("api/version/update", {
                body: {
                    clientId: this._session.clientId,
                    version_id: versionId,
                    scenario_naam: scenarioName,
                    scenario_omschrijving: scenarioDescription
                }
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }


    saveVersionAs(versionId: number, scenarioName: string, scenarioDescription: string): Observable<IVersionSaveResponse> {
        return this
            .post<IVersionSaveResponse>("api/version/saveas", {
                body: {
                    clientId: this._session.clientId,
                    version_id: versionId,
                    scenario_naam: scenarioName,
                    scenario_omschrijving: scenarioDescription
                }
            })
            .pipe(
                switchMap((result) =>
                    this.setVersion(result.id)
                        .pipe(map(() => result))),

                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }


    setVersionPrimary(versionId: number): Observable<IVersionResponse> {
        return this
            .post<IVersionResponse>("api/version/primary", {
                body: {
                    clientId: this._session.clientId,
                    version_id: versionId
                }
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }


    lockVersion(versionId: number, locked: boolean): Observable<IVersionResponse> {
        return this
            ._post<IVersionResponse>("api/version/lock", {
                body: {
                    clientId: this._session.clientId,
                    version_id: versionId,
                    locked
                },
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }


    deleteVersion(versionId: number): Observable<IVersionResponse> {
        return this
            .post<IVersionResponse>("api/version/delete", {
                body: {
                    clientId: this._session.clientId,
                    version_id: versionId
                }
            })
            .pipe(
                switchMap(response => {
                    // If current scenario was deleted - switch to another
                    let res: Observable<any>;
                    if (versionId === this._session.scenarioId) {
                        res = this.getVersionsList()
                            .pipe(
                                switchMap(versions => {
                                    const newScenario = _.first(
                                        _.orderBy(versions.list, ["is_primary", "id"], ["desc", "desc"])
                                    );
                                    return this.setVersion(newScenario.id);
                                })
                            );
                    } else {
                        res = of(true);
                    }
                    return res.pipe(
                        map(() => response)
                    );
                }),

                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        return of({
                            ok: false,
                            access: true,
                        });
                    } else {
                        throw err;
                    }
                })
            );
    }
}
