import { Directive, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild } from "@angular/core";
import { AppDefinitions, AppSession, DefinitionsCostDriver } from "@shared";
import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { RulesGateway } from "./gateway/rules-gateway";
import * as _ from "lodash";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { IDropdownDefinition, LgPromptDialog } from "@logex/framework/ui-core";
import { CostDriverInfo, RuleEditableFields } from "./gateway/rules-gateway.types";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { NEW_RULE_ID, SOURCE_RULE_FILTER_SELECTOR } from "./types";

@Directive()
export abstract class RuleEditorComponentBase implements OnInit {

    protected _promptDialog = inject(LgPromptDialog);
    protected _lgTranslate = inject(LgTranslateService);
    protected _definitions = inject(AppDefinitions);
    protected _session = inject<AppSession>(LG_APP_SESSION);
    protected _ruleFilterSelector = inject(SOURCE_RULE_FILTER_SELECTOR);

    protected abstract _gateway: RulesGateway;

    // @Input() must be in the derived class
    protected abstract id: number;
    protected abstract isReadonly: boolean;
    protected abstract name: string;
    protected abstract costDrivers: CostDriverInfo[];
    protected abstract costDriverId: number;
    protected abstract sourceSelectorConfig: RuleFilterInfo[];
    protected abstract filters: Record<string, unknown[]>;

    @Output("save") protected saveEvent = new EventEmitter<Partial<RuleEditableFields>>();
    @Output("saveAndNew") protected saveAndNewEvent = new EventEmitter<Partial<RuleEditableFields>>();
    @Output("delete") protected deleteEvent = new EventEmitter<void>();
    @Output("close") protected closeEvent = new EventEmitter<void>();

    @ViewChild("scrollerWrap") protected _scrollerWrap: ElementRef<HTMLElement>;

    protected _defaultCostDriver: DefinitionsCostDriver;
    protected _costDriverDropdown: IDropdownDefinition<number>;
    protected _scrollerHeight: number;

    protected _originalValues: Partial<RuleEditableFields>;

    protected _formVisible = true;


    ngOnInit() {

        this._ruleFilterSelector.configure({
            selectOptionsCallback: (uid, row) => this._gateway.selectFilterData({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                uid,
            })
        });

        // Update scroller height on resize
        window.addEventListener("resize", () => this._updateScrollerHeight());
        requestAnimationFrame(() => this._updateScrollerHeight());

        // Clone properties so that they are not updated in the parent component
        this.filters = _.cloneDeep(this.filters);

        this._processCostDrivers();
        this._storeOriginalValues();
        this._updateRuleAfterChanges();
    }

    protected _updateScrollerHeight(): void {
        this._scrollerHeight = this._scrollerWrap.nativeElement.clientHeight;
    }


    protected _storeOriginalValues(): void {
        this._originalValues = _.cloneDeep(this._getFormValues());
    }


    protected _getFormValues(): Partial<RuleEditableFields> {
        return {
            name: this.name,
            costDriverId: this.costDriverId,
            filters: this.filters,
        };
    }


    protected _processCostDrivers(): void {
        const orderedCostDrivers = _.sortBy(
            _.map(this.costDrivers, x => this._definitions.costDriver[x.costDriverId]),
            "orderBy"
        );

        this._defaultCostDriver = _.first(orderedCostDrivers);

        this._costDriverDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: _.map(orderedCostDrivers, x => x),
        });
    }


    protected async _onFilterChange(
        selector: string,
        value: string[]
    ): Promise<void> {
        this.filters = this._updatedFilters(this.filters, selector, value);
    }


    protected _updatedFilters(filters: Record<string, unknown[]>, name: string, value: unknown[]): _.Dictionary<unknown[]> {
        if (filters == null) filters = {};

        if (_.isEqual(filters[name], value)) return filters;

        if (_.isEmpty(value)) {
            delete filters[name];
        } else {
            filters[name] = value;
        }

        if (Object.keys(filters).length === 0) {
            filters = undefined;
        }

        return filters;
    }


    public isChanged(): boolean {
        return !_.isEqual(this._getFormValues(), this._originalValues);
    }


    protected _updateRuleAfterChanges(): void {
    }


    protected async _delete(): Promise<void> {
        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Title"),
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Body"),
            {
                buttons: [
                    {
                        id: "yes",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Yes"),
                        isConfirmAction: true
                    },
                    {
                        id: "no",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.No"),
                        isCancelAction: true
                    }
                ]
            }
        );

        if (response === "yes") {
            this.deleteEvent.next();
        }
    }


    _isNewRule(): boolean {
        return this.id === NEW_RULE_ID;
    }

    _isValid(): boolean {
        return this.costDriverId != null;
    }


    async close(): Promise<void> {
        if (!this.isChanged()) {
            this.closeEvent.next();
            return;
        }

        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleClose.Title"),
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleClose.Body"),
            {
                buttons: [
                    {
                        id: "save",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleClose.Save"),
                        isConfirmAction: true
                    },
                    {
                        id: "discard",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleClose.Discard"),
                        isCancelAction: true
                    }
                ]
            }
        );

        if (response === "save") {
            this.save();
            return;
        }

        this.closeEvent.next();
    }


    async reset(): Promise<void> {
        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleReset.Title"),
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleReset.Body"),
            {
                buttons: [
                    {
                        id: "yes",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleReset.Yes"),
                        isConfirmAction: true
                    },
                    {
                        id: "no",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleReset.No"),
                        isCancelAction: true
                    }
                ]
            }
        );

        if (response === "yes") {
            this._doReset();
        }
    }


    protected _doReset(): void {

        // Restoring original values
        const keys = Object.keys(this._originalValues);
        for (const key of keys) {
            this[key] = _.cloneDeep(this._originalValues[key]);
        }

        this._updateRuleAfterChanges();

        // Trigger form re-rendering
        this._formVisible = false;
        requestAnimationFrame(() => this._formVisible = true);
    }


    public save(): void {
        this.saveEvent.next(this._getChanges());
    }

    public saveAndNew(): void {
        this.saveAndNewEvent.next(this._getChanges());
    }

    protected _getChanges() {
        const changes = {};
        const currentValues = this._getFormValues();
        const fields = Object.keys(currentValues);
        for (const field of fields) {
            if (!_.isEqual(this._originalValues[field], currentValues[field])) {
                changes[field] = currentValues[field];
            }
        }
        return changes;
    }

}
