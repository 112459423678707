import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LoadManager, StaleDataService } from "@logex/load-manager";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { SubtaskSpecificationRulesGateway } from "./gateways/subtask-specification-rules-gateway";
import { TransferRuleSectionComponentBase } from "../base/transfer-rule-section-component-base";
import { IAppDefinitions } from "@shared";
import { RuleSection } from "../../types/constants";
import { SubtaskManagementDialog } from "@shared/dialogs/subtask-management-dialog/subtask-management-dialog.component";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";

@Component({
    selector: "mod-subtask-specification-rules",
    templateUrl: "./subtask-specification.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.SubtaskSpecification"),
        LoadManager,
        StaleDataService,
        RulesPivot,
        SubtaskSpecificationRulesGateway,
    ],
})
export class SubtaskSpecificationRulesSectionComponent extends TransferRuleSectionComponentBase {
    private _subtaskManagementDialog = inject(SubtaskManagementDialog);

    _gateway = inject(SubtaskSpecificationRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.SubtaskSpecification;

    _openSubtaskManagement(): void {
        this._subtaskManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["subtask", "costDriver"];
}
