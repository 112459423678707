import * as _ from "lodash";
import { Component, inject, Injectable, TemplateRef, ViewChild } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { getDialogFactoryBase, IDialogComponent, LgDialogFactory, LgDialogRef, LgPromptDialog } from "@logex/framework/ui-core";
import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { IPasteButtonColumnInfo } from "@logex/framework/ui-toolbox";
import { LgPivotInstance, LgPivotInstanceFactory, LogexPivotService } from "@logex/framework/lg-pivot";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { AppSession } from "@shared/types/app-session";
import { AppDefinitions } from "@shared/app-definitions.service";
import { HelpTooltip, PasteResult } from "@shared";

import { TaskManagementGateway } from "./gateways/task-management-gateway";
import { TaskItem } from "./gateways/task-management-gateway.types";
import { ResourceTaskOverviewPivotLevel1, TaskItemKey } from "./pivots/task-management-pivot.types";
import { ResourceTaskOverviewPivot } from "./pivots/task-management-pivot.service";
import { getMatchedPastedData } from "@shared/helpers/copyPaste/pasteHelpers";
import { IDataCell, PasteDialogConfiguration } from "@logex/framework/ui-toolbox/copy-paste/copy-paste.types";


export interface OverviewDialogArguments {
    isReadonly: boolean;
}

export interface TaskManagementDialogResult {
    doReload: boolean;
}

export interface TaskManagementDialogComponent extends DialogMixin<TaskManagementDialogComponent>,
    ModalResultDialogMixin<OverviewDialogArguments, TaskManagementDialogResult> {
}


@Component({
    selector: "mod-task-management-dialog",
    templateUrl: "./task-management-dialog.component.tshtml",
    providers: [
        ...useTranslationNamespace("APP._TaskManagementDialog"),
    ],
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class TaskManagementDialogComponent implements IDialogComponent<TaskManagementDialogComponent> {

    _promptDialog = inject(LgPromptDialog);
    _definitions = inject(AppDefinitions);
    _dialogRef = inject(LgDialogRef<TaskManagementDialogComponent>);
    _lgTranslate = inject(LgTranslateService);
    overviewPivot = inject(ResourceTaskOverviewPivot);
    protected _pivotService = inject(LogexPivotService);
    private _gateway = inject(TaskManagementGateway);
    private _session = inject<AppSession>(LG_APP_SESSION);

    @ViewChild("headerTemplate", { static: true }) _dialogHeaderTemplate: TemplateRef<any>;

    _dialogClass = "lg-dialog lg-dialog--3col";
    _title = this._lgTranslate.translate(".DialogTitle");

    _isSaving = false;
    _isLoading = false;
    _isModified = false;

    _overviewPivot: LgPivotInstance<ResourceTaskOverviewPivotLevel1, any>;
    _originalData: TaskItem[];

    _helpTooltip = HelpTooltip;

    _clipboardDefCopy: IPasteButtonColumnInfo[] = this._getClipboardDef(true);
    _clipboardDefPaste: IPasteButtonColumnInfo[] = this._getClipboardDef(false);

    _pasteConfiguration: PasteDialogConfiguration = {
        allowNullsToZeros: false,
        allowSkipErrors: true,
        allowPasteMode: true,
        nullsToZeros: false,
        skipErrors: false,
        pasteMode: "update"
    };


    // ----------------------------------------------------------------------------------
    //
    constructor() {
        const _pivotInstanceFactory = inject(LgPivotInstanceFactory);

        this._initMixins();

        this._copyToClipboard = this._copyToClipboard.bind(this);
        this._pasteFromClipboard = this._pasteFromClipboard.bind(this);
        this._preprocessClipboardData = this._preprocessClipboardData.bind(this);

        this._overviewPivot = _pivotInstanceFactory.create<ResourceTaskOverviewPivotLevel1, any>(this.overviewPivot, this);
    }


    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._initialized = true; // Set earlier so that we can switch tabs

        await this._load();
    }


    protected async _load(): Promise<void> {
        this._isLoading = true;

        this._gateway
            .selectOverview({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId
            })
            .subscribe(data => {
                this._originalData = _.cloneDeep(data);
                this._overviewPivot.build(data);
            })
            .add(() => {
                this._isLoading = false;
            });
    }


    // ----------------------------------------------------------------------------------
    //
    protected _getClipboardDef(forCopy: boolean): IPasteButtonColumnInfo[] {
        let config: IPasteButtonColumnInfo[] = [
            {
                field: "code",
                name: this._lgTranslate.translate(".ResourceTaskOverviewPivot.CodeHeader"),
                key: true,
                type: "string",
                unique: true,
                validateKey: (data: IDataCell): void => {
                    if (data.value.length < 4 || data.value.length > 10) {
                        data.valid = false;
                        data.error = this._lgTranslate.translate(".CopyPasteValidation.WrongCodeLength");
                    }
                }
            },
            {
                field: "name",
                name: this._lgTranslate.translate(".ResourceTaskOverviewPivot.NameHeader"),
                key: false,
                type: "string",
                unique: true,
            },
        ];

        if (forCopy) {
            config = [
                ...config,
                {
                    field: "infoMark",
                    name: this._lgTranslate.translate("APP._.InfoMark"),
                    key: false,
                    type: "null",
                },
                {
                    field: "used",
                    name: this._lgTranslate.translate(".ResourceTaskOverviewPivot.UsedHeader"),
                    key: false,
                    type: "string",
                    unique: true
                },
            ];
        }

        return config;
    }


    _copyToClipboard(): ResourceTaskOverviewPivotLevel1[] {
        return this._overviewPivot.filtered
            .filter(x => !x.isReadonly)
            .map(x => Object.assign({}, x, {
                code: x.code,
                name: x.name
            }));
    }


    _preprocessClipboardData(args: { columns: IPasteButtonColumnInfo[]; data: IDataCell[][]; }): IDataCell[][] {
        for (const row of args.data) {
            const nameCell = row[1];
            if (nameCell.value != null && nameCell.value.length >= 128) {
                nameCell.valid = false;
                nameCell.error = this._lgTranslate.translate(".CopyPasteValidation.WrongNameLength");
            }
        }
        return args.data;
    }


    _pasteFromClipboard(result: PasteResult<TaskItemKey, TaskItem>): void {
        const processedData = getMatchedPastedData(this._overviewPivot.all, result, "code");

        this._overviewPivot.build(Array.from(processedData.values()));

        this._isModified = true;
    }


    // ----------------------------------------------------------------------------------
    //
    _addRow(): void {
        this._isModified = true;
        this._overviewPivot.reattachLeafNode({
            code: "",
            name: "",
        } as ResourceTaskOverviewPivotLevel1);
        this._overviewPivot.refilter();
    }

    _removeWhiteSpaces(row: ResourceTaskOverviewPivotLevel1): void {
        row.name = row.name.trim();
        row.code = row.code.trim();
    }

    _markModified(row: ResourceTaskOverviewPivotLevel1): void {
        row.isModified = true;
        this._isModified = true;
    }


    _deleteRow(row: ResourceTaskOverviewPivotLevel1): void {
        this._overviewPivot.removeLeafNode(row);
        this._overviewPivot.refilter();

        this._isModified = true;
    }

    _getNameErrorCode(row: ResourceTaskOverviewPivotLevel1): string | null {
        if (row.name.length >= 128) {
            return ".ResourceTaskOverviewPivot.InputError.NameMaxLength";
        }

        const nameAlreadyExists = this._overviewPivot.all
            .filter(x => x.code !== row.code)
            .map(x => x.name.trim())
            .includes(row.name.trim());

        if (nameAlreadyExists) {
            return ".ResourceTaskOverviewPivot.InputError.NameExists";
        }

        return null;
    }

    _getCodeErrorCode(row: ResourceTaskOverviewPivotLevel1): string | null {
        if (row.code.length < 4) {
            return ".ResourceTaskOverviewPivot.InputError.CodeMinLength";
        }

        if (row.code.length > 10) {
            return ".ResourceTaskOverviewPivot.InputError.CodeMaxLength";
        }

        const codeIsUnique = this._overviewPivot.all
            .filter(x => x.code === row.code)
            .length === 1;

        if (!codeIsUnique) {
            return ".ResourceTaskOverviewPivot.InputError.CodeExists";
        }

        return null;
    }


    _isNameValid(row: ResourceTaskOverviewPivotLevel1): boolean {
        return !this._getNameErrorCode(row);
    }


    _isCodeValid(row: ResourceTaskOverviewPivotLevel1): boolean {
        return !this._getCodeErrorCode(row);
    }


    // ----------------------------------------------------------------------------------
    //
    private async _doSave(): Promise<boolean> {
        try {
            this._isSaving = true;

            const originalCodes = _.map(this._originalData, x => x.code);
            const currentCodes = _.map(this._overviewPivot.all, x => x.code);

            const removedCodes = _.difference(originalCodes, currentCodes);
            const modifiedCodes = this._overviewPivot.all
                .filter(x => x.isModified)
                .map(x => x.code);

            if (!removedCodes.length && !modifiedCodes.length) return true;

            const modifiedItems = this._overviewPivot.all
                .filter(x => modifiedCodes.includes(x.code));

            await firstValueFrom(this._gateway
                .saveOverview({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    modified: modifiedItems
                        .map(x => ({
                            code: x.code,
                            name: x.name
                        }))
                        .concat(removedCodes.map(code => ({
                            code,
                            name: null
                        })))
                }))
                .catch(() => {
                    this._isSaving = false;
                });

            await firstValueFrom(this._definitions.reload("task"));

            this._isSaving = false;

            this._resolve({ doReload: true });

            return true;
        } catch {
            return false;
        }
    }


    async _save(): Promise<boolean> {
        return this._doSave();
        // If _doSave returns true, then dialog-mixin will automatically close the dialog
    }


    // ----------------------------------------------------------------------------------
    //
    public _isChanged(): boolean {
        return this._isModified;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get _isValid(): boolean {
        return this._overviewPivot && this._overviewPivot.all
            && this._overviewPivot.all
                .filter(x => !x.isReadonly)
                .every(x =>
                    this._isNameValid(x)
                    && this._isCodeValid(x));
    }
}


@Injectable()
export class TaskManagementDialog extends getDialogFactoryBase(TaskManagementDialogComponent, "show") {
    constructor() {
        const _factory = inject(LgDialogFactory);

        super(_factory);
    }
}
