import { Injectable } from "@angular/core";

import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";
import { CostComparePivotLevel1 } from "@shared/components/cost-compare-panel/pivots/cost-compare-pivot.types";
import { DiffValues } from "../../../../pages/output-pages/base/types/constants";


@Injectable()
export class CostComparePivot extends PivotProviderBase {

    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            levelId: "row1",
            defaultOrderBy: "sortOrder",

            sorters: {
                sortOrder: {
                    extract: (row1: CostComparePivotLevel1) => row1.orderBy
                }
            },

            calculate: {
                steps: [
                    "SUM( value )",
                    "SUM( refValue )",
                    `${DiffValues.CostAbsoluteDiff} = DIFF( value, refValue ) at NODES, PARENT`,
                    `${DiffValues.CostRelativeDiff} = GROWTH( value, refValue ) at NODES, PARENT`,
                ]
            }
        };
    }
}

