/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import {
    DeleteGroupArguments,
    DeleteRuleArguments,
    RuleGroup,
    SaveGroupArguments,
    SaveGroupResult,
    SaveRuleArguments,
    SaveRuleResult,
    SelectCostArguments,
    SelectCostDriverArguments,
    SelectCostResult,
    SelectFiltersDataArguments,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../base/gateway/rules-gateway.types";
import { SelectTransferFiltersSchema, TransferCostDriverInfo, TransferRule, TransferRuleEditableFields } from "../../base/gateway/transfer-rule-gateway.type";
import { RulesGateway } from "../../base/gateway/rules-gateway";


@Injectable()
export class SubtaskSpecificationRulesGateway extends AppServerGatewayBase implements RulesGateway<TransferRule, TransferCostDriverInfo> {

    selectRules(args: SelectRulesArguments) {
        return this._get<TransferRule[]>("rules/resourceTaskSplit",
            { params: args });
    }


    selectRuleGroups(args: SelectRuleGroupArguments) {
        return this._get<RuleGroup[]>("rules/resourceTaskSplit/groups",
            { params: args });
    }


    selectCostDrivers(args: SelectCostDriverArguments) {
        return this._get<TransferCostDriverInfo[]>("rules/resourceTaskSplit/costDriver",
            { params: args });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments) {
        return this._get<SelectTransferFiltersSchema>("rules/resourceTaskSplit/filters/schema",
            { params: args });
    }


    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/resourceTaskSplit/filters/data",
            { params: args });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/resourceTaskSplit/filters/targetData",
            { params: args });
    }


    saveGroup(args: SaveGroupArguments) {
        return this._post<SaveGroupResult>("rules/resourceTaskSplit/saveGroup",
            { body: args });
    }


    saveRule(args: SaveRuleArguments<TransferRuleEditableFields>) {
        return this._post<SaveRuleResult>("rules/resourceTaskSplit/save",
            { body: args });
    }


    deleteGroup(args: DeleteGroupArguments) {
        return this._post<void>("rules/resourceTaskSplit/deleteGroup",
            { body: args });
    }


    deleteRule(args: DeleteRuleArguments) {
        return this._post<void>("rules/resourceTaskSplit/delete",
            { body: args });
    }


    selectCost(args: SelectCostArguments) {
        return this._getMaybeStale<SelectCostResult[]>("rules/resourceTaskSplit/cost",
            { params: args });
    }
}
