<div [lgLoaderOverlay]="isLoading"><div class="form-group"><div class="form-row"><div class="form-row__controls"><lg-button *ngIf="!_args.isReadonly" textLc=".AddRow" (click)="_addRow()" buttonClass="button button--condensed" icon="icon-add"></lg-button>

                            <lg-copy-button [onGetData]="_copyToClipboard" [columnsDefinitions]="_clipboardDef" style="margin-left: 8px"></lg-copy-button>

                            <lg-paste-button *ngIf="!_args.isReadonly" [onGetData]="_copyToClipboard" [onPutData]="_pasteFromClipboard" [preprocessData]="_preprocessClipboardData" [columnsDefinitions]="_clipboardDef" [configuration]="_pasteConfiguration" unmatched="allow" style="margin-left: 4px"></lg-paste-button>

                            <div class="form-row form-row--table form-row--no-bottom-margin">
                                <div class="form-row__controls">
                                    <lg-col-definition #_overviewPivotColumns columnClass="table__column"><lg-col-row id="header"><lg-col class="" id="code" width="*"></lg-col><lg-col class="" id="name" width="*2"></lg-col><lg-col class="right-align" id="used" width="80"></lg-col><lg-col class="" id="delete" width="28" type="icons"></lg-col></lg-col-row>
<lg-col-row id="level0"><lg-col class="" id="code" width="*"></lg-col><lg-col class="" id="name" width="*2"></lg-col><lg-col class="right-align" id="used" width="80"></lg-col><lg-col class="" id="delete" width="28" type="icons"></lg-col></lg-col-row>
</lg-col-definition>
<lg-rectangle #_overviewPivotWrapper [auditTime]="0" [class.flexcol.flexcol--full]="true" style="height: 30vh">
<lg-pivot-table class="cost-centre-type-resources-dialog__transfer-pivot" #_overviewPivotView [definition]="_overviewPivot.definition" [build]="false" [passive]="true" [data]="_overviewPivot.all" [filteredData]="_overviewPivot.filtered" [totals]="_overviewPivot.totals" [(orderBy)]="_overviewPivot.orderBy" (orderByChange)="_overviewPivot.refilter()" [pivotContext]="this" [lgColDefinition]="_overviewPivotColumns" [lgColDefinitionWidth]="_overviewPivotWrapper.width - 10" [stretchable]="true"><lg-pivot-table-header lgColRow="header" *lgPivotTableHeaderDef="let maxVisibleLevel=maxVisibleLevel"><div lgCol="code">{{ '.ResourceSubtaskOverviewPivot.CodeHeader' | lgTranslate }}</div><div lgCol="name">{{ '.ResourceSubtaskOverviewPivot.NameHeader' | lgTranslate }}</div><div lgCol="used">{{ '.ResourceSubtaskOverviewPivot.UsedHeader' | lgTranslate }}</div><div lgCol="delete"></div></lg-pivot-table-header>
<lg-pivot-table-row class="table__row" lgColRow="level0" *lgPivotTableRowDef="let row1 onLevel 'row1'; let rows = items" [ngClass]="{}"><div lgCol="code"><lg-grid-input [(model)]="row1.code" type="text" (modelChange)="_markModified( row1 )" [effective]="row1.code" [invalid]="!_isCodeValid( row1 )" [noEdit]="_args.isReadonly || row1.isReadonly || (row1.used > 0)" [placeholder]="'.ResourceSubtaskOverviewPivot.CodePlaceholder' | lgTranslate" markFocusOn=".table__row"></lg-grid-input></div><div lgCol="name"><lg-grid-input [(model)]="row1.name" type="text" (modelChange)="_markModified( row1 )" [effective]="row1.name" [invalid]="!_isNameValid( row1 )" [noEdit]="_args.isReadonly || row1.isReadonly" [placeholder]="'.ResourceSubtaskOverviewPivot.NamePlaceholder' | lgTranslate" markFocusOn=".table__row"></lg-grid-input></div><div lgCol="used" [innerHTML]=" row1.used | fmtInteger "></div><div lgCol="delete">
                            <lg-icon class="table__column-icon table__row__hover-only" *ngIf="!(_args.isReadonly || row1.isReadonly || (row1.used > 0))" icon="icon-delete" [clickable]="true" (click)="_deleteRow( row1 )"></lg-icon>
                        </div></lg-pivot-table-row>


</lg-pivot-table><lg-pivot-instance-sync [pivotInstance]="_overviewPivot" [component]="_overviewPivotView"></lg-pivot-instance-sync></lg-rectangle>
                                </div>
                            </div></div></div></div></div><div class="form-group--buttons form-group"><div class="form-row"><lg-button *ngIf="!_args.isReadonly" textLc="FW.SAVE" (click)="_save()" [isDisabled]="_isSaving || !_isValid"></lg-button>

                    <lg-button textLc="FW.CLOSE" (click)="_close()"></lg-button></div></div><ng-template #headerTemplate><div style="display: flex; justify-content: end">
                        <lg-help-tooltip [code]="_helpTooltip.SubTaskManagement"></lg-help-tooltip>
                    </div></ng-template>