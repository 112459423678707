import * as _ from "lodash";
import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { LG_APP_SESSION } from "@logex/framework/lg-application";

import { AppDefinitions } from "../../app-definitions.service";
import { IAppDefinitions } from "../../app-definitions.types";
import { AppSession } from "../../types/app-session";
import { OptionKeysArrayType, OptionKeyType, RuleFilterInfo, RuleFilterOption } from "./rule-filter-selector.types";


type OptionsCallbackServerSideCacheLevel2 = Map<object, () => Observable<OptionKeysArrayType>>;

@Injectable()
export class RuleFilterSelectorService {

    private _definitions = inject(AppDefinitions);
    private _lgTranslate = inject(LgTranslateService);
    private _session = inject<AppSession>(LG_APP_SESSION);

    // ----------------------------------------------------------------------------------
    private _optionsCallbackClientSideCache: Map<RuleFilterInfo, () => Observable<OptionKeysArrayType>> = new Map();

    private _optionsCallbackServerSideCache: Map<RuleFilterInfo, OptionsCallbackServerSideCacheLevel2> = new Map();

    private _loadedOptionsCache: Map<RuleFilterInfo, Map<OptionKeyType, RuleFilterOption>> = new Map();

    private _displayNameCallbackCache: Map<RuleFilterInfo, (id) => string> = new Map();

    private _orderByCallbackCache: Map<RuleFilterInfo, (id) => number> = new Map();

    // private _definitionDisplayNameCallback: _.Dictionary<( id ) => string> = {};
    //
    // private _definitionOrderByCallback: _.Dictionary<( id ) => number> = {};

    private _selectOptionsCallback: (uid: string, row: any) => Observable<RuleFilterOption[]>;


    // ----------------------------------------------------------------------------------
    configure(args: {
        selectOptionsCallback: (uid: string, row: any) => Observable<RuleFilterOption[]>;
    }): void {
        this._selectOptionsCallback = args.selectOptionsCallback;
    }


    getOptionsCallback(filterInfo: RuleFilterInfo, row: any): () => Observable<OptionKeysArrayType> {

        if (filterInfo.isClientSide) {
            return this._memoize(this._optionsCallbackClientSideCache, filterInfo, () => {
                // Options should be gathered on the client
                const definition = filterInfo.type as keyof IAppDefinitions;

                if (definition == null || !(definition in this._definitions)) {
                    throw Error(`Client-side rule filter ${filterInfo.uid} should be bound to a know definition`);
                }

                return () => of(this._getDefinitionKeys(definition));
            });

        } else {
            // Options should be obtained from the server
            // In this case we should cache different callbacks for different filterInfo and filter containers
            return this._memoize(
                this._memoize(
                    this._optionsCallbackServerSideCache,
                    filterInfo,
                    () => new Map() as OptionsCallbackServerSideCacheLevel2
                ),
                row,
                () => {

                    // If filter is not bound to definitions, we need to store loaded options for their names
                    const tapLoadedData = filterInfo.type == null
                        ? data => {
                            if (!this._loadedOptionsCache.has(filterInfo)) {
                                // Was not loaded before
                                this._loadedOptionsCache.set(filterInfo, new Map(data.map(x => [x.value, x])));
                            } else {
                                // Was already loaded before
                                const options = this._loadedOptionsCache.get(filterInfo);
                                for (const x of data) {
                                    if (!options.has(x.value)) {
                                        options.set(x.value, x);
                                    }
                                }
                            }
                        }
                        : null;

                    return () => this._selectOptionsCallback(filterInfo.uid, row)
                        .pipe(
                            tap(tapLoadedData),
                            map(data => _.map(data, x => x.value) as OptionKeysArrayType)
                        );
                }
            );
        }

    }


    private _getDefinitionKeys<TSection extends keyof IAppDefinitions>(
        definitionSection: TSection,
    ): OptionKeysArrayType {
        const defSection = this._definitions.getSection(definitionSection);

        return _
            .chain(this._definitions[definitionSection])
            .orderBy(x => x[defSection.orderByField])
            .map(x => x[defSection.codeField])
            .value();
    }


    getDisplayNameCallback(filterInfo: RuleFilterInfo): (id: OptionKeyType) => string {
        if (filterInfo.type != null) {
            // This is a definition-based filter
            return this._memoize(this._displayNameCallbackCache, filterInfo, () =>
                (id) => this._definitions.getDisplayName(filterInfo.type as keyof IAppDefinitions, id));

        } else if (!filterInfo.isClientSide) {
            // This is not bound to a definition, so names will come with options
            return this._memoize(this._displayNameCallbackCache, filterInfo, () =>
                (id) => {
                    const options = this._loadedOptionsCache.get(filterInfo);
                    const option = options?.get(id);
                    return option != null
                        ? option.name ?? this._lgTranslate.translate(option.nameLc, option.nameLcParameters ?? {})
                        : id.toString();
                });
        } else {
            throw Error(`Client-side filter ${filterInfo.uid} must be bound to a definition`);
        }
    }


    getOrderByCallback(filterInfo: RuleFilterInfo): (id: OptionKeyType) => any {
        if (filterInfo.type != null) {
            // This is a definition-based filter
            return this._memoize(this._orderByCallbackCache, filterInfo, () =>
                (id) => this._definitions.getOrderBy(filterInfo.type as keyof IAppDefinitions, id));

        } else {
            // This is not bound to a definition, so we can sort only by IDs
            return this._memoize(this._orderByCallbackCache, filterInfo, () =>
                (id) => id);
        }
    }


    // getDefinitionDisplayNameCallback( definition: keyof IAppDefinitions ) {
    //     return memoizeByKey( this._definitionDisplayNameCallback, definition, () =>
    //         id => this._definitions.getDisplayName( definition, id ) );
    // }
    //
    //
    // getDefinitionOrderByCallback( definition: keyof IAppDefinitions ) {
    //     return memoizeByKey( this._definitionOrderByCallback, definition, () =>
    //         id => this._definitions.getOrderBy( definition, id ) );
    // }
    //

    private _memoize<TKey, TRes>(cache: Map<TKey, TRes>, key: TKey, getValue: () => TRes): TRes {
        let res = cache.get(key);

        if (res == null) {
            res = getValue();
            cache.set(key, res);
        }

        return res;
    }
}
