import { NgModule } from "@angular/core";
import { ExportsNotificationsService } from "./services/exports-notifications.service";
import { ExportsNotificationsGateway } from "./gateways/exports-notifications-gateway.service";
import { DownloadExportService } from "@modules/exports/services/download-export.service";

@NgModule({
    providers: [
        ExportsNotificationsGateway,
        ExportsNotificationsService,
        DownloadExportService,
    ]
})
export class ExportsModule {
}
