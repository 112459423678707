import { Injectable } from "@angular/core";
import { LgApplicationPermissionsBase } from "@logex/framework/lg-application";


@Injectable({ providedIn: "root" })
export class ApplicationPermissions extends LgApplicationPermissionsBase {
    static readonly SWITCH_ALL_CLIENTS = "cst_switch_all_clients";
    static readonly MANAGE_SCENARIOS = "cst_manage_scenarios";
    static readonly SWITCH_SCENARIO = "cst_switch_scenario";
    static readonly SWITCH_LANGUAGE = "cst_switch_language";

    static readonly VIEW_ALL_COST_CENTRES = "cst_view_all_cost_centres";
    static readonly EDIT_ALL_COST_CENTRES = "cst_edit_all_cost_centres";
    static readonly VIEW_ALL_SPECIALISMS = "cst_view_all_specialisms";

    static readonly VIEW_RULES = "cst_view_rules";
    static readonly EDIT_RULES = "cst_edit_rules";

    static readonly ADMIN = "admin";
    static readonly INTERNAL = "internal";

    static readonly VIEW_ALL_SCENARIO_SETTINGS = "cst_view_scenario_settings_all";
    static readonly EDIT_SECTORS = "cst_edit_scenario_settings_sectors";
    static readonly EDIT_SCENARIO_TYPE = "cst_edit_scenario_settings_scenario_type";
    static readonly EDIT_PERIOD = "cst_edit_scenario_settings_period";
    static readonly EDIT_MODELING_RELEASE = "cst_edit_scenario_settings_modeling_release";
    static readonly EDIT_SUBMISSION_SET = "cst_edit_scenario_settings_submission_set";
    static readonly EDIT_BENCHMARK_SET = "cst_edit_scenario_settings_benchmark_release";
    static readonly EDIT_VALID_DATE = "cst_edit_scenario_settings_valid_date";
    static readonly USER_SETTINGS = "cst_user_settings";

    static readonly VIEW_SOURCE_DATA = "cst_view_source_data";
    static readonly EDIT_SOURCE_DATA = "cst_edit_source_data";
    static readonly UPLOAD_SET = "cst_upload_set";
    static readonly ACTIVATE_SET = "cst_activate_set";
    static readonly EXPORTS = "cst_export";

    static readonly VIEW_CHARACTERISTICS = "cst_view_characteristics";
    static readonly VIEW_COST_CENTRE_CHARACTERISTICS = "cst_view_cost_centre_characteristics";
    static readonly EDIT_CHARACTERISTICS = "cst_edit_characteristics";
    static readonly EDIT_COST_CENTRE_CHARACTERISTICS = "cst_edit_cost_centre_characteristics";

    static readonly VIEW_COST_CENTRE_VALUES = "cst_view_cost_centre_values";
    static readonly EDIT_COST_CENTRE_VALUES = "cst_edit_cost_centre_values";
    static readonly VIEW_ORGANISATION_COST = "cst_view_organisation_cost";
    static readonly EDIT_ORGANISATION_COST = "cst_edit_organisation_cost";

    static readonly VIEW_ALLOCATE_TO_RESOURCE = "cst_view_allocate_to_resource";
    static readonly EDIT_ALLOCATE_TO_RESOURCE = "cst_edit_allocate_to_resource";
    static readonly VIEW_ALLOCATE_SUPPORT_COST = "cst_view_allocate_support_cost";
    static readonly EDIT_ALLOCATE_SUPPORT_COST = "cst_edit_allocate_support_cost";
    static readonly VIEW_ALLOCATE_COST_ANALYTICS = "cst_view_allocate_cost_analytics";

    static readonly VIEW_ALLOCATE_TO_PRODUCTION = "cst_view_allocate_to_production";
    static readonly EDIT_ALLOCATE_TO_PRODUCTION = "cst_edit_allocate_to_production";
    static readonly VIEW_PRODUCTION_VALUES = "cst_view_production_values";
    static readonly EDIT_PRODUCTION_VALUES = "cst_edit_production_values";
    static readonly VIEW_ALLOCATE_TO_PRODUCTION_ANALYTICS = "cst_view_allocate_to_production_analytics";
    static readonly VIEW_ENCOUNTER_ANALYTICS = "cst_view_encounter_analytics";

    static readonly VIEW_ACTIVITY_ANALYTICS = "cst_view_activity_analytics";
    static readonly VIEW_PRODUCT_ANALYTICS = "cst_view_product_analytics";

    constructor() {
        super();

        (this as any).InternalRole = ApplicationPermissions.INTERNAL;
    }

}
