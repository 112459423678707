import { inject, Injectable } from "@angular/core";
import { RuleSection } from "@shared/dialogs/rules-dialog/types/constants";
import { RulesDialog } from "@shared/dialogs/rules-dialog/rules-dialog.component";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { ReloadTriggerService } from "@shared/services/reload-trigger.service";


@Injectable({
    providedIn: "root"
})
export class RulesDialogService {
    protected _rulesDialog = inject(RulesDialog);
    protected _lgTranslate = inject(LgTranslateService);
    protected _reloadTriggerService = inject(ReloadTriggerService);

    async openRuleDialog(section: RuleSection): Promise<void> {
        const dialog = await this._rulesDialog.show({ section });

        if (dialog.reload) {
            this._reloadTriggerService.trigger.next();
        }
    }
}
