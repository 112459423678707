<ng-template #headerTemplate>
                    <div class="flexrow" style="align-items: center">
                        <div style="flex-shrink: 0">
                            <lg-dropdown className="lg-reference-dropdown lg-reference-dropdown--header" popupClassName="lg-dropdown-popup lg-dropdown-popup--minimal-input" [definition]="_navigationDropdown" matchWidth="max" [textOverride]="_currentSectionName" [current]="_currentSection" (currentChange)="_onSectionChange( _currentSection, $event )" [hideSearch]="true" [condensed]="true"></lg-dropdown>
                        </div>
                        <div style="padding-left: 20px">
                            <lg-icon class="stale-data__loading-icon" *ngIf="_isStale$ | async" icon="icon-recalculation" style="margin-left: -10px" [lgTooltip]="'APP._.StaleDataLoading' | lgTranslate"></lg-icon>
                        </div>
                    </div>
                </ng-template><div class="stale-data-container stale-data-container--attach-top" *ngIf="_isStale$ | async"></div><div [lgLoaderOverlay]="_isLoading()"><div class="form-group" style="margin-bottom: -12px"><mod-subaccount-specification-rules [hidden]="_currentSection != 0" [isReadonly]="_isReadonly"></mod-subaccount-specification-rules>

                        <mod-account-transfer-rules [hidden]="_currentSection != 1" [isReadonly]="_isReadonly"></mod-account-transfer-rules>

                        <mod-resource-specification-rules [hidden]="_currentSection != 2" [isReadonly]="_isReadonly"></mod-resource-specification-rules>

                        <mod-resource-transfer-rules [hidden]="_currentSection != 3" [isReadonly]="_isReadonly"></mod-resource-transfer-rules>

                        <mod-task-specification-rules [hidden]="_currentSection != 4" [isReadonly]="_isReadonly"></mod-task-specification-rules>

                        <mod-support-cost-transfer-rules [hidden]="_currentSection != 5" [isReadonly]="_isReadonly"></mod-support-cost-transfer-rules>

                        <mod-subtask-specification-rules [hidden]="_currentSection != 6" [isReadonly]="_isReadonly"></mod-subtask-specification-rules>

                        <mod-map-to-production-rules [hidden]="_currentSection != 7" [isReadonly]="_isReadonly"></mod-map-to-production-rules></div></div><div class="form-group--buttons form-group"><div class="form-row"><lg-button textLc=".Close" (click)="_close()"></lg-button></div></div>