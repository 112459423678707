<div class="flex flexrow" style="height: 38px">
                <div class="flex-stretch form-row form-row--no-margin"><input class="input input--small app-search-input" type="text" [placeholder]="'APP._RulesDialog.SearchPlaceholder' | lgTranslate" [(ngModel)]="_filterValue" (ngModelChange)="_refilter()" style="margin-right: 8px" />

            <lg-button textLc="APP._SubAccountManagementDialog.DialogTitle" (click)="_openSubAccountManager()" buttonClass="button button--condensed"></lg-button>

            <lg-help-tooltip [code]="_helpTooltip.SubAccountManagement" style="margin-right: 4px"></lg-help-tooltip></div>
                <div class="form-row form-row--no-margin"><lg-button *ngIf="!isReadonly && !_isEditing()" textLc="APP._RulesDialog.AddNewRuleGroup" (click)="_addRuleGroup()" buttonClass="button button--condensed" icon="icon-add" style="margin-left: 8px"></lg-button></div>
            </div>

            <lg-content-grid [columnMajor]="true">
                <lg-content-grid-group id="group2" [size]="_isEditing() ? 5 : 12">
                    <lg-content-grid-item class="flexcol" id="item2">
                        <div class="form-row form-row--table form-row--no-margin">
                            <div class="form-row__controls">
                                <lg-col-definition #_pivotColumns columnClass="table__column"><lg-col-row id="header"><lg-col class="" id="name" width="325" type="headerLevels"></lg-col><lg-col class="crop" id="costDriver" width="150" [if]="!_isEditing()"></lg-col><lg-col class="crop" id="source" width="*" [if]="!_isEditing()"></lg-col><lg-col class="crop" id="target" width="*" [if]="!_isEditing()"></lg-col><lg-col class="right-align" id="cost" width="120" [if]="!_isEditing()"></lg-col><lg-col class="" id="clone" width="28" type="icons" [if]="!_isEditing()"></lg-col><lg-col class="" id="delete" width="28" type="icons" [if]="!_isEditing()"></lg-col></lg-col-row>
<lg-col-row id="level0"><lg-col class="" id="_col0" width="24" type="expand"></lg-col><lg-col class="" id="padding" width="9" type="icons"></lg-col><lg-col class="" id="dragHandle" width="24" type="icons"></lg-col><lg-col class="right-align" id="isEnabled" width="16" type="icons"></lg-col><lg-col class="" id="name" width="*"></lg-col><lg-col class="" id="clone" width="28" type="icons" [if]="!_isEditing()"></lg-col><lg-col class="" id="delete" width="28" type="icons" [if]="!_isEditing()"></lg-col></lg-col-row>
<lg-col-row id="level1"><lg-col class="" id="_col0" width="24" type="empty"></lg-col><lg-col class="" id="padding" width="1" type="icons"></lg-col><lg-col class="" id="dragHandle" width="24" type="icons"></lg-col><lg-col class="right-align" id="isEnabled" width="16" type="icons"></lg-col><lg-col class="" id="position" width="20"></lg-col><lg-col class="" id="name" width="200"></lg-col><lg-col class="crop" id="costDriver" width="150" [if]="!_isEditing()"></lg-col><lg-col class="crop" id="source" width="*" [if]="!_isEditing()"></lg-col><lg-col class="crop" id="target" width="*" [if]="!_isEditing()"></lg-col><lg-col class="right-align" id="cost" width="120" [if]="!_isEditing()"></lg-col><lg-col class="" id="clone" width="28" type="icons" [if]="!_isEditing()"></lg-col><lg-col class="" id="delete" width="28" type="icons" [if]="!_isEditing()"></lg-col></lg-col-row>
</lg-col-definition>
<lg-rectangle #_pivotWrapper [auditTime]="0" [class.flexcol.flexcol--full]="true" style="height: 50vh">
<lg-pivot-table #_pivotView [definition]="_pivot.definition" [build]="false" [passive]="true" [data]="_pivot.all" [filteredData]="_pivot.filtered" [totals]="_pivot.totals" [(orderBy)]="_pivot.orderBy" (orderByChange)="_pivot.refilter()" [pivotContext]="this" [lgColDefinition]="_pivotColumns" [lgColDefinitionWidth]="_isEditing() ? 480 : 1178" [stretchable]="true" cdkDropList><lg-pivot-table-header lgColRow="header" *lgPivotTableHeaderDef="let maxVisibleLevel=maxVisibleLevel"><lg-pivot-table-level-headers class="crop" lgCol="name" [levels]="[{ 'header':  'APP._RulesDialog.RulePivot.GroupHeader' | lgTranslate  }, { 'header':  'APP._RulesDialog.RulePivot.RuleHeader' | lgTranslate  }]"></lg-pivot-table-level-headers><div lgCol="costDriver"><span class="crop-text">
            <span lgTooltip="{{ 'APP._RulesDialog.RulePivot.CostDriverHeader' | lgTranslate }}"> {{ 'APP._RulesDialog.RulePivot.CostDriverHeader' | lgTranslate }} </span>
        </span></div><div lgCol="source"><span class="crop-text">
            <span lgTooltip="{{ 'APP._RulesDialog.RulePivot.SourceHeader' | lgTranslate }}"> {{ 'APP._RulesDialog.RulePivot.SourceHeader' | lgTranslate }} </span>
        </span></div><div lgCol="target"><span class="crop-text">
            <span lgTooltip="{{ '.RulePivot.SubAccountsHeader' | lgTranslate }}"> {{ '.RulePivot.SubAccountsHeader' | lgTranslate }} </span>
        </span></div><div lgCol="cost">{{ 'APP._RulesDialog.RulePivot.CostHeader' | lgTranslate }}</div><div lgCol="clone"></div><div lgCol="delete"></div></lg-pivot-table-header>
<lg-pivot-table-row class="table__row" lgColRow="level0" *lgPivotTableRowDef="let row1 onLevel 'row1'; let rows = items" [toggleOnClick]="true" [ngClass]="{'table__row--clickable': true}" cdkDrag [cdkDragData]="row1" cdkDragBoundary=".lg-pivot-table__body__holder" cdkDragLockAxis="y" (cdkDragDropped)="_dropGroup( $event )"><div class="center" lgCol="_col0"><lg-pivot-table-expand title="{{ 'FW.CLICK_TO_EXPAND' | lgTranslate }}"></lg-pivot-table-expand></div><div lgCol="padding"></div><div lgCol="dragHandle"><lg-icon class="table__row__hover-only" icon="icon-draggable" cdkDragHandle [cdkDragHandleDisabled]="_isEditing() || isReadonly || _filterValue.length > 0 || row1.isStandard"></lg-icon></div><div lgCol="isEnabled"><input type="checkbox" [(ngModel)]="row1.isEnabled" (ngModelChange)="_toggleRuleGroup( row1 )" [disabled]="isReadonly || row1.isStandard" lgStyledCheckbox /></div><div lgCol="name"><lg-editable-grid-text #groupName [disabled]="_isEditing() || isReadonly || row1.isStandard" [(model)]="row1.name" placeholder="{{ 'APP._RulesDialog.GroupPlaceholder' | lgTranslate }}" (lgFocus)="_groupRenameStart()" (lgBlur)="_groupRename( $event, row1.id )"></lg-editable-grid-text></div><div lgCol="clone"><lg-icon class="table__column-icon table__row__hover-only" *ngIf="!( isReadonly || row1.isStandard )" [lgTooltip]="'APP._RulesDialog.AddNewRule' | lgTranslate" icon="icon-add" [clickable]="true" (click)="_addRule( $event, row1.id )"></lg-icon></div><div lgCol="delete"><lg-icon class="table__column-icon table__row__hover-only" *ngIf="!( isReadonly || row1.isStandard )" [lgTooltip]="'APP._RulesDialog.DeleteGroup' | lgTranslate" icon="icon-delete" [clickable]="true" (click)="_deleteGroup( $event, row1 )"></lg-icon></div></lg-pivot-table-row>
<lg-pivot-table-row class="table__row" lgColRow="level1" *lgPivotTableRowDef="let row2 onLevel 'row2'; let rows = items" [ngClass]="{'table__row--selected': _isRuleSelected( row2.id ),'table__row--clickable': true}" (click)="_selectRule( row2 )" cdkDrag [cdkDragData]="row2" cdkDragBoundary=".lg-pivot-table__body__holder" cdkDragLockAxis="y" (cdkDragDropped)="_dropRule( $event )"><lg-pivot-table-empty-column lgCol="_col0" level="0"></lg-pivot-table-empty-column><div lgCol="padding"></div><div lgCol="dragHandle"><lg-icon class="table__row__hover-only" icon="icon-draggable" cdkDragHandle [cdkDragHandleDisabled]="_isEditing() || isReadonly || _filterValue.length > 0 || row2.isStandard"></lg-icon></div><div lgCol="isEnabled"><input *ngIf="rows.row1.isEnabled" type="checkbox" [(ngModel)]="row2.isEnabled" (ngModelChange)="_toggleRule( row2 )" [disabled]="isReadonly || row2.isStandard" lgStyledCheckbox /></div><div lgCol="position">{{ row2.position | fmtInteger }}.</div><div lgCol="name"><lg-editable-grid-text [disabled]="_isEditing() || isReadonly || row2.isStandard" [(model)]="row2.name" placeholder="{{ 'APP._RulesDialog.RulePlaceholder' | lgTranslate }}" (lgSave)="_renameRule( row2.id, $event )"></lg-editable-grid-text></div><div lgCol="costDriver" [innerHTML]=" row2.costDriverId | asCostDriver "></div><div lgCol="source">
                    <span [lgTooltip]="_renderSelectionTooltip( row2.filters )" [innerHTML]=" _renderSources( row2.filters ) "></span>
                </div><div lgCol="target"><span [lgTooltip]="_renderSubaccountsTooltip( row2.subAccounts )" [innerHTML]=" _renderSubaccounts( row2.subAccounts ) "></span></div><div lgCol="cost"><span class="cell-value--loadable" [innerHTML]="_renderRuleImpact(row2.id) | fmtMoney:0 | lgMarkSymbols" [class.cell-value--loading]="isStale$ | async"></span></div><div lgCol="clone"><lg-icon class="table__column-icon table__row__hover-only" *ngIf="!( isReadonly || rows.row1.isStandard )" [type]="_isRuleUpdatingInProgress ? 'disabled' : ''" [lgTooltip]="'APP._RulesDialog.CloneThisRule' | lgTranslate" icon="icon-duplicate" [clickable]="true" (click)="_cloneRule( $event, row2 )"></lg-icon></div><div lgCol="delete"><lg-icon class="table__column-icon table__row__hover-only" *ngIf="!( isReadonly || row2.isStandard )" [type]="_isRuleUpdatingInProgress ? 'disabled' : ''" [lgTooltip]="'APP._RulesDialog.DeleteRule' | lgTranslate" icon="icon-delete" [clickable]="true" (click)="_deleteRule( $event, row2 )"></lg-icon></div></lg-pivot-table-row>


</lg-pivot-table><lg-pivot-instance-sync [pivotInstance]="_pivot" [component]="_pivotView"></lg-pivot-instance-sync></lg-rectangle>
                            </div>
                        </div>
                    </lg-content-grid-item>
                </lg-content-grid-group>

                <lg-content-grid-group id="group2" [size]="7" *ngIf="_isEditing()">
                    <lg-content-grid-item class="flexcol" id="item3">
                        <mod-subaccount-specification-rule-editor #editor [isReadonly]="isReadonly || _currentRule.isStandard" [costDrivers]="_costDrivers" [sourceSelectorConfig]="_sourceSelectorConfig" [id]="_currentRule.id" [name]="_currentRule.name" [costDriverId]="_currentRule.costDriverId" [filters]="_currentRule.filters" (save)="_save( _currentRule.id, $event )" (saveAndNew)="_saveAndNew( _currentRule.id, $event )" (delete)="_deleteCurrentRule()" (close)="_closeRule()" [subAccounts]="_currentRule.subAccounts"></mod-subaccount-specification-rule-editor>
                    </lg-content-grid-item>
                </lg-content-grid-group>
            </lg-content-grid>