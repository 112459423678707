import { NgModule } from "@angular/core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UiCoreModule } from "@logex/framework/ui-core";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SharedModule } from "@shared/shared.module";
import { RulesDialog, RulesDialogComponent } from "./rules-dialog.component";
import { SubaccountSpecificationRulesSectionComponent } from "./components/1-1-subaccount-specification/subaccount-specification.component";
import { SubaccountSpecificationRulesEditorComponent } from "./components/1-1-subaccount-specification/rule-editor.component";
import { AccountTransferRulesSectionComponent } from "./components/1-2-account-transfer/account-transfer.component";
import { AccountTransferRulesEditorComponent } from "./components/1-2-account-transfer/rule-editor.component";
import { ResourceSpecificationRulesSectionComponent } from "./components/2-1-resource-specification/resource-specification.component";
import { ResourceSpecificationRulesEditorComponent } from "./components/2-1-resource-specification/rule-editor.component";
import { ResourceTransferRulesSectionComponent } from "./components/2-2-resource-transfer/resource-transfer.component";
import { ResourceTransferRulesEditorComponent } from "./components/2-2-resource-transfer/rule-editor.component";
import { TaskSpecificationRulesSectionComponent } from "./components/3-1-task-specification/task-specification.component";
import { TaskSpecificationRulesEditorComponent } from "./components/3-1-task-specification/rule-editor.component";
import { SupportCostTransferRulesSectionComponent } from "./components/3-2-support-cost-transfer/support-cost-transfer.component";
import { SupportCostTransferRulesEditorComponent } from "./components/3-2-support-cost-transfer/rule-editor.component";
import { SubtaskSpecificationRulesSectionComponent } from "./components/4-1-subtask-specification/subtask-specification.component";
import { SubtaskSpecificationRulesEditorComponent } from "./components/4-1-subtask-specification/rule-editor.component";
import { MapToProductionRulesSectionComponent } from "./components/4-2-map-to-production/map-to-production.component";
import { MapToProductionRulesEditorComponent } from "./components/4-2-map-to-production/rule-editor.component";

import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiCoreModule,
        UiToolboxModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        DragDropModule,
        SharedModule,
    ],
    declarations: [
        RulesDialogComponent,
        SubaccountSpecificationRulesSectionComponent,
        SubaccountSpecificationRulesEditorComponent,
        AccountTransferRulesSectionComponent,
        AccountTransferRulesEditorComponent,
        ResourceSpecificationRulesSectionComponent,
        ResourceSpecificationRulesEditorComponent,
        ResourceTransferRulesSectionComponent,
        ResourceTransferRulesEditorComponent,
        TaskSpecificationRulesSectionComponent,
        TaskSpecificationRulesEditorComponent,
        SupportCostTransferRulesSectionComponent,
        SupportCostTransferRulesEditorComponent,
        SubtaskSpecificationRulesSectionComponent,
        SubtaskSpecificationRulesEditorComponent,
        MapToProductionRulesSectionComponent,
        MapToProductionRulesEditorComponent,
    ],
    providers: [
        RulesDialog,

        RuleFilterSelectorService,
    ]
})
export class RulesDialogModule {
}
