/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import { RulesGateway, SaveRulesResult } from "../../bases/rules-base/gateways/rules-gateway";
import {
    RuleGroup,
    SaveRulesArguments,
    SelectCostDriverArguments,
    SelectFiltersDataArguments,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../bases/rules-base/gateways/rules-gateway.types";

import { TransferCostDriverInfoExt, TransferRuleExt } from "./edit-resource-task-transfer-rules-gateway.types";
import { SelectTransferFiltersSchema } from "../../bases/transfer-rules-base/gateway/transfer-rules-gateway.type";


@Injectable()
export class EditResourceTaskRulesGateway extends AppServerGatewayBase implements RulesGateway<TransferRuleExt, TransferCostDriverInfoExt> {

    selectRules(args: SelectRulesArguments) {
        return this._get<TransferRuleExt[]>("rules/resourceTaskTransfer",
            { params: args });
    }


    selectRuleGroups(args: SelectRuleGroupArguments) {
        return this._get<RuleGroup[]>("rules/resourceTaskTransfer/groups",
            { params: args });
    }


    selectCostDrivers(args: SelectCostDriverArguments) {
        return this._get<TransferCostDriverInfoExt[]>("rules/resourceTaskTransfer/costDriver",
            { params: args });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments) {
        return this._get<SelectTransferFiltersSchema>("rules/resourceTaskTransfer/filters/schema",
            { params: args });
    }


    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/resourceTaskTransfer/filters/data",
            { params: args });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/resourceTaskTransfer/filters/targetData",
            { params: args });
    }


    saveRules(args: SaveRulesArguments<TransferRuleExt>) {
        return this._post<SaveRulesResult>("rules/resourceTaskTransfer",
            { body: args });
    }
}
