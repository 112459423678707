export enum HelpTooltip {
    SourceDataProduction = "source-data-production",
    SourceDataFinancial = "source-data-financial",
    SourceDataHumanResource = "source-data-human-resource",
    SourceDataOtherResource = "source-data-other-resource",

    CharacteristicsProduction = "production-characteristics",
    CharacteristicsCostCentre = "cost-centre-characteristics",
    CharacteristicsAccount = "account-characteristics",
    CharacteristicsHumanResource = "human-resource-characteristics",
    CharacteristicsEquipment = "equipment-characteristics",
    CharacteristicsMaterial = "material-characteristics",
    CharacteristicsResource = "resource-characteristics",
    CharacteristicsTask = "task-characteristics",

    OrganisationCost = "organisation-cost",
    OptimizeDataAnalytics = "optimize-data-analytics",
    SubAccountSpecification = "sub-account-specification",
    AccountCorrection = "account-correction",
    AccountTransfer = "account-transfer",
    SubAccountManagement = "sub-account-management",

    AllocateToResource = "allocate-to-resource",
    ResourceSpecification = "resource-specification",
    ResourceTransfer = "resource-transfer",

    AllocateSupportCost = "allocate-support-cost",
    TaskSpecification = "task-specification",
    SupportCostTransfers = "support-cost-transfers",
    TaskManagement = "task-management",

    AllocateCostAnalytics = "allocate-cost-analytics",

    AllocateToProduction = "allocate-to-production",

    ProductionValues = "production-values",
    AllocateToProductionAnalytics = "allocate-to-production-analytics",

    ActivityAnalytics = "activity-analytics",
    ProductActivityAnalytics = "product-activity-analytics",
    ProductAnalytics = "product-analytics",
    EncounterAnalytics = "encounter-analytics",
    Exports = "exports",

    SubTaskSpecification = "sub-task-specification",
    ProductionAllocations = "production-allocations",
    SubTaskManagement = "sub-task-management",

    Manual = "manual",
    FAQ = "faq",
    Webinar = "video_webinar"
}
