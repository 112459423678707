<ng-template #headerTemplate>
                <lg-icon class="stale-data__loading-icon" *ngIf="_isStale$ | async" icon="icon-recalculation" style="margin-left: -10px" [lgTooltip]="'APP._.StaleDataLoading' | lgTranslate"></lg-icon>
            </ng-template>

            <div class="stale-data-container stale-data-container--attach-top" *ngIf="_isStale$ | async"></div>

            <div class="form-group" [lgLoaderOverlay]="_loading$ | async">
                <div style="display: flex; width: 100%; justify-content: end; margin-right: 16px;">
                    <lg-button buttonClass="button--condensed" icon="icon-export-download" (click)="_exportToExcel()" textLc="Export"></lg-button>
                </div>
                <lg-rectangle class="form-row flexcol flexcol--full" #tracingRect style="height: 75vh">
                    <mod-tracing-chart [width]="tracingRect.width" [height]="tracingRect.height" [data]="_data" [nodeTemplate]="nodeTemplate" [columnTitleTemplate]="columnTitleTemplate" (nodeClick)="_onNodeClick( $event )">
                        <ng-template #columnTitleTemplate let-column>
                            <ng-container *ngIf="_columns[column] as col">
                                <div class="mod-tracing-chart-column__title__label">{{col.titleLc | lgTranslate}}</div>
                                <div class="mod-tracing-chart-column__title__selector">
                                    <lg-multi-filter [source]="col.options" [filter]="col.selectedOptions" (filterChange)="_changeColumnDimensions( col.column, $event )" [clearable]="false" look="grid" [showSelected]="3" [disabled]="col.isCentral" [placeholder]="'.SelectDimensions' | lgTranslate" (popupSelectionChange)="_onDimensionsSelectChange( col.column, $event )" [hidePopupHeader]="true"></lg-multi-filter>
                                </div>
                            </ng-container>
                        </ng-template>

                        <ng-template #multiFilterItem let-entry let-filter="regexpFilter">
                            <div *ngIf="entry.disabled" style="width: 100%" [innerHTML]="entry.name | highlightByRegex: filter" [lgTooltip]="entry.disabled ? entry.data : undefined" title="">
                            </div>
                            <div *ngIf="!entry.disabled" [innerHTML]="entry.name | highlightByRegex: filter">
                            </div>
                        </ng-template>

                        <ng-template #nodeTemplate let-node>
                            <ng-container *ngIf="node.grouped == null; else nodeGrouped">

                                <ng-container *ngFor="let dimension of node.dimensions">
                                    <div class="mod-tracing-chart-node__details-row" *ngIf="dimension.type != 'string'; else stringDefinition" lgTooltip="{{ dimension.nameLc | lgTranslate }}: {{dimension.value | asDefinition:dimension.type}}">
                                        {{dimension.value | asDefinition:dimension.type}}
                                    </div>

                                    <ng-template #stringDefinition>
                                        <div class="mod-tracing-chart-node__details-row" lgTooltip="{{ dimension.nameLc | lgTranslate }}: {{dimension.value ?? '-'}}">
                                            {{dimension.value ?? "-"}}
                                        </div>
                                    </ng-template>
                                </ng-container>

                            </ng-container>

                            <ng-template #nodeGrouped>
                                <div class="mod-tracing-chart-node__group">
                                    {{ '.GroupOfNodes' | lgTranslate:{ count: node.grouped.length } }}
                                </div>
                            </ng-template>

                            <div class="mod-tracing-chart-node__details-cost" [ngSwitch]="true">
                                
                                <ng-container *ngSwitchCase="node.column < _centralNode.column && !( _areCostsEqual( node.outgoingCost, 0 ) || _areCostsEqual( node.outgoingCost, node.cost ) )">
                                    <span [innerHTML]="node.outgoingCost | fmtMoney| lgMarkSymbols"></span>
            <span class="mod-tracing-chart-node__details-cost--more">
                {{ '.Of' | lgTranslate }} 
                <span [innerHTML]="node.cost | fmtMoney | lgMarkSymbols"></span>
                (<span [innerHTML]="node.outgoingCost / node.cost |fmtPercent | lgMarkSymbols"></span>)
            </span>
                                </ng-container>

                                <ng-container *ngSwitchCase="node.column < _centralNode.column && ( _areCostsEqual( node.outgoingCost, 0 ) || _areCostsEqual( node.outgoingCost, node.cost ) )">
                                    <span [innerHTML]="node.cost | fmtMoney | lgMarkSymbols"></span>
                                </ng-container>

                                
                                <ng-container *ngSwitchCase="node.column >= _centralNode.column && !( _areCostsEqual( node.incomingCost, 0 ) || _areCostsEqual( node.incomingCost, node.cost ) )">
                                    <span [innerHTML]="node.incomingCost | fmtMoney| lgMarkSymbols"></span>
            <span class="mod-tracing-chart-node__details-cost--more">
                {{ '.Of' | lgTranslate }} 
                <span [innerHTML]="node.cost | fmtMoney | lgMarkSymbols"></span>
                (<span [innerHTML]="node.incomingCost / node.cost |fmtPercent | lgMarkSymbols"></span>)
            </span>
                                </ng-container>

                                <ng-container *ngSwitchCase="node.column >= _centralNode.column && ( _areCostsEqual( node.incomingCost, 0 ) || _areCostsEqual( node.incomingCost, node.cost ) )">
                                    <span [innerHTML]="node.cost | fmtMoney | lgMarkSymbols"></span>
                                </ng-container>
                            </div>

                            <lg-icon class="mod-tracing-chart-node__set-central-node" icon="icon-split" [lgTooltip]="'.ChangeCentralNode' | lgTranslate" [clickable]="true" (click)="_changeCentralNode( $event, node )" *ngIf="node !== _centralNode && node.grouped == null"></lg-icon>

                        </ng-template>
                    </mod-tracing-chart>

                </lg-rectangle>
            </div>