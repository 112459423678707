import _ from "lodash";
import { Injectable } from "@angular/core";

import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";

import { CostDriverInfo, Rule } from "../gateways/rules-gateway.types";
import { RulesPivotLevel1, RulesPivotLevel2 } from "./rules-pivot.types";
import { RulesComponentBase } from "../rules-component-base";


@Injectable()
export class RulesPivot extends PivotProviderBase {

    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "id",
            mergedKey: (row: Rule) => row.groupId,
            levelId: "row1",
            attachedColumns: [
                (
                    target: RulesPivotLevel1<RulesPivotLevel2<Rule>>,
                    row: Rule,
                    parentNode,
                    context: RulesComponentBase<RulesPivotLevel2<Rule>, CostDriverInfo>
                ) => {
                    // Find a group
                    const group = _.find(context._ruleGroups, { id: row.groupId });
                    if (group == null) {
                        throw new Error(`Rule group ${row.groupId} is not found`);
                    }

                    target.name = group.name;
                    target.position = group.position;
                    target.isEnabled = group.isEnabled;
                    target.isStandard = group.isStandard;
                }
            ],
            defaultOrderBy: ["position", "id"],
            filtersKeepChildless: true,

            children: {
                column: "position",
                store: "children",
                levelId: "row2",
                defaultOrderBy: ["position", "id"],
            },
        };
    }
}
