import { InjectionToken } from "@angular/core";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";

export class DataSets {
    static GROUPS = "Groups";
    static RULES = "Rules";
    static COSTS = "Costs";
    static COST_DRIVERS = "CostDrivers";
    static FILTER_SCHEMA = "FilterSchema";
}

export const SOURCE_RULE_FILTER_SELECTOR = new InjectionToken<RuleFilterSelectorService>("SourceRuleFilterSelector");
export const TARGET_RULE_FILTER_SELECTOR = new InjectionToken<RuleFilterSelectorService>("TargetRuleFilterSelector");


export const NEW_RULE_ID = -Infinity;
