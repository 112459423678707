import { IAppInfo, LgMatTrackingService, } from "@logex/framework/lg-application";

import { AppSession } from "@shared/types/app-session";
import { AppUser } from "@shared";
import { ApplicationPermissions } from "@shared/app-permissions";
import { configureMatomoBasicDimensions } from "@logex/fadp";


// setup of custom dimensions is same for all environments (at least for now)
const USER_TYPE_DIMENSION = 3;
const INSTANCE_DIMENSION = 4;


export function configureMatomoCustomDimensions(service: LgMatTrackingService, values: {
    session: AppSession;
    userInfo: AppUser;
    appInfo: IAppInfo;
}): void {
    configureMatomoBasicDimensions(service, values);

    service.deleteCustomDimension(USER_TYPE_DIMENSION);
    service.setCustomDimension(USER_TYPE_DIMENSION,
        values.userInfo.hasPermission(ApplicationPermissions.INTERNAL) ? "internal" : "external");

    service.deleteCustomDimension(INSTANCE_DIMENSION);
    service.setCustomDimension(INSTANCE_DIMENSION, values.appInfo.toolInstanceName);
}
