import { Directive, inject, OnInit } from "@angular/core";
import { RuleEditorComponentBase } from "./rule-editor-component-base";
import * as _ from "lodash";
import { TransferCostDriverInfo } from "./gateway/transfer-rule-gateway.type";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { TARGET_RULE_FILTER_SELECTOR } from "./types";

@Directive()
export abstract class TransferRuleEditorComponentBase extends RuleEditorComponentBase implements OnInit {

    protected _targetRuleFilterSelector = inject(TARGET_RULE_FILTER_SELECTOR);

    // @Input() must be in the derived class
    protected abstract targetFilters: Record<string, unknown[]>;
    protected abstract costDrivers: TransferCostDriverInfo[];
    protected abstract targetSelectorConfig: RuleFilterInfo[];


    allowedTargetUids: string[];


    ngOnInit() {
        super.ngOnInit();

        this._targetRuleFilterSelector.configure({
            selectOptionsCallback: (uid, row) => this._gateway.selectTargetFilterData({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                uid,
            })
        });

        // Clone properties so that they are not updated in the parent component
        this.targetFilters = _.cloneDeep(this.targetFilters);
    }


    protected _getFormValues(): Record<string, unknown> {
        return {
            ...super._getFormValues(),
            targetFilters: this.targetFilters,
        }
    }


    protected _updateRuleAfterChanges(): void {
        super._updateRuleAfterChanges();

        const costDriverDict = _.keyBy(this.costDrivers, x => x.costDriverId);

        const costDriverInfo = costDriverDict[this.costDriverId];

        this.allowedTargetUids = costDriverInfo?.targetUids;

        // Clear all target fields that are not allowed in this cost driver
        _.forOwn(this.targetFilters, (value, field) => {
            if (!this._isTargetAllowed(field)) {
                delete this.targetFilters[field];
            }
        });

        if (this.targetFilters && Object.keys(this.targetFilters)?.length === 0) {
            this.targetFilters = undefined;
        }
    }


    _isTargetAllowed(field: string): boolean {
        return _.includes(this.allowedTargetUids, field);
    }
    
    
    _hasAllowedTargets(): boolean {
        return this.allowedTargetUids?.length > 0
    }


    async _onTargetFilterChange(
        selector: string,
        value: string[]
    ): Promise<void> {
        this.targetFilters = this._updatedFilters(this.targetFilters, selector, value);
    }

}
