<div class="mod-tracing-chart__columns"
     #columnsPanel>

    <svg class="mod-tracing-chart__svg">
        <!--<rect width="100%" height="100%"
              class="mod-tracing-chart__background">
        </rect>-->
    </svg>

    <div *ngFor="let column of _columns"
         class="mod-tracing-chart__column"
    >
        <div class="mod-tracing-chart-column__title">
            <ng-container
                *ngTemplateOutlet="columnTitleTemplate; context: { $implicit: column.column }"
            ></ng-container>
        </div>

        <div class="mod-tracing-chart-column__body">
            <div
                class="mod-tracing-chart-column__body-scroller"
                [lgScrollable]
                [lgScrollableAutoHide]="true"
                [lgScrollableWrapper]="false"
                (scroll)="_draw()"
            >

                <div
                    *ngFor="let node of column.nodes"
                    [id]="node.id"
                    class="mod-tracing-chart-node"
                    [class.mod-tracing-chart-node--clickable]="node.dataNode.clickable"
                    [class.mod-tracing-chart-node--central]="node.dataNode.central"
                    (click)="_onNodeClick( node )"
                >
                    <div class="mod-tracing-chart-node__cost-indicator--left">
                        <div class="mod-tracing-chart-node__cost-indicator--positive"
                             [style.height.px]="node.costLeftPositive"></div>
                        <div class="mod-tracing-chart-node__cost-indicator--negative"
                             [style.height.px]="-node.costLeftNegative"></div>
                        <div class="mod-tracing-chart-node__cost-indicator--remaining"
                             [style.height.px]="node.cost - node.costLeft"></div>
                    </div>
                    <div class="mod-tracing-chart-node__cost-indicator--right">
                        <div class="mod-tracing-chart-node__cost-indicator--positive"
                             [style.height.px]="node.costRightPositive"></div>
                        <div class="mod-tracing-chart-node__cost-indicator--negative"
                             [style.height.px]="-node.costRightNegative"></div>
                        <div class="mod-tracing-chart-node__cost-indicator--remaining"
                             [style.height.px]="node.cost - node.costRight"></div>
                    </div>

                    <div class="mod-tracing-chart-node__details">
                        <ng-container
                            *ngTemplateOutlet="nodeTemplate; context: { $implicit: node.dataNode }"
                        ></ng-container>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>
