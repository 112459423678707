import { inject, Injectable } from "@angular/core";

import { LG_USER_INFO } from "@logex/framework/lg-application";

import { ApplicationPermissions } from "@shared/app-permissions";
import { AppUser } from "@shared/app-user";
import { AuthorizationBase } from "@shared/bases/authorization-base";


@Injectable()
export class RulesDialogAuthorization extends AuthorizationBase {

    readonly access: boolean;
    readonly edit: boolean;

    constructor() {
        const applicationPermissions = inject(ApplicationPermissions);
        const userInfo = inject<AppUser>(LG_USER_INFO);

        super(applicationPermissions, userInfo);

        this.access = userInfo.hasPermission(ApplicationPermissions.VIEW_RULES);
        this.edit = userInfo.hasPermission(ApplicationPermissions.EDIT_RULES);
    }
}
