import * as _ from "lodash";
import { firstValueFrom } from "rxjs";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { mapTargetFilters } from "@shared/services/rule-filter-selector/utils/loadTargetFilters";

import { SelectTransferFiltersSchema, TransferRule, TransferRuleEditableFields } from "./gateway/transfer-rule-gateway.type";
import { TransferRulesPivotLevel2 } from "./types";
import { RuleSectionComponentBase } from "./rule-section-component-base";
import { DefinitionsCostDriver, IAppDefinitions } from "@shared";
import { RuleGroup } from "./gateway/rules-gateway.types";


export abstract class TransferRuleSectionComponentBase<TRulesPivotLevel2 extends TransferRulesPivotLevel2 = TransferRulesPivotLevel2>
    extends RuleSectionComponentBase<TRulesPivotLevel2> {


    protected _defaultCostDriver: DefinitionsCostDriver;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = [];
    protected _targetSelectorConfig: RuleFilterInfo[];


    protected async _processFiltersSchema(filtersSchema: SelectTransferFiltersSchema): Promise<void> {
        this._sourceSelectorConfig = mapTargetFilters(filtersSchema.filters, this._lgTranslate);
        this._targetSelectorConfig = mapTargetFilters(filtersSchema.targetFilters, this._lgTranslate);

        const schemaDefinitions = _.compact(
            _.uniq([
                ...this._sourceSelectorConfig.map(x => x.type),
                ...this._targetSelectorConfig.map(x => x.type),
            ])
        );

        await firstValueFrom(this._definitions.load(
            "costDriver",
            ...this._requiredDefinitions,
            ...schemaDefinitions as any,
        ));
    }


    protected _getRulePropsForSaving(rule: TransferRuleEditableFields): TransferRuleEditableFields {
        return {
            ...super._getRulePropsForSaving(rule),
            filters: rule.filters,
            targetFilters: rule.targetFilters,
        }
    }


    protected _processLoadedData(rules: TransferRule[], groups: RuleGroup[]) {
        super._processLoadedData(rules, groups);

        const orderedCostDrivers = _.sortBy(
            _.map(this._costDrivers, x => this._definitions.costDriver[x.costDriverId]),
            "orderBy"
        );

        this._defaultCostDriver = _.first(orderedCostDrivers);
    }


    protected _newRule(groupId: number, position: number): TRulesPivotLevel2 {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            costDriverId: this._defaultCostDriver.id,
        } as TRulesPivotLevel2;
    }


    protected _renderTargets(filters: Record<string, unknown[]>): string {
        return this._renderSelection(this._targetSelectorConfig, filters, "APP._RulesDialog.Targets");
    }


}
