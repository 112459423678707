import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { HandleErrorsMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";

import { LG_AUTHORIZATION_SERVICE } from "@logex/framework/lg-application";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { BootHelperService } from "./boot-helper/boot-helper.service";


export interface BootGuardService extends HandleErrorsMixin {
}

@Injectable({ providedIn: "root" })
@mixins(HandleErrorsMixin)
export class BootGuardService {

    _promptDialog = inject(LgPromptDialog);
    _lgTranslate = inject(LgTranslateService);
    private _authorizationService = inject(LG_AUTHORIZATION_SERVICE);
    private _bootHelper = inject(BootHelperService);
    private _lgLoaderService = inject(LgLoaderService);
    private _router = inject(Router);

    constructor() {
        this._initMixins();
    }


    // ----------------------------------------------------------------------------------

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return await this._checkAndRedirect();
    }


    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return await this._checkAndRedirect();
    }


    private async _checkAndRedirect(): Promise<boolean | UrlTree> {
        if (this._bootHelper.isSessionConfigured()) return true;

        this._lgLoaderService.show("bootGuard");

        try {
            // Check that the user has access to the product instance
            if (!await firstValueFrom(this._authorizationService.checkProductInstanceAccess())) {
                // Redirect to "access denied" page
                return this._router.parseUrl("/boot/accessDenied");
            }

            // Access is allowed -> try to select latest stored session
            if (!await this._bootHelper.trySelectLastSession()) {
                // There is no stored session or it's not available/allowed anymore
                // TODO: Check if user is allowed to switch scenarios
                const dst = this._router.parseUrl("/boot");
                dst.queryParams["redirectTo"] = this._router.getCurrentNavigation()?.initialUrl.toString();
                return dst;
            }

            return true;
        } catch (e: any) {
            this._onException(e);
            return false;
        } finally {
            this._lgLoaderService.hide("bootGuard");
        }
    }
}
