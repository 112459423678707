/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */

import * as _ from "lodash";
import { inject, Injectable } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import * as Filters from "@logex/framework/lg-filterset";
import { INormalizedFilterDefinition } from "@logex/framework/lg-filterset";

import { Filters as FilterId, FiltersLayoutGroup } from "./types/filters";
import {
    FilterFactoryCreatorBase,
    IFilterFactoryEntry,
    MapFilterDefinition,
    MapFilterStore,
    SupportedFilters
} from "./bases/filter-factory-base";
import { AppDefinitions, DefinitionKey } from "./app-definitions.service";


// ---------------------------------------------------------------------------------------------
//  Implementation of the FilterFactory service
// ---------------------------------------------------------------------------------------------

@Injectable({
    providedIn: "root"
})
export class FilterFactory {

    private _appDefinitions = inject(AppDefinitions);
    private _filterSetService = inject(Filters.LgFilterSetService);
    private _translationService = inject(LgTranslateService);

    define(): FilterFactoryCreator {
        return new FilterFactoryCreator(this._filterSetService, this._translationService, this._appDefinitions);
    }
}


// ---------------------------------------------------------------------------------------------
type Combo2Parameters<T> = Pick<Filters.IComboFilter2Definition<T>,
    "source" | "onBecameActive" | "onBecameActiveLimit" | "onChanged" | "visible" | "previewPotentiallyVisible" | "nameLC">;

type CheckboxParameters = Pick<Filters.ICheckboxFilterDefinition,
    "uncheckedFilters" | "default" | "previewTextLC" | "previewPopup" | "nameLC" | "onChanged" | "visible">;

// type SelectableCombo2Parameters<T> = Pick<Filters.ISelectableComboFilter2Definition<T>, "source" | "onBecameActive" | "onBecameActiveLimit" | "onChanged" | "visible" | "nameLC" | "onSelectionChanged" | "onSelectionRemoved">;

// type InputRangeParameters = Pick<Filters.IInputRangeFilterDefinition, "min" | "max" | "format" | "decimals" | "default" | "onChanged" | "visible" | "nameLC">;


// ---------------------------------------------------------------------------------------------
//  Implementation of the creator.
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreator<Definitions extends Record<string, INormalizedFilterDefinition> = {},
    Filters extends Filters.IFilterList = {}> extends FilterFactoryCreatorBase {

    constructor(
        _filterSetService: Filters.LgFilterSetService,
        _translationService: LgTranslateService,
        private _appDefinitions: AppDefinitions,
    ) {
        super(_filterSetService, _translationService);
    }


    // ---------------------------------------------------------------------------------------------

    create(context: any, layout?: FiltersLayoutGroup[]) {

        // If layout is specified, then all configured filters must be rearranged
        if (layout != null) {
            const newDefinition: Filters.IFilterDefinition[] = [];
            const includedFilter = new Set<string>();
            for (const group of layout) {
                const groupFilters = _.filter(_.map(group.filters, x => {
                    const filter = _.find(this._definition, {id: x.filter});
                    if (filter == null) return null;

                    return {...x, filter};
                }));

                if (_.isEmpty(groupFilters)) continue;

                let isFirst = true;
                for (const {lc, hidden, filter} of groupFilters) {
                    filter.startGroupLC = isFirst ? group.lc : null;
                    filter.nameLC = lc ?? filter.nameLC;

                    if (hidden) {
                        filter.visible = () => false;
                        filter.previewPotentiallyVisible = () => false;
                    }

                    newDefinition.push(filter);
                    includedFilter.add(filter.id);

                    isFirst = false;
                }
            }

            // Add all filters not included in the layout as hidden
            const notIncludedFilters = _.filter(this._definition, x => !includedFilter.has(x.id));
            for (const filter of notIncludedFilters) {
                // filter.visible = () => false;
                filter.previewPotentiallyVisible = () => false;
                newDefinition.push(filter);
            }

            this._definition = newDefinition;
        }

        return this._create<Definitions, Filters>(context);
    }


    // ----------------------------------------------------------------------------------

    costCentreTopgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_TOPGROUP,
            params,
            {
                nameLC: "APP._Filters.CostCentreTopgroup",
                mapToOptions: this.createMapper("costCentreTopgroup"),
            }
        );
    }

    costCentreFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE,
            params,
            {
                nameLC: "APP._Filters.CostCentre",
                mapToOptions: this.createMapper("costCentre"),
            }
        );
    }


    costCentreGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_GROUP,
            params,
            {
                nameLC: "APP._Filters.CostCentreGroup",
                mapToOptions: this.createMapper("costCentreGroup"),
            }
        );
    }


    costCentreSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.CostCentreSubgroup",
                mapToOptions: this.createMapper("costCentreSubgroup"),
            }
        );
    }


    costCentreTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.COST_CENTRE_TYPE,
            params,
            {
                nameLC: "APP._Filters.CostCentreType",
                mapToOptions: this.createMapper("costCentreType"),
            }
        );
    }


    costCentreBenchmarkTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.COST_CENTRE_BENCHMARK_TYPE,
            params, {
                nameLC: "APP._Filters.CostCentreBenchmarkType",
                mapToOptions: this.createMapper("costCentreBenchmarkType"),
            }
        );
    }


    costCentreLevel1Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL1,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel1",
                mapToOptions: this.createMapper("costCentreLevel1"),
            }
        );
    }


    costCentreLevel2Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL2,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel2",
                mapToOptions: this.createMapper("costCentreLevel2"),
            }
        );
    }


    costCentreLevel3Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL3,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel3",
                mapToOptions: this.createMapper("costCentreLevel3"),
            }
        );
    }


    costCentreLevel4Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL4,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel4",
                mapToOptions: this.createMapper("costCentreLevel4"),
            }
        );
    }


    costCentreLevel5Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL5,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel5",
                mapToOptions: this.createMapper("costCentreLevel5"),
            }
        );
    }


    // ----------------------------------------------------------------------------

    costCentreTopgroupAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_TOPGROUP_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreTopgroupAfterTransfer",
                mapToOptions: this.createMapper("costCentreTopgroup"),
            }
        );
    }


    costCentreAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreAfterTransfer",
                mapToOptions: this.createMapper("costCentre"),
            }
        );
    }


    costCentreGroupAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_GROUP_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreGroupAfterTransfer",
                mapToOptions: this.createMapper("costCentreGroup"),
            }
        );
    }


    costCentreSubgroupAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_SUBGROUP_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreSubgroupAfterTransfer",
                mapToOptions: this.createMapper("costCentreSubgroup"),
            }
        );
    }


    costCentreLevel1AfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL1_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel1AfterTransfer",
                mapToOptions: this.createMapper("costCentreLevel1"),
            }
        );
    }


    costCentreLevel2AfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL2_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel2AfterTransfer",
                mapToOptions: this.createMapper("costCentreLevel2"),
            }
        );
    }


    costCentreLevel3AfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL3_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel3AfterTransfer",
                mapToOptions: this.createMapper("costCentreLevel3"),
            }
        );
    }


    costCentreLevel4AfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL4_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel4AfterTransfer",
                mapToOptions: this.createMapper("costCentreLevel4"),
            }
        );
    }


    costCentreLevel5AfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_LEVEL5_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreLevel5AfterTransfer",
                mapToOptions: this.createMapper("costCentreLevel5"),
            }
        );
    }


    costCentreTypeAfterTransferFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.COST_CENTRE_TYPE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreTypeAfterTransfer",
                mapToOptions: this.createMapper("costCentreType"),
            }
        );
    }


    costCentreBenchmarkTypeAfterTransferFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.COST_CENTRE_BENCHMARK_TYPE_AFTER_TRANSFER,
            params, {
                nameLC: "APP._Filters.CostCentreBenchmarkTypeAfterTransfer",
                mapToOptions: this.createMapper("costCentreBenchmarkType"),
            }
        );
    }


    costCentreRegulationTypeAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_REGULATION_TYPE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.CostCentreRegulationTypeAfterTransfer",
                mapToOptions: this.createMapper("costCentreRegulationType"),
            }
        );
    }


    costCentreRegulationTypeSubgroupAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_REGULATION_TYPE_SUBGROUP_AFTER_TRANSFER,
            params,
            {
                mapToOptions: this.createMapper("costCentreRegulationTypeSubgroup"),
            }
        );
    }


    productFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCT,
            params,
            {
                nameLC: "APP._Filters.Product",
                mapToOptions: this.createMapper("product"),
            }
        );
    }


    productTopgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCT_TOPGROUP,
            params,
            {
                nameLC: "APP._Filters.ProductTopgroup",
                mapToOptions: this.createMapper("productTopgroup"),
            }
        );
    }


    productGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCT_GROUP,
            params,
            {
                nameLC: "APP._Filters.ProductGroup",
                mapToOptions: this.createMapper("productGroup"),
            }
        );
    }


    productTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCT_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductType",
                mapToOptions: this.createMapper("productType"),
            }
        );
    }


    productSubmissionTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.PRODUCT_SUBMISSION_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductSubmissionType",
                mapToOptions: (codes: number[]) =>
                    _(codes)
                        .map(id => ({
                            id,
                            name: this._appDefinitions.getDisplayName("productSubmissionType", id),
                            order: id != null
                                ? this._appDefinitions.getOrderBy("productSubmissionType", id)
                                : Infinity,
                        }))
                        .sortBy(f => f.order)
                        .value(),
            }
        );
    }


    productionTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.PRODUCTION_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductionType",
                mapToOptions: this.createMapper("productionType"),
            }
        );
    }


    productionUnitTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.PRODUCTION_UNIT_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitType",
                mapToOptions: this.createMapper("productionUnitType"),
            }
        );
    }


    productionUnitFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT,
            params,
            {
                nameLC: "APP._Filters.ProductionUnit",
                mapToOptions: this.createMapper("productionUnit"),
            }
        );
    }


    productionUnitCostCentreFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_COST_CENTRE,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitCostCentre",
                mapToOptions: this.createMapper("costCentre"),
            }
        );
    }


    productionUnitTopgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_TOPGROUP,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitTopgroup",
                mapToOptions: this.createMapper("costCentreTopgroup"),
            }
        );
    }


    productionUnitGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_GROUP,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitGroup",
                mapToOptions: this.createMapper("costCentreGroup"),
            }
        );
    }


    productionUnitSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitSubgroup",
                mapToOptions: this.createMapper("costCentreSubgroup"),
            }
        );
    }


    productionUnitBenchmarkTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.PRODUCTION_UNIT_BENCHMARK_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitBenchmarkType",
                mapToOptions: this.createMapper("productionUnitBenchmarkType"),
            }
        );
    }


    productionUnitRegulationTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_REGULATION_TYPE,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitRegulationType",
                mapToOptions: this.createMapper("costCentreRegulationType"),
            }
        );
    }


    productionUnitRegulationTypeSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_REGULATION_TYPE_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitRegulationTypeSubgroup",
                mapToOptions: this.createMapper("costCentreRegulationTypeSubgroup"),
            }
        );
    }


    productionUnitLevel1Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_LEVEL1,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitLevel1",
                mapToOptions: this.createMapper("costCentreLevel1"),
            }
        );
    }


    productionUnitLevel2Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_LEVEL2,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitLevel2",
                mapToOptions: this.createMapper("costCentreLevel2"),
            }
        );
    }


    productionUnitLevel3Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_LEVEL3,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitLevel3",
                mapToOptions: this.createMapper("costCentreLevel3"),
            }
        );
    }


    productionUnitLevel4Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_LEVEL4,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitLevel4",
                mapToOptions: this.createMapper("costCentreLevel4"),
            }
        );
    }


    productionUnitLevel5Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.PRODUCTION_UNIT_LEVEL5,
            params,
            {
                nameLC: "APP._Filters.ProductionUnitLevel5",
                mapToOptions: this.createMapper("costCentreLevel5"),
            }
        );
    }


    resourceFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE,
            params,
            {
                nameLC: "APP._Filters.Resource",
                mapToOptions: this.createMapper("resource"),
            }
        );
    }


    resourceBenchmarkTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_BENCHMARK_TYPE,
            params,
            {
                nameLC: "APP._Filters.ResourceBenchmarkType",
                mapToOptions: this.createMapper("resourceBenchmarkType"),
            }
        );
    }


    resourceGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_ROOT,
            params,
            {
                nameLC: "APP._Filters.ResourceGroup",
                mapToOptions: this.createMapper("resource"),
            }
        );
    }


    resourceTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_TYPE,
            params,
            {
                nameLC: "APP._Filters.ResourceType",
                mapToOptions: this.createMapper("resourceType"),
            }
        );
    }


    submissionResourceFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SUBMISSION_RESOURCE,
            params,
            {
                nameLC: "APP._Filters.SubmissionResource",
                mapToOptions: this.createMapper("submissionResource"),
            }
        );
    }


    regulationResourceDefaultSourceFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.REGULATION_RESOURCE_DEFAULT_SOURCE,
            params,
            {
                nameLC: "APP._Filters.RegulationResourceDefaultSource",
                mapToOptions: this.createMapper("regulationResourceDefaultSource"),
            }
        );
    }

    resourceAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.ResourceAfterTransfer",
                mapToOptions: this.createMapper("resource"),
            }
        );
    }


    resourceBenchmarkTypeAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_BENCHMARK_TYPE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.ResourceBenchmarkTypeAfterTransfer",
                mapToOptions: this.createMapper("resourceBenchmarkType"),
            }
        );
    }


    resourceGroupAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_ROOT_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.ResourceGroupAfterTransfer",
                mapToOptions: this.createMapper("resource"),
            }
        );
    }


    resourceTypeAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.RESOURCE_TYPE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.ResourceTypeAfterTransfer",
                mapToOptions: this.createMapper("resourceType"),
            }
        );
    }


    regulationResourceDefaultSourceAfterTransferFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.REGULATION_RESOURCE_DEFAULT_SOURCE_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.RegulationResourceDefaultSourceAfterTransfer",
                mapToOptions: this.createMapper("regulationResourceDefaultSource"),
            }
        );
    }


    roomFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ROOM,
            params,
            {
                nameLC: "APP._Filters.Room",
                mapToOptions: this.createMapper("room"),
            }
        );
    }


    taskFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.TASK,
            params,
            {
                nameLC: "APP._Filters.Task",
                mapToOptions: this.createMapper("task"),
            }
        );
    }


    subtaskFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SUBTASK,
            params,
            {
                nameLC: "APP._Filters.Subtask",
                mapToOptions: this.createMapper("subtask"),
            }
        );
    }


    regulationTaskFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_TASK,
            params,
            {
                mapToOptions: this.createMapper("regulationTask"),
            }
        );
    }


    regulationTaskSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_TASK_SUBGROUP,
            params,
            {
                mapToOptions: this.createMapper("regulationTaskSubgroup"),
            }
        );
    }


    taskAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.TASK_AFTER_TRANSFER,
            params,
            {
                nameLC: "APP._Filters.TaskAfterTransfer",
                mapToOptions: this.createMapper("task"),
            }
        );
    }


    regulationTaskAfterTransferFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_TASK_AFTER_TRANSFER,
            params,
            {
                mapToOptions: this.createMapper("regulationTask"),
            }
        );
    }


    accountFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACCOUNT,
            params,
            {
                nameLC: "APP._Filters.Account",
                mapToOptions: this.createMapper("account"),
            }
        );
    }


    subAccountFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SUB_ACCOUNT,
            params,
            {
                nameLC: "APP._Filters.SubAccount",
                mapToOptions: this.createMapper("subAccount"),
            }
        );
    }


    jobTitleGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.JOB_TITLE_GROUP,
            params,
            {
                nameLC: "APP._Filters.JobTitleGroup",
                mapToOptions: this.createMapper("jobTitleGroup"),
            }
        );
    }

    jobTitleFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.JOB_TITLE,
            params,
            {
                nameLC: "APP._Filters.JobTitle",
                mapToOptions: this.createMapper("jobTitle"),
            }
        );
    }

    jobTitleTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.JOB_TITLE_TYPE,
            params,
            {
                nameLC: "APP._Filters.JobTitleType",
                mapToOptions: this.createMapper("jobTitleType"),
            }
        );
    }


    jobTitleBenchmarkTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.JOB_TITLE_BENCHMARK_TYPE,
            params,
            {
                nameLC: "APP._Filters.JobTitleBenchmarkType",
                mapToOptions: this.createMapper("jobTitleBenchmarkType"),
            }
        );
    }

    accountSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACCOUNT_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.AccountSubgroup",
                mapToOptions: this.createMapper("accountSubgroup"),
            }
        );
    }


    accountGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACCOUNT_GROUP,
            params,
            {
                nameLC: "APP._Filters.AccountGroup",
                mapToOptions: this.createMapper("accountGroup"),
            }
        );
    }


    accountTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACCOUNT_TYPE,
            params,
            {
                nameLC: "APP._Filters.AccountType",
                mapToOptions: this.createMapper("accountType"),
            }
        );
    }


    accountBenchmarkTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACCOUNT_BENCHMARK_TYPE,
            params,
            {
                nameLC: "APP._Filters.AccountBenchmarkType",
                mapToOptions: this.createMapper("accountBenchmarkType"),
            }
        );
    }


    accountRegulationTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACCOUNT_REGULATION_TYPE,
            params,
            {
                nameLC: "APP._Filters.AccountRegulationType",
                mapToOptions: this.createMapper("accountRegulationType"),
            }
        );
    }


    accountRegulationTypeSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACCOUNT_REGULATION_TYPE_SUBGROUP,
            params,
            {
                mapToOptions: this.createMapper("accountRegulationTypeSubgroup"),
            }
        );
    }


    accountAllocationRuleTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACCOUNT_ALLOCATION_RULE_TYPE_ID,
            params,
            {
                mapToOptions: this.createMapper("ruleType"),
            }
        );
    }


    accountAllocationRuleGroupFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACCOUNT_ALLOCATION_RULE_GROUP_ID,
            params,
            {
                mapToOptions: this.createMapper("accountAllocationRuleGroup"),
            }
        );
    }


    accountAllocationRuleFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACCOUNT_ALLOCATION_RULE_ID,
            params,
            {
                mapToOptions: this.createMapper("accountAllocationRule"),
            }
        );
    }


    costCentreRegulationTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_REGULATION_TYPE,
            params,
            {
                nameLC: "APP._Filters.CostCentreRegulationType",
                mapToOptions: this.createMapper("costCentreRegulationType"),
            }
        );
    }


    costCentreRegulationTypeSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CENTRE_REGULATION_TYPE_SUBGROUP,
            params,
            {
                mapToOptions: this.createMapper("costCentreRegulationTypeSubgroup"),
            }
        );
    }


    regulationResourceTopgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_RESOURCE_TOPGROUP,
            params,
            {
                nameLC: "APP._Filters.RegulationResourceTopgroup",
                mapToOptions: this.createMapper("regulationResourceTopgroup"),
            }
        );
    }


    regulationResourceGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_RESOURCE_GROUP,
            params,
            {
                nameLC: "APP._Filters.RegulationResourceGroup",
                mapToOptions: this.createMapper("regulationResourceGroup"),
            }
        );
    }


    regulationResourceSubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_RESOURCE_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.RegulationResourceSubgroup",
                mapToOptions: this.createMapper("regulationResourceSubgroup"),
            }
        );
    }


    resourceAllocationRuleTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RESOURCE_ALLOCATION_RULE_TYPE,
            params,
            {
                nameLC: "APP._Filters.ResourceAllocationRuleType",
                mapToOptions: this.createMapper("ruleType"),
            }
        );
    }


    resourceAllocationRuleGroupFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RESOURCE_ALLOCATION_RULE_GROUP,
            params,
            {
                nameLC: "APP._Filters.ResourceAllocationRuleGroup",
                mapToOptions: this.createMapper("resourceAllocationRuleGroup"),
            }
        );
    }


    resourceAllocationRuleFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RESOURCE_ALLOCATION_RULE,
            params,
            {
                nameLC: "APP._Filters.ResourceAllocationRule",
                mapToOptions: this.createMapper("resourceAllocationRule"),
            }
        );
    }


    supportCostAllocationRuleTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.SUPPORT_COST_ALLOCATION_RULE_TYPE,
            params,
            {
                nameLC: "APP._Filters.SupportCostAllocationRuleType",
                mapToOptions: this.createMapper("ruleType"),
            }
        );
    }


    supportCostAllocationRuleGroupFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.SUPPORT_COST_ALLOCATION_RULE_GROUP,
            params,
            {
                nameLC: "APP._Filters.SupportCostAllocationRuleGroup",
                mapToOptions: this.createMapper("resourceAllocationRuleGroup"),
            }
        );
    }


    supportCostAllocationRuleFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.SUPPORT_COST_ALLOCATION_RULE,
            params,
            {
                nameLC: "APP._Filters.SupportCostAllocationRule",
                mapToOptions: this.createMapper("resourceAllocationRule"),
            }
        );
    }


    regulationResourceFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_RESOURCE,
            params,
            {
                nameLC: "APP._Filters.RegulationResource",
                mapToOptions: this.createMapper("regulationResource"),
            }
        );
    }


    regulationActivityFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_ACTIVITY,
            params,
            {
                nameLC: "APP._Filters.RegulationActivity",
                mapToOptions: this.createMapper("activity"),
            }
        );
    }

    activityRegulationLabel1Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_REGULATION_LABEL1,
            params,
            {
                nameLC: "APP._Filters.ActivityRegulationLabel1",
                mapToOptions: this.createPlainMapper(),
            }
        );
    }


    activityRegulationLabel2Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_REGULATION_LABEL2,
            params,
            {
                nameLC: "APP._Filters.ActivityRegulationLabel2",
                mapToOptions: this.createPlainMapper(),
            }
        );
    }

    activityRegulationLabel3Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_REGULATION_LABEL3,
            params,
            {
                nameLC: "APP._Filters.ActivityRegulationLabel3",
                mapToOptions: this.createPlainMapper(),
            }
        );
    }

    activityBenchmarkTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ACTIVITY_BENCHMARK_TYPE,
            params,
            {
                nameLC: "APP._Filters.ActivityBenchmarkType",
                mapToOptions: this.createMapper("activityBenchmarkType"),
            }
        );
    }

    activityFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY,
            params,
            {
                nameLC: "APP._Filters.Activity",
                mapToOptions: this.createMapper("activity"),
            }
        );
    }


    activityGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_GROUP,
            params,
            {
                nameLC: "APP._Filters.ActivityGroup",
                mapToOptions: this.createMapper("activityGroup"),
            }
        );
    }


    activitySubgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_SUBGROUP,
            params,
            {
                nameLC: "APP._Filters.ActivitySubgroup",
                mapToOptions: this.createMapper("activitySubgroup"),
            }
        );
    }


    activityTopgroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_TOPGROUP,
            params,
            {
                nameLC: "APP._Filters.ActivityTopgroup",
                mapToOptions: this.createMapper("activityTopgroup"),
            }
        );
    }


    activityTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_TYPE,
            params,
            {
                nameLC: "APP._Filters.ActivityType",
                mapToOptions: this.createMapper("activityType"),
            }
        );
    }

    activitySourceTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_SOURCE_TYPE,
            params,
            {
                nameLC: "APP._Filters.ActivitySourceType",
                mapToOptions: this.createMapper("activitySourceType"),
            }
        );
    }

    activityDimension1Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_DIMENSION1,
            params,
            {
                nameLC: "APP._Filters.ActivityDimension1",
                mapToOptions: this.createMapper("activityDimension1"),
            }
        );
    }

    activityDimension2Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_DIMENSION2,
            params,
            {
                nameLC: "APP._Filters.ActivityDimension2",
                mapToOptions: this.createMapper("activityDimension2"),
            }
        );
    }

    activityDimension3Filter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.ACTIVITY_DIMENSION3,
            params,
            {
                nameLC: "APP._Filters.ActivityDimension1",
                mapToOptions: this.createMapper("activityDimension3"),
            }
        );
    }


    specialismFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SPECIALISM,
            params,
            {
                nameLC: "APP._Filters.Specialism",
                mapToOptions: this.createMapper("specialism"),
            }
        );
    }


    executingSpecialismFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.EXECUTING_SPECIALISM,
            params,
            {
                nameLC: "APP._Filters.ExecutingSpecialism",
                mapToOptions: this.createMapper("specialism"),
            }
        );
    }

    executingSpecialismSourceTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.EXECUTING_SPECIALISM_SOURCE_TYPE,
            params,
            {
                nameLC: "APP._Filters.ExecutingSpecialismSourceType",
                mapToOptions: this.createMapper("specialismSourceType"),
            }
        );
    }


    regulationSpecialismFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_SPECIALISM,
            params,
            {
                nameLC: "APP._Filters.RegulationSpecialism",
                mapToOptions: this.createMapper("specialism"),
            }
        );
    }


    segmentFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SEGMENT,
            params,
            {
                nameLC: "APP._Filters.Segment",
                mapToOptions: this.createMapper("segment"),
            }
        );
    }


    regulationSegmentFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.REGULATION_SEGMENT,
            params,
            {
                nameLC: "APP._Filters.RegulationSegment",
                mapToOptions: this.createMapper("segment"),
            }
        );
    }


    submissionTypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SUBMISSION_TYPE,
            params,
            {
                nameLC: "APP._Filters.SubmissionType",
                mapToOptions: this.createMapper("submissionType"),
            }
        );
    }


    submissionSubtypeFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.SUBMISSION_SUBTYPE,
            params,
            {
                nameLC: "APP._Filters.SubmissionSubtype",
                mapToOptions: this.createMapper("submissionSubtype"),
            }
        );
    }


    financialStatementGroupFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.FINANCIAL_STATEMENT_GROUP,
            params,
            {
                nameLC: "APP._Filters.FinancialStatementGroup",
                mapToOptions: this.createMapper("financialStatementGroup"),
            }
        );
    }


    machineFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.MACHINE,
            params,
            {
                nameLC: "APP._Filters.Machine",
                mapToOptions: this.createMapper("machine"),
            }
        );
    }


    materialFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.MATERIAL,
            params,
            {
                nameLC: "APP._Filters.MaterialType",
                mapToOptions: this.createMapper("material"),
            }
        );
    }


    materialTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.MATERIAL_TYPE,
            params,
            {
                nameLC: "APP._Filters.Material",
                mapToOptions: this.createMapper("materialType"),
            }
        );
    }


    machineTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.MACHINE_TYPE,
            params,
            {
                nameLC: "APP._Filters.MachineType",
                mapToOptions: this.createMapper("machineType"),
            }
        );
    }


    transferCostDriverFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.TRANSFER_COST_DRIVER,
            params,
            {
                nameLC: "APP._Filters.CostDriver",
                mapToOptions: (codes: number[]) =>
                    _(codes)
                        .map(id => ({
                            id,
                            name: id != null
                                ? this._appDefinitions.getDisplayName("costDriver", id)
                                : this._translationService.translate("APP._.NotSet"),
                            order: id != null
                                ? this._appDefinitions.getOrderBy("costDriver", id)
                                : -1,
                        }))
                        .sortBy(f => f.order)
                        .value(),
            }
        );
    }


    costDriverFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.COST_DRIVER,
            params,
            {
                nameLC: "APP._Filters.CostDriver",
                mapToOptions: (codes: number[]) =>
                    _(codes)
                        .map(id => ({
                            id,
                            name: id != null
                                ? this._appDefinitions.getDisplayName("costDriver", id)
                                : this._translationService.translate("APP._.NotSet"),
                            order: id != null
                                ? this._appDefinitions.getOrderBy("costDriver", id)
                                : -1,
                        }))
                        .sortBy(f => f.order)
                        .value(),
            }
        );
    }


    transferMethodFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.TRANSFER_METHOD,
            params,
            {
                nameLC: "APP._Filters.TransferMethod",
                mapToOptions: (ids: number[]) =>
                    _(ids)
                        .map(id => ({
                            id,
                            name: id != null
                                ? this._appDefinitions.getDisplayName("resourceTaskTransferMethod", id)
                                : this._translationService.translate("APP._.NotSet"),
                            order: id != null
                                ? this._appDefinitions.getOrderBy("resourceTaskTransferMethod", id)
                                : -1,
                        }))
                        .sortBy(f => f.order)
                        .value(),
            }
        );
    }


    transferTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.TRANSFER_TYPE,
            params,
            {
                nameLC: "APP._Filters.TransferType",
                mapToOptions: (ids: number[]) =>
                    _(ids)
                        .map(code => ({
                            id: code,
                            name: code != null
                                ? this._appDefinitions.getDisplayName("transferType", code)
                                : this._translationService.translate("APP._.NotSet"),
                            order: code != null
                                ? this._appDefinitions.getOrderBy("transferType", code)
                                : -1,
                        }))
                        .sortBy(f => f.order)
                        .value(),
            }
        );
    }


    sourceIsDirect(params: Combo2Parameters<boolean>) {
        /* eslint-disable quote-props */
        const displayNames = {
            "null": {
                id: null,
                name: this._translationService.translate("APP._Filters.IsDirect.NotKnown"),
                order: 0
            },
            "true": {id: true, name: this._translationService.translate("APP._Filters.IsDirect.Direct"), order: 1},
            "false": {id: false, name: this._translationService.translate("APP._Filters.IsDirect.Indirect"), order: 2},
        };
        /* eslint-enable quote-props */

        return this.addFilter(
            FilterId.IS_DIRECT,
            {
                filterType: "combo2",
                main: true,
                idType: "boolean" as any, // Hack because normally boolean is not supported here
                visible: () => true,
                allowNullValues: true,
                ...params,
                nameLC: "APP._Filters.IsDirect",
                mapToOptions: (ids) =>
                    _(ids)
                        .map(id => displayNames[id?.toString() ?? "null"])
                        .sortBy(f => f.order)
                        .value(),
            } as IFilterFactoryEntry<Filters.IComboFilter2Definition<boolean>>
        );
    }


    unitCostReferenceSourceFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.REFERENCE_SOURCE,
            params,
            {
                nameLC: "APP._Filters.UnitCostReferenceSource",
                mapToOptions: this.createMapper("unitCostReferenceSource"),
            }
        );
    }


    costClassificationFilter(params: Combo2Parameters<string>) {
        return this.createCombo2String(
            FilterId.COST_CLASSIFICATION,
            params,
            {
                nameLC: "APP._Filters.CostClassification",
                mapToOptions: this.createMapper("costClassification"),
            }
        );
    }


    adjustmentTypeFilter(params?: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.ADJUSTMENT_TYPE,
            params,
            {
                nameLC: "APP._Filters.AdjustmentType",
                mapToOptions: () => ([
                    {id: 1, name: this._translationService.translate("APP._Filters.AdjustmentTypeAccountSplits")},
                    {
                        id: 2,
                        name: this._translationService.translate("APP._Filters.AdjustmentTypeAccountCorrections")
                    },
                ]),
                source: () => [],
            }
        );
    }


    resourceTaskToActivityRuleFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RULE,
            params,
            {
                nameLC: "APP._Filters.Rule",
                mapToOptions: this.createMapper("resourceTaskToActivityRule"),
            }
        );
    }


    resourceTaskToActivityRuleGroupFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RULE_GROUP,
            params,
            {
                nameLC: "APP._Filters.RuleGroup",
                mapToOptions: this.createMapper("resourceTaskToActivityRuleGroup"),
            }
        );
    }


    ruleTypeFilter(params: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.RULE_TYPE,
            params,
            {
                nameLC: "APP._Filters.RuleType",
                mapToOptions: this.createMapper("ruleType"),
            }
        );
    }


    dataErrorFilter(params?: Combo2Parameters<number>) {
        return this.createCombo2Number(
            FilterId.DATA_ERRORS,
            params,
            {
                nameLC: "APP._Filters.Errors",
                mapToOptions: ids => {
                    const getName = (id: number): string => {
                        const def = this._appDefinitions.errors
                            && this._appDefinitions.errors[id];

                        if (!def) return id.toString();

                        const uid = def.uid.split(".").pop();

                        return this._translationService.translate(`APP._DataErrors.${uid}`);
                    };

                    return _.map(_.values(ids), id => ({
                        id,
                        name: getName(id)
                    }));
                },
            }
        );
    }


    // ---------------------------------------------------------------------------------------------
    addFilter<T extends SupportedFilters, N extends string>(
        id: N,
        params: T
    ): FilterFactoryCreator<Definitions & { [P in N]: MapFilterDefinition<T> },
        Filters & { [P in N]: MapFilterStore<T> }> {
        return this._addFilter(id, params);
    }


    addMappedNumberCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<number> & {
            optionName: (id: number) => string;
            optionOrderById?: boolean;
        }
    ) {
        return this.createCombo2Number(
            id,
            params,
            {
                mapToOptions: ids =>
                    _(ids)
                        .map(mapId => {
                            const name = params.optionName(mapId);
                            return {
                                id: mapId,
                                name,
                                sort: params.optionOrderById ? mapId : name
                            };
                        })
                        .sortBy(f => f.sort)
                        .value()
            }
        );
    }


    addMappedStringCombo2Filter<T extends string>(
        id: T,
        params: Combo2Parameters<string> & {
            optionName: (id: string) => string;
            optionOrderById?: boolean;
        }
    ) {
        return this.createCombo2String(
            id,
            params,
            {
                mapToOptions: ids =>
                    _(ids)
                        .map(mapId => {
                            const name = params.optionName(mapId);
                            return {
                                id: mapId,
                                name,
                                sort: params.optionOrderById ? mapId : name
                            };
                        })
                        .sortBy(f => f.sort)
                        .value()
            }
        );
    }


    addCheckboxFilter<T extends string>(id: T, params: CheckboxParameters) {
        return this.createCheckbox(
            id,
            params,
            null
        );
    }


    /* addErrorFilter<T extends string>( id: T, params: CheckboxParameters ) {
        return this.createCheckbox(
            id,
            params,
            {
                nameLC: "APP._Filters.Errors"
            }
        );
    }*/


    // ---------------------------------------------------------------------------------------------
    //  Private helpers
    // ---------------------------------------------------------------------------------------------

    private createCombo2Number<T extends string>(
        id: T,
        params: Combo2Parameters<number>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>>
    ) {
        return this.addFilter(
            id,
            {
                filterType: "combo2",
                main: true,
                idType: "number",
                visible: () => true,
                allowNullValues: true,
                ...defaults,
                ...params
            } as IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>
        );
    }


    private createCombo2String<T extends string>(
        id: T,
        params: Combo2Parameters<string>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>>
    ) {
        return this.addFilter(
            id,
            {
                filterType: "combo2",
                main: true,
                idType: "string",
                visible: () => true,
                allowNullValues: true,
                ...defaults,
                ...params
            } as IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>
        );
    }


    private createCheckbox<T extends string>(
        id: T,
        params: CheckboxParameters,
        defaults: Partial<IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>>
    ) {
        return this.addFilter(
            id,
            {
                filterType: "checkbox",
                main: true,
                visible: () => true,
                ...defaults,
                ...params
            } as IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>
        );
    }


    createMapper<T extends number | string>(definition: DefinitionKey) {
        return (codes: T[]) =>
            _(codes)
                .map(code => ({
                    id: code,
                    name: this._appDefinitions.getDisplayName(definition, code),
                    order: this._appDefinitions.getOrderBy(definition, code),
                }))
                .sortBy(f => f.order)
                .value();
    }


    private createDictionaryMapper<T extends number | string>(definition: _.Dictionary<string>) {
        return (codes: T[]) =>
            _(codes)
                .map(code => ({
                    id: code,
                    name: definition[code],
                    order: code,
                }))
                .sortBy(f => f.order)
                .value();
    }


    createPlainMapper<T extends number | string>() {
        return (codes: T[]) =>
            _(codes)
                .map(code => ({
                    id: code,
                    name: code,
                    order: code,
                }))
                .sortBy(f => f.order)
                .value();
    }
}
