import { LG_USER_INFO } from "@logex/framework/lg-application";
import { ApplicationPermissions } from "../app-permissions";
import { AppUser } from "../app-user";
import * as _ from "lodash";


export { AppUser, ApplicationPermissions, LG_USER_INFO };

export abstract class AuthorizationBase {

    constructor(
        protected _applicationPermissions: ApplicationPermissions,
        protected _userInfo: AppUser
    ) {
        this.admin = this._userInfo.hasPermission(ApplicationPermissions.ADMIN);
        this.internal = this._userInfo.hasPermission(ApplicationPermissions.INTERNAL);

        this.viewAllCostCentres = this._userInfo.hasPermission(ApplicationPermissions.VIEW_ALL_COST_CENTRES);
        this.editAllCostCentres = this._userInfo.hasPermission(ApplicationPermissions.EDIT_ALL_COST_CENTRES);
        this.viewAllSpecialism = this._userInfo.hasPermission(ApplicationPermissions.VIEW_ALL_SPECIALISMS);
        this.viewRules = this._userInfo.hasPermission(ApplicationPermissions.VIEW_RULES);
        this.activateSet = this._userInfo.hasPermission(ApplicationPermissions.ACTIVATE_SET);
        this.uploadSet = this._userInfo.hasPermission(ApplicationPermissions.UPLOAD_SET);
        // this.open = this._userInfo.hasPermission( _applicationPermissions.Open );
        // this.viewSettings = this._userInfo.hasPermission( _applicationPermissions.ViewSettings );
        // this.editSettings = this._userInfo.hasPermission( _applicationPermissions.EditSettings );
        // this.switchHospital = this._userInfo.hasPermission( _applicationPermissions.SwitchHospital );
        // this.switchDatabase = this._userInfo.hasPermission( _applicationPermissions.SwitchDatabase );
        // this.mergedHospitals = this._userInfo.hasPermission( _applicationPermissions.MergedHospitals );
        // this.switchLanguage = this._userInfo.hasPermission( _applicationPermissions.SwitchLanguage );
        // this.limitedReferences = this._userInfo.hasPermission( _applicationPermissions.LimitedReferencesUser );
    }

    access: boolean;
    edit: boolean;
    // abstract readonly export: boolean;

    readonly admin: boolean;
    readonly internal: boolean;
    readonly viewRules: boolean;
    // readonly open: boolean;
    // readonly viewSettings: boolean;
    // readonly editSettings: boolean;
    // readonly switchHospital: boolean;
    // readonly switchDatabase: boolean;
    // readonly mergedHospitals: boolean;
    // readonly switchLanguage: boolean;

    readonly viewAllCostCentres: boolean;
    readonly editAllCostCentres: boolean;
    readonly viewAllSpecialism: boolean;

    readonly activateSet: boolean;
    readonly uploadSet: boolean;

    private _costCentresReadSet: Set<string>;
    private _costCentresModifySet: Set<string>;


    get costCentresRead(): string[] | null {
        return this._userInfo.costCentresRead;
    }


    get costCentresModify(): string[] | null {
        return this._userInfo.costCentresModify;
    }


    filterReadableCostCentres<T extends { costCentreCode: string; }>(data: T[]): T[];
    filterReadableCostCentres<T extends object, CostCentreField extends keyof T>(data: T[], field: CostCentreField): T[];
    filterReadableCostCentres<T extends object, CostCentreField extends keyof T>(data: T[], field?: CostCentreField): T[] {
        if (this.costCentresRead == null) return data;

        if (this._costCentresReadSet == null) {
            this._costCentresReadSet = new Set(this.costCentresRead);
        }

        const fieldName = field ?? "costCentreCode";
        return _.filter(data, (x: any) => this._costCentresReadSet.has(x[fieldName]));
    }

    canModifyCostCentre(costCentreCode: string): boolean;
    canModifyCostCentre(row: { costCentreCode: string; }): boolean;
    canModifyCostCentre(input: string | { costCentreCode: string; }): boolean {
        if (!this.edit) {
            return false;
        }

        if (this.costCentresModify == null) {
            return true;
        }

        if (this._costCentresModifySet == null) {
            this._costCentresModifySet = new Set(this.costCentresModify);
        }

        let costCentreCode: string;
        if (typeof input === "object") {
            costCentreCode = input.costCentreCode;
        } else {
            costCentreCode = input;
        }

        return this._costCentresModifySet.has(costCentreCode);
    }
}
