/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import {
    RuleGroup,
    SaveRulesArguments,
    SelectCostDriverArguments,
    SelectFiltersDataArguments,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../bases/rules-base/gateways/rules-gateway.types";
import { SelectTransferFiltersSchema, TransferCostDriverInfo, TransferRule } from "../../bases/transfer-rules-base/gateway/transfer-rules-gateway.type";
import { RulesGateway, SaveRulesResult } from "../../bases/rules-base/gateways/rules-gateway";


@Injectable()
export class EditAccountRulesGateway extends AppServerGatewayBase implements RulesGateway<TransferRule, TransferCostDriverInfo> {

    selectRules(args: SelectRulesArguments) {
        return this._get<TransferRule[]>("rules/accountTransfer",
            { params: args });
    }


    selectRuleGroups(args: SelectRuleGroupArguments) {
        return this._get<RuleGroup[]>("rules/accountTransfer/groups",
            { params: args });
    }


    selectCostDrivers(args: SelectCostDriverArguments) {
        return this._get<TransferCostDriverInfo[]>("rules/accountTransfer/costDriver",
            { params: args });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments) {
        return this._get<SelectTransferFiltersSchema>("rules/accountTransfer/filters/schema",
            { params: args });
    }


    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountTransfer/filters/data",
            { params: args });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountTransfer/filters/targetData",
            { params: args });
    }


    saveRules(args: SaveRulesArguments<TransferRule>) {
        return this._post<SaveRulesResult>("rules/accountTransfer",
            { body: args });
    }
}
