import { IInfoboxSpecification, LgExcelFactoryService, LogexXlsxApi } from "@logex/framework/lg-exports";
import { LG_LOCALIZATION_SETTINGS, LgLocalizationSettings, LgTranslateService } from "@logex/framework/lg-localization";

import { inject } from "@angular/core";
import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { AppSession } from "../types";
import { AppDefinitions } from "@shared";


export abstract class ExportBase {

    private _session = inject<AppSession>(LG_APP_SESSION);
    private _localizationSettings = inject<LgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    protected _lgTranslate = inject(LgTranslateService);
    protected _definitions = inject(AppDefinitions);

    constructor() {
        const lgXlsxService = inject(LgExcelFactoryService);
        this._lgXlsx = lgXlsxService.create();
        this._lgXlsx.setDefaultStyle(this._lgXlsx.styles.logex);
        this.translate = this.translate.bind(this);
    }


    protected _lgXlsx: LogexXlsxApi;
    abstract _translationNamespace: string;


    // ----------------------------------------------------------------------------------
    //
    protected getFilename(exportName?: string): string {
        const date = new Date().toISOString()
            .slice(0, 10)
            .replace(/-/g, "");

        const abbr = this._session.client?.abbr != null
            ? ` - ${this._session.client.abbr}`
            : "";

        const toolName = this._lgTranslate.translate("APP._Exports.ToolNameAppendix");
        const defaultExportName = this.translate(".Export.Name");

        return `${date}${abbr} - ${toolName} - ${exportName ?? defaultExportName}`;
    }


    protected getDefaultInfobox(addInfo?: Array<string | (() => string)>): IInfoboxSpecification {
        return {
            row: 0,
            column: 0,
            spacing: 1,
            labelSpan: 1,
            textSpan: 1,
            textAlign: "left",
            info: [
                this.translate("APP._Exports.Hospital"),
                () => `${this._session.client.code} - ${this._session.client.name}`,
                this.translate("APP._Exports.Scenario"),
                () => `${this._session.scenario.description}`,
                this.translate("APP._Exports.Date"),
                () => (new Date()).toLocaleDateString(this._localizationSettings.locale, {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                }) + " " + (new Date()).toLocaleTimeString(this._localizationSettings.locale),
                this.translate("APP._Exports.Period"),
                () => `${this._session.scenario.period}`,
                ...addInfo ?? []
            ]
        };
    }


    protected translate(translationId: string, interpolateParams?: object): string {
        if (this._translationNamespace && translationId[0] === ".") {
            translationId = this._translationNamespace + translationId;
        }
        return this._lgTranslate.translate(translationId, interpolateParams);
    }


    protected getTranslateFn(): (translationId: string, interpolateParams?: any, interpolationId?: string) => string {
        return this.translate;
    }


    protected translateFileNameWithCurrentTime(translationId: string, interpolationObject: object = {}): string {
        return this.translate(
            translationId,
            { ...this._getFormattedDateForInterpolationObject(), ...interpolationObject }
        );
    }


    private _getFormattedDateForInterpolationObject(): { date: string; } {
        const now = new Date();

        return {
            date: now
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "")
        }; // https://stackoverflow.com/a/16714931
    }
}
