import { IUser } from "@logex/framework/lg-application";

export class AppUser implements IUser {

    id: number;
    name: string;
    roles: _.Dictionary<boolean>;
    title: string;
    userid: string;
    impersonator: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;

    // Resource access restrictions
    costCentresRead: string[];
    costCentresModify: string[];


    constructor() {
        this.roles = {};
    }


    public hasPermission(role: string): boolean {
        return this.roles[role] || false;
    }
}
