<div class="body__holder">
    <lg-sidebar>
        <!--
        <lg-sidebar-switch
            *lgSidebarPanel="let sidebar; icon: 'icon-product-logo_costing'; large: true; top: true; nameAlwaysVisible: true; disabled: true; id: 'logo'"
            [sidebar]="sidebar"
        ></lg-sidebar-switch>
        -->

        <lg-sidebar-button
            (click)="_openDashboard()"
            icon="icon-product-logo_costing"
            [large]="true"
            [nameAlwaysVisible]="false"
            [onTop]="true"
        ></lg-sidebar-button>

        <ng-container *ngFor="let menuItem of _sideBarMenuItems">
            <lg-sidebar-menu
                *lgSidebarPanel="let sidebar; nameLc: menuItem.nameLc; icon: menuItem.icon; top: true; nameAlwaysVisible: true; id: menuItem.id; isVisible: menuItem.isVisible"
                [navigationRootId]="menuItem.id"
                [sidebar]="sidebar"
            ></lg-sidebar-menu>
        </ng-container>

        <ng-container *ngIf="_sideBarMenuItems != null">
            <lg-sidebar-exports
                *lgSidebarPanel="let sidebar; nameLc: 'APP._Sidebar.Export'; icon: 'icon-sidebar-export'; top: true; nameAlwaysVisible: true; id: 'export'"
                [sidebar]="sidebar"
            >
                <div class="lg-sidebar-menu-item--divider"></div>
                <lg-sidebar-menu
                    [navigationRootId]="'detail-exports'"
                    [sidebar]="sidebar"
                    style="margin-right: 6px;"
                ></lg-sidebar-menu>
            </lg-sidebar-exports>

        </ng-container>

        <!--<ng-container *ngIf="_refresh">
            <fcm-recalculation-status *lgSidebarWidget="let ctx; onTop:true"></fcm-recalculation-status>
        </ng-container>-->

        <lg-sidebar-help
            *lgSidebarPanel="let sidebar; nameLc: 'APP._Sidebar.Help'; icon:'icon-sidebar-help'; top: false; id: 'help'"
            [standardLinks]="_appHelpLinks"
            [additionalItems]="_additionalLinks"
            [supportLink]="null"
            [versionClickable]="false"
        >
            <ng-template
                let-internal="internal"
                #extension
            >
                <div class="lg-sidebar-help__bottom">
                    <div class="lg-sidebar-help__bottom-group" *ngIf="userInfo">
                        <div
                            class="lg-sidebar-help__bottom-group__header">{{ 'APP._Sidebar._Help.Client'|lgTranslate }}
                        </div>
                        <div
                            class="lg-sidebar-help__text">{{ userInfo?.ziekenhuiscode }} {{ userInfo?.ziekenhuisnaam }}
                        </div>
                    </div>

                    <div class="lg-sidebar-help__bottom-group">
                        <div
                            class="lg-sidebar-help__bottom-group__header">{{ 'APP._Sidebar._Help.Scenario'|lgTranslate }}
                        </div>
                        <div class="lg-sidebar-help__text">{{ _session.scenario.name }}
                        </div>
                    </div>

                    <div class="lg-sidebar-help__bottom-group">
                        <div
                            class="lg-sidebar-help__bottom-group__header">{{ 'APP._Sidebar._Help.Period'|lgTranslate }}
                        </div>
                        <div class="lg-sidebar-help__text">{{ _session.scenario.period }}
                        </div>
                    </div>

                    <div class="lg-sidebar-help__bottom-group" *ngIf="internal">
                        <div
                            class="lg-sidebar-help__bottom-group__header">{{ 'APP._Sidebar._Help.SystemId'|lgTranslate }}
                        </div>
                        <div class="lg-sidebar-help__text">{{ _session.scenario.clientId }}
                            - {{ _session.scenario.id }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </lg-sidebar-help>

        <lg-sidebar-account
            *lgSidebarPanel="let sidebar; nameAlwaysVisible: true; titleLc: false; icon: 'icon-account'; top: false; badge: _userMessages; id: 'account'"
        ></lg-sidebar-account>

        <ng-container *ngIf="_viewSettings">
            <lg-sidebar-settings
                *lgSidebarPanel="let sidebar; nameLc: 'APP._Sidebar.Settings'; icon: 'icon-settings'; top: false; id: 'settings'"
                [sidebar]="sidebar"
            ></lg-sidebar-settings>
        </ng-container>

        <div *ngIf="_devMode">
            <lg-sidebar-dev-widget *lgSidebarWidget></lg-sidebar-dev-widget>
        </div>

    </lg-sidebar>

    <div class="body__content">
        <div
            *ngIf="_bannerVisible"
            class="banner"
        >
            <lg-icon
                icon="icon-warning"
                class="icon--warning"
            ></lg-icon>

            <div *ngIf="_bannerHeader">
                <h3>{{ _bannerHeader }}</h3>
            </div>

            <div>{{ _bannerText }}</div>

            <div>
                <a
                    class="button button--condensed button--primary"
                    [href]="_bannerButtonLink"
                    target="_blank"
                >
                    {{ _bannerButtonText }}
                </a>
            </div>

            <lg-icon
                icon="icon-close"
                clickable="true"
                class="banner-item--right"
                (click)="_closeBanner()"
            ></lg-icon>
        </div>

        <div class="breadcrumb-row">
            <div class="breadcrumb-row__split">
                <lg-breadcrumb-data-context [showScenarioButton]="true" [showHospitalButton]="true">
                </lg-breadcrumb-data-context>
                <div class="breadcrumb-row__split__subrow-one">
                    <lg-sibling-placeholder name="breadcrumb-row.left.first">
                        <lg-breadcrumb
                            titlePrefixLc="APP.ToolName"
                            toolNameLc="APP.ToolName"
                            homeId="dashboard"
                            [toolNameParams]="{version: _appVersionTitle}"
                        ></lg-breadcrumb>
                    </lg-sibling-placeholder>
                    <lg-sibling-placeholder name="breadcrumb-row.left.second"></lg-sibling-placeholder>
                </div>
            </div>
            <lg-sibling-placeholder name="breadcrumb-row.right"></lg-sibling-placeholder>
        </div>

        <router-outlet></router-outlet>
    </div>

    <lg-slideout width="360" variant="right" id="slideout-panel">
        <lg-sibling-placeholder name="slideout-panel"></lg-sibling-placeholder>
        <ng-container *lgPlaceholderContent="'slideout-panel'">
        </ng-container>
    </lg-slideout>
</div>
