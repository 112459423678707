import * as _ from "lodash";
import {
    ICheckboxFilterDefinition,
    IComboFilter2Definition,
    IComboFilterDefinition,
    IDateFilterDefinition,
    IDateFilterValue,
    IDropdownFilterDefinition,
    IFilterDefinition,
    IFilterList,
    IInputRangeFilterDefinition,
    INormalizedFilterDefinition,
    IRangeFilterDefinition,
    IRangeFilterValue,
    ISelectableComboFilter2Definition,
    ISelectedItemFilterDefinition,
    ITristateFilterDefinition,
    LgFilterSet,
    LgFilterSetService
} from "@logex/framework/lg-filterset";
import { IColumnFilterDictionary } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IItemClusterFilterDefinition } from "@logex/framework/ui-toolbox";

// ---------------------------------------------------------------------------------------------
// Type that removes some fields from the general filter definitions
// ---------------------------------------------------------------------------------------------
export type IFilterFactoryEntry<T> =
    Pick<T, Exclude<keyof T, "id" | "startGroup" | "startGroupLC">>;


// ---------------------------------------------------------------------------------------------
//  Map wrapped filter definition into the correct filter store
// ---------------------------------------------------------------------------------------------
export type MapFilterStore<T, X = any> =
    T extends IFilterFactoryEntry<IComboFilterDefinition<X>> ? IColumnFilterDictionary :
        T extends IFilterFactoryEntry<IComboFilter2Definition<X>> ? IColumnFilterDictionary :
            T extends IFilterFactoryEntry<ISelectableComboFilter2Definition<X>> ? IColumnFilterDictionary :
                T extends IFilterFactoryEntry<ISelectedItemFilterDefinition> ? string | undefined :
                    T extends IFilterFactoryEntry<ICheckboxFilterDefinition> ? boolean :
                        T extends IFilterFactoryEntry<ITristateFilterDefinition> ? 1 | 0 | null :
                            T extends IFilterFactoryEntry<IDropdownFilterDefinition<X>> ? number | string | null :
                                T extends IFilterFactoryEntry<IRangeFilterDefinition> ? IRangeFilterValue :
                                    T extends IFilterFactoryEntry<IDateFilterDefinition> ? IDateFilterValue :
                                        T extends IFilterFactoryEntry<IInputRangeFilterDefinition> ? IRangeFilterValue :
                                            T extends IFilterFactoryEntry<IItemClusterFilterDefinition<X>> ? IColumnFilterDictionary :
                                                never;

// ---------------------------------------------------------------------------------------------
// Map wrapped filter definition back to the original type (unwrap IFilterFactoryEntry)
// ---------------------------------------------------------------------------------------------
// Note: this could be in theory done in simpler way using infer, but TS 2.8 doesn't seem to resolve the internal
// Type to anything better than "any"
export type MapFilterDefinition<T> =
    T extends IFilterFactoryEntry<IComboFilterDefinition<infer U>> ? IComboFilterDefinition<U> :
        T extends IFilterFactoryEntry<IComboFilter2Definition<infer U>> ? IComboFilter2Definition<U> :
            T extends IFilterFactoryEntry<ISelectableComboFilter2Definition<infer U>> ? ISelectableComboFilter2Definition<U> :
                T extends IFilterFactoryEntry<ISelectedItemFilterDefinition> ? ISelectedItemFilterDefinition :
                    T extends IFilterFactoryEntry<ICheckboxFilterDefinition> ? ICheckboxFilterDefinition :
                        T extends IFilterFactoryEntry<ITristateFilterDefinition> ? ITristateFilterDefinition :
                            T extends IFilterFactoryEntry<IDropdownFilterDefinition<infer U>> ? IDropdownFilterDefinition<U> :
                                T extends IFilterFactoryEntry<IRangeFilterDefinition> ? IRangeFilterDefinition :
                                    T extends IFilterFactoryEntry<IDateFilterDefinition> ? IDateFilterDefinition :
                                        T extends IFilterFactoryEntry<IInputRangeFilterDefinition> ? IInputRangeFilterDefinition :
                                            T extends IFilterFactoryEntry<IItemClusterFilterDefinition<infer U>> ? IItemClusterFilterDefinition<U> :
                                                never;

// ---------------------------------------------------------------------------------------------
export type SupportedFilters =
    IFilterFactoryEntry<IComboFilterDefinition<number>> |
    IFilterFactoryEntry<IComboFilterDefinition<string>> |
    IFilterFactoryEntry<IComboFilter2Definition<number>> |
    IFilterFactoryEntry<IComboFilter2Definition<string>> |
    IFilterFactoryEntry<IComboFilter2Definition<boolean>> |
    IFilterFactoryEntry<ISelectableComboFilter2Definition<number>> |
    IFilterFactoryEntry<ISelectableComboFilter2Definition<string>> |
    IFilterFactoryEntry<ISelectedItemFilterDefinition> |
    IFilterFactoryEntry<ICheckboxFilterDefinition> |
    IFilterFactoryEntry<ITristateFilterDefinition> |
    IFilterFactoryEntry<IDropdownFilterDefinition<number>> |
    IFilterFactoryEntry<IDropdownFilterDefinition<string>> |
    IFilterFactoryEntry<IRangeFilterDefinition> |
    IFilterFactoryEntry<IDateFilterDefinition> |
    IFilterFactoryEntry<IInputRangeFilterDefinition> |
    IFilterFactoryEntry<IItemClusterFilterDefinition<number>> |
    IFilterFactoryEntry<IItemClusterFilterDefinition<string>>;


// ---------------------------------------------------------------------------------------------
// Typing helper to merge 2 types (A is primary, B contains defaults)
type MergeTypes<A, B> = {
    [P in keyof A]: A[P]
} & {
    [P in Exclude<keyof B, keyof A>]: B[P]
};


// ---------------------------------------------------------------------------------------------
// Base class for the filter creator (i.e. the class that's used in the chain)
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreatorBase {

    protected _definition: IFilterDefinition[] = [];
    private _startGroup = false;
    private _startGroupLC: string | null = null;

    private _cursor = 0;


    public constructor(
        protected _filterSetService: LgFilterSetService,
        protected _translationService: LgTranslateService
    ) {
        // empty
    }


    protected _addFilter<T extends SupportedFilters, N extends string>(
        id: N,
        params: T
    ): any {
        const entry = _.clone(params) as any;
        entry["id"] = id;

        // Getting rid of some legacy behaviour
        if (entry["visible"] === undefined) entry["visible"] = () => true;
        if (entry["main"] === undefined) entry["main"] = true;

        // Add the separately configured startgroups
        if (this._startGroup) {
            entry["startGroup"] = true;
            this._startGroup = false;
        } else if (this._startGroupLC) {
            entry["startGroupLC"] = this._startGroupLC;
            this._startGroupLC = null;
        }

        this._definition.splice(this._cursor, 0, entry);
        this._cursor++;

        return this as any;
    }


    // public startGroup( nameLC?: string ): this {
    //     if ( nameLC ) {
    //         this._startGroupLC = nameLC;
    //         this._startGroup = false;
    //     } else {
    //         this._startGroup = true;
    //         this._startGroupLC = null;
    //     }
    //     return this;
    // }
    //
    //
    // public first(): this {
    //     this._cursor = 0;
    //     return this;
    // }
    //
    // public last(): this {
    //     this._cursor = this._definition.length;
    //     return this;
    // }
    //
    // public after( filterId: string ): this {
    //     const newIndex = _.findIndex( this._definition, x => x.id === filterId );
    //     this._cursor = newIndex >= 0 ? newIndex + 1 : this._cursor;
    //     return this;
    // }


    protected _create<
        Definitions extends Record<string, INormalizedFilterDefinition>,
        Filters extends IFilterList
    >(context: any): LgFilterSet<Definitions, Filters> {
        const result = this._filterSetService.create(this._definition, this._translationService, context) as any;
        result.asFilterSet = () => result;
        return result;
    }
}


