import { Directive } from "@angular/core";
import * as _ from "lodash";
import { dropdownFlat } from "@logex/framework/utilities";
import { TransferRuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/transfer-rule-editor-component-base";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { TransferCostDriverInfoExt } from "./gateway/transfer-rule-with-method-gateway.type";
import { DefinitionsResourceTaskTransferMethod, DefinitionsResourceTransferMethod } from "@shared";

@Directive()
export abstract class TransferRuleWithMethodEditorComponentBase extends TransferRuleEditorComponentBase {

    // @Input() must be in the derived class
    protected abstract costDrivers: TransferCostDriverInfoExt[];
    protected abstract methodId: number;
    
    protected abstract _methodDefinition: Record<string, DefinitionsResourceTransferMethod | DefinitionsResourceTaskTransferMethod>;


    protected _methodDropdown: IDropdownDefinition<number>;


    protected _getFormValues(): Record<string, unknown> {
        return {
            ...super._getFormValues(),
            methodId: this.methodId,
        }
    }


    protected _processCostDrivers(): void {

        // Method dropdown
        const methodIds = [...new Set([...this.costDrivers.map(x => x.methodId)])];
        const orderedMethods = _.sortBy(
            _.map(methodIds, x => this._methodDefinition[x]),
            "orderBy"
        );

        this._methodDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: _.map(orderedMethods, x => x),
        });

        this._updateCostDriverDropdown();
    }


    protected _updateRuleAfterChanges(): void {

        // Default logic is overridden as here allowed targets are depend on both, Cost driver and Method selection
        const costDriverDict = _.keyBy(this.costDrivers, x => `${x.costDriverId}__${x.methodId}`);

        const costDriverInfo = costDriverDict[`${this.costDriverId}__${this.methodId}`];

        this.allowedTargetUids = costDriverInfo?.targetUids;

        // Clear all target fields that are not allowed in this cost driver
        _.forOwn(this.targetFilters, (value, field) => {
            if (!this._isTargetAllowed(field)) {
                delete this.targetFilters[field];
            }
        });

        if (this.targetFilters && Object.keys(this.targetFilters)?.length === 0) {
            this.targetFilters = undefined;
        }

        this._updateCostDriverDropdown();
    }


    protected _updateCostDriverDropdown() {
        const costDriverIds = [...new Set([...this.costDrivers
            .filter(x => x.methodId === this.methodId)
            .map(x => x.costDriverId)])];
        const orderedCostDrivers = _.sortBy(
            _.map(costDriverIds, x => this._definitions.costDriver[x]),
            "orderBy"
        );

        this._defaultCostDriver = _.first(orderedCostDrivers);

        this._costDriverDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: _.map(orderedCostDrivers, x => x),
        });

        if (!costDriverIds.includes(this.costDriverId)) {
            this.costDriverId = undefined;
        }
    }


    _isValid(): boolean {
        return super._isValid()
            && this.methodId != null;
    }

}
