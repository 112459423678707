import { Dictionary } from "lodash";
import { Injectable, inject } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { LgApplicationInsightsService } from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";


@Injectable({
    providedIn: "root"
})
export class PageTelemetryService {

    private _lgApplicationInsights = inject(LgApplicationInsightsService);
    private _lgConsole = inject(LgConsole);

    trackTime(name: string, duration: number, properties?: Dictionary<any>): void {
        const appInsights: ApplicationInsights = this._lgApplicationInsights.appInsights;

        if (appInsights == null) {
            this._lgConsole.error("ApplicationInsights is not configured");
            return;
        }

        appInsights.trackEvent({
            name,
            properties: {
                ...(properties ?? {}),
                duration,
                page: window.location.pathname,
            }
        });
    }
}
