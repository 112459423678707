<div class="mod-rules__form-wrap" *ngIf="_formVisible">
                <div class="mod-rules__form flex-stretch" #scrollerWrap>
                    <div class="mod-rules__form-inner" lgScrollable [style.height.px]="_scrollerHeight">
                        <div class="form-row form-row--input" *ngIf="true">
                <div class="form-row__label">{{ 'APP._RulesDialog.EditForm.Name' | lgTranslate }}</div>
                <div class="form-row__controls "><input class="input input--wide" type="text" [(ngModel)]="name" [disabled]="isReadonly" /></div>
            </div>

            <div class="form-row form-row--input" *ngIf="true">
                <div class="form-row__label">{{ 'APP._RulesDialog.EditForm.CostDriver' | lgTranslate }}</div>
                <div class="form-row__controls "><lg-dropdown *ngIf="_costDriverDropdown != null" [disabled]="isReadonly" [definition]="_costDriverDropdown" [(current)]="costDriverId" (currentChange)="_updateRuleAfterChanges()" [emptyAcceptable]="false"></lg-dropdown></div>
            </div>

            <div class="form-row form-row--tag-selector" *ngIf="true">
                <div class="form-row__label">{{ 'APP._RulesDialog.EditForm.Source' | lgTranslate }}</div>
                <div class="form-row__controls form-row__controls--no-overflow"><lg-tags-selector *ngIf="_ruleFilterSelector != null" [disabled]="isReadonly">
                        <ng-container *ngFor="let item of sourceSelectorConfig">
                            <lg-tags-selector-tag *ngIf="_isSourceAllowed( item.uid )" [tagName]="item.name" [current]="filters && filters[item.uid]" (currentChange)="_onFilterChange( item.uid, $event )" [idType]="item.keyType || 'string'" [getOptions]="_ruleFilterSelector.getOptionsCallback( item, null )" [getOptionDisplayName]="_ruleFilterSelector.getDisplayNameCallback( item )" [getOptionOrderBy]="_ruleFilterSelector.getOrderByCallback( item )"></lg-tags-selector-tag>
                        </ng-container>
                    </lg-tags-selector></div>
            </div>

            <div class="form-row form-row--tag-selector" *ngIf="_maxSubAccounts > 0">
                <div class="form-row__label">{{ '.EditForm.SubAccounts' | lgTranslate }}</div>
                <div class="form-row__controls "><ng-container *ngIf="_maxSubAccounts === 1">
                            <lg-dropdown *ngIf="_subAccountDropdown != null" [definition]="_subAccountDropdown" [(current)]="subAccounts[0]" [emptyAcceptable]="false" [condensed]="true" [matchWidth]="false" className="lg-dropdown lg-dropdown--condensed"></lg-dropdown>
                        </ng-container>
                        <ng-container *ngIf="_maxSubAccounts > 1">
                            <lg-tags-selector-tag [disabled]="isReadonly" [class.lg-tags-selector-tag--invalid]="subAccounts?.length < 1" [tagName]="'APP._.SubAccount' | lgTranslate" [(current)]="subAccounts" idType="string" [getOptions]="_getSubAccountOptionsCallback()" [getOptionDisplayName]="_getDisplayNameCallback()" [getOptionOrderBy]="_getOrderByCallback()" [hideWhenEmpty]="false"></lg-tags-selector-tag>
                        </ng-container></div>
            </div>
                    </div>
                </div>
                <div class="mod-rules__form-buttons" *ngIf="!isReadonly">
                    <div class="flexrow">
                        <div class="flex-stretch">
                            <lg-button *ngIf="!_isNewRule()" textLc="APP._RulesDialog.EditForm.Delete" buttonClass="button--error button--condensed" (click)="_delete()"></lg-button>
                        </div>
                        <div>
                            <lg-button textLc="APP._RulesDialog.EditForm.Close" buttonClass="button--condensed" (click)="close()" style="margin-right: 8px"></lg-button>

                            <lg-button [isDisabled]="!isChanged()" textLc="APP._RulesDialog.EditForm.Undo" buttonClass="button--condensed" (click)="reset()" style="margin-right: 8px"></lg-button>

                            <lg-button [isDisabled]="!isChanged() || !_isValid()" textLc="APP._RulesDialog.EditForm.Save" buttonClass="button--primary button--condensed" (click)="save()" style="margin-right: 8px"></lg-button>

                            <lg-button [isDisabled]="!isChanged() || !_isValid()" textLc="APP._RulesDialog.EditForm.SaveAndNew" buttonClass="button--primary button--condensed" (click)="saveAndNew()"></lg-button>
                        </div>
                    </div>
                </div>
            </div>