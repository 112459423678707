import { InjectionToken } from "@angular/core";
import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";


export const APP_BANNER_CONFIG = new InjectionToken<SidebarHelpStandardLinks>("AppBannerConfig");


export interface IAppBannerConfig {
    heading: string;
    body: string;
    buttonText: string;
    buttonLink: string;
}
