import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import { RulesGateway } from "../../base/gateway/rules-gateway";
import {
    DeleteGroupArguments,
    DeleteRuleArguments,
    RuleGroup,
    SaveGroupArguments,
    SaveGroupResult,
    SaveRuleArguments,
    SaveRuleResult,
    SelectCostArguments,
    SelectCostDriverArguments,
    SelectCostResult,
    SelectFiltersDataArguments,
    SelectFiltersSchema,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../base/gateway/rules-gateway.types";

import { CostDriverInfoExt, RuleExt } from "./resource-specification-rules-gateway.types";
import { Observable } from "rxjs";


@Injectable()
export class ResourceSpecificationRulesGateway extends AppServerGatewayBase implements RulesGateway<RuleExt, CostDriverInfoExt> {

    selectRules(args: SelectRulesArguments): Observable<RuleExt[]> {
        return this._get<RuleExt[]>("rules/accountToResource",
            { params: args });
    }

    selectRuleGroups(args: SelectRuleGroupArguments): Observable<RuleGroup[]> {
        return this._get<RuleGroup[]>("rules/accountToResource/groups",
            { params: args });
    }

    selectCostDrivers(args: SelectCostDriverArguments): Observable<CostDriverInfoExt[]> {
        return this._get<CostDriverInfoExt[]>("rules/accountToResource/costDriver",
            { params: args });
    }

    selectFiltersSchema(args: SelectFiltersSchemaArguments): Observable<SelectFiltersSchema> {
        return this._get<SelectFiltersSchema>("rules/accountToResource/filters/schema",
            { params: args });
    }

    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountToResource/filters/data",
            { params: args });
    }

    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountToResource/filters/targetData",
            { params: args });
    }

    saveGroup(args: SaveGroupArguments) {
        return this._post<SaveGroupResult>("rules/accountToResource/saveGroup",
            { body: args });
    }

    saveRule(args: SaveRuleArguments) {
        return this._post<SaveRuleResult>("rules/accountToResource/save",
            { body: args });
    }

    deleteGroup(args: DeleteGroupArguments) {
        return this._post<void>("rules/accountToResource/deleteGroup",
            { body: args });
    }

    deleteRule(args: DeleteRuleArguments) {
        return this._post<void>("rules/accountToResource/delete",
            { body: args });
    }

    selectCost(args: SelectCostArguments) {
        return this._getMaybeStale<SelectCostResult[]>("rules/accountToResource/cost",
            { params: args });
    }
}
