import { Component, inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { RuleEditorComponentBase } from "../base/rule-editor-component-base";
import * as _ from "lodash";
import { LoadManager } from "@logex/load-manager";
import { SOURCE_RULE_FILTER_SELECTOR } from "../base/types";
import { ResourceSpecificationRulesGateway } from "./gateways/resource-specification-rules-gateway.service";
import { CostDriverInfoExt, RuleExt } from "./gateways/resource-specification-rules-gateway.types";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { dropdownFlat } from "@logex/framework/utilities";

@Component({
    selector: "mod-resource-specification-rule-editor",
    templateUrl: "./rule-editor.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.ResourceSpecification"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        LoadManager,
        ResourceSpecificationRulesGateway,
    ],
})
export class ResourceSpecificationRulesEditorComponent extends RuleEditorComponentBase implements OnInit {

    protected _gateway = inject(ResourceSpecificationRulesGateway);

    @Input() id: number;
    @Input() isReadonly: boolean;
    @Input() name: string;
    @Input() costDrivers: CostDriverInfoExt[];
    @Input() costDriverId: number;
    @Input() sourceSelectorConfig: RuleFilterInfo[];
    @Input() filters: Record<string, unknown[]>;
    @Input() targetFilters: Record<string, unknown[]>;
    @Input() targetSelectorConfig: RuleFilterInfo[];
    @Input() resourceTypeCode: string;

    protected _resourceTypeDropdown: IDropdownDefinition<any>;

    private _allowedUids: string[];


    protected _getFormValues(): Partial<RuleExt> {
        return {
            ...super._getFormValues(),
            resourceTypeCode: this.resourceTypeCode,
        }
    }


    _isValid(): boolean {
        return super._isValid()
            && (this._resourceTypeDropdown == null || this.resourceTypeCode != null);
    }


    protected _updateRuleAfterChanges(): void {
        super._updateRuleAfterChanges();

        const costDriverDict = _.keyBy(this.costDrivers, x => x.costDriverId);
        const costDriverInfo = costDriverDict[this.costDriverId];

        this._allowedUids = costDriverInfo?.uids;

        // Clear all target fields that are not allowed in this cost driver
        _.forOwn(this.filters, (value, field) => {
            if (!this._allowedUids?.includes(field)) {
                delete this.filters[field];
            }
        });

        if (this?.filters && Object.keys(this?.filters)?.length === 0) {
            this.filters = undefined;
        }

        // Update resource type dropdown
        let resourceTypeDropdownEntries: Array<{ id: string; name: string; }>;
        if (costDriverInfo?.resourceTypeCodes != null) {
            resourceTypeDropdownEntries = _.map(costDriverInfo?.resourceTypeCodes, id => ({
                id,
                name: this._definitions.getDisplayName("resourceType", id),
            }));
        }

        this._resourceTypeDropdown = resourceTypeDropdownEntries?.length > 0 ? dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: resourceTypeDropdownEntries,
        }) : null;

        if (!_.some(costDriverInfo?.resourceTypeCodes, x => x === this.resourceTypeCode)) {
            this._onResourceTypeSelected(costDriverInfo?.resourceTypeCodes != null
                ? _.first(costDriverInfo?.resourceTypeCodes) : null);
        }
    }


    _onResourceTypeSelected(value: string): void {
        if (this.resourceTypeCode === value) return;
        this.resourceTypeCode = value;

        this._updateRuleAfterChanges();
    }


    _isSourceAllowed(field: string): boolean {
        return _.includes(this._allowedUids, field);
    }

}
