import { RuleFilterInfo } from "../rule-filter-selector.types";
import * as _ from "lodash";
import { AppDefinitions } from "@shared";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { firstValueFrom } from "rxjs";

export async function loadTargetFilters(
    filtersSchema: RuleFilterInfo[],
    definitions: AppDefinitions,
    lgTranslate: LgTranslateService
): Promise<RuleFilterInfo[]> {
    const sourceSelectorConfig = mapTargetFilters(filtersSchema, lgTranslate);

    const schemaDefinitions = _.compact(
        _.uniq([
            ...sourceSelectorConfig.map(x => x.type),
        ])
    );

    await firstValueFrom(definitions.load(
        "costDriver",
        ...schemaDefinitions as any
    ));

    return sourceSelectorConfig;
}


export function mapTargetFilters(filters: RuleFilterInfo[], lgTranslate: LgTranslateService): RuleFilterInfo[] {
    return filters
        .map(x => (
            {
                ...x,
                name: x.name ?? lgTranslate.translate(x.nameLc, x.nameLcParameters ?? {}),
            }
        ))
        .sort(x => x.sortOrder);
}
