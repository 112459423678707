import * as _ from "lodash";

import { dropdownFlat } from "@logex/framework/utilities";
import { IDropdownDefinition } from "@logex/framework/ui-core";

import { IAppDefinitions } from "@shared/app-definitions.types";
import { AppDefinitions } from "@shared/app-definitions.service";


export function dropdownBoundToDefinition(config: {
    definitions: AppDefinitions;
    section: keyof IAppDefinitions;
    data?: any[];
    addEmptyOption?: boolean;
}): IDropdownDefinition<any> {

    const entries = [];

    if (config.addEmptyOption) {
        entries.push({ id: null, name: "-" });
    }

    const def = config.definitions.getSection(config.section);
    const values = config.data || Object.values(def.data).map(x => x[def.codeField]);

    entries.push(..._.map(values, x => def.data[x]
        ? { id: x, name: config.definitions.getDisplayName(config.section, x) }
        : { id: x, name: x }));

    return dropdownFlat({
        entryId: "id",
        entryName: "name",
        entries
    });
}
