import { IUser, NavigationProcessing, ProcessedNavNode } from "@logex/framework/lg-application";
import { Injectable } from "@angular/core";


@Injectable()
export class AppNavigationProcessing extends NavigationProcessing {

    evaluateAccess(node: ProcessedNavNode, parentNode: ProcessedNavNode, userInfo: IUser) {
        return this.evaluateAccessStrategy_PermissionsHideNoAccess(node, parentNode, userInfo);
    }


    evaluateNumbering(node: ProcessedNavNode, depth: number, numberings: number[]): boolean {
        if (node.id === "analytics") {
            return this.evaluateNumberingStrategy_None(node, depth, numberings);
        }
        return this.evaluateNumberingStrategy_All(node, depth, numberings);
    }
}
