import { Pipe } from "@angular/core";
import { AsPipeBase } from "@logex/fadp";
import { AppDefinitions } from "@shared/app-definitions.service";


@Pipe({
    name: "asCostDriver"
})
export class AsCostDriverPipe extends AsPipeBase<AppDefinitions> {

    constructor() {
        super("costDriver");
    }

}
