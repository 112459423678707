import { NgModule } from "@angular/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormatMsToTimePipe } from "./pipes/format-ms-to-time.pipe";


@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        UiCoreModule,
        UiToolboxModule,
    ],
    declarations: [
        FormatMsToTimePipe,
    ],
    exports: [
        FormatMsToTimePipe,
    ],
    providers: [
        FormatMsToTimePipe,
    ],
})
export class UtilsModule {
}
