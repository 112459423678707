import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";

import { SaveOverviewArguments, SelectOverviewArguments, SubtaskItem } from "./subtask-management-gateway.types";


@Injectable()
export class SubtaskManagementGateway extends AppServerGatewayBase {

    selectOverview(args: SelectOverviewArguments) {
        return this._postQuery<SubtaskItem[]>("subtask/overview",
            { body: args });
    }

    saveOverview(args: SaveOverviewArguments) {
        return this._post("subtask/save",
            { body: args });
    }
}
