/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import {
    RuleGroup,
    SaveRulesArguments,
    SelectCostDriverArguments,
    SelectFiltersDataArguments,
    SelectFiltersSchema,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../bases/rules-base/gateways/rules-gateway.types";
import { RulesGateway, SaveRulesResult } from "../../bases/rules-base/gateways/rules-gateway";

import { CostDriverInfoExt, RuleExt } from "./edit-account-to-resource-rules-gateway.types";


@Injectable()
export class EditAccountToResourceRulesGateway extends AppServerGatewayBase implements RulesGateway<RuleExt, CostDriverInfoExt> {

    selectRules(args: SelectRulesArguments) {
        return this._get<RuleExt[]>("rules/accountToResource",
            { params: args });
    }


    selectRuleGroups(args: SelectRuleGroupArguments) {
        return this._get<RuleGroup[]>("rules/accountToResource/groups",
            { params: args });
    }


    selectCostDrivers(args: SelectCostDriverArguments) {
        return this._get<CostDriverInfoExt[]>("rules/accountToResource/costDriver",
            { params: args });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments) {
        return this._get<SelectFiltersSchema>("rules/accountToResource/filters/schema",
            { params: args });
    }


    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountToResource/filters/data",
            { params: args });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountToResource/filters/targetData",
            { params: args });
    }


    saveRules(args: SaveRulesArguments<RuleExt>) {
        return this._post<SaveRulesResult>("rules/accountToResource",
            { body: args });
    }
}
