export class AppFeatures {
    static ALL_DATA_TEMPLATES = "allDataTemplates";
    static DEFAULT_LEVELS_PATIENT_BILL = "defaultLevelsPatientBill";
    static EMPLOYEE_LEVEL_CALCULATION = "employeeLevelCalculation";
    static EXECUTING_CAREGIVER_LEVEL_CALCULATION = "executingCaregiverLevelCalculation";
    static FIELD_ACTIVITY_DIMENSION1_CODE = "fieldActivityDimension1Code"
    static FIELD_ACTIVITY_DIMENSION2_CODE = "fieldActivityDimension2Code"
    static FIELD_ACTIVITY_DIMENSION3_CODE = "fieldActivityDimension3Code"
    static FILTERS = "filters";
    static FLEXIBLE_ANALYTICS = "flexibleAnalytics";
    static HIDE_PAGES = "hidePages";
    static JOB_PLAN = "jobPlan";
    static NHS_SUBMISSION_EXPORT_SETTINGS = "nhsSubmissionExportSettings";
    static NL_REGULATION_NZA = "nlRegulationNza";
    static PRODUCTION_CHARACTERISTIC_SOURCE = "productionCharacteristicSource";
    static SE_REGULATION_SKR = "seRegulationSkr";
    static SECTOR_LOCALIZATION = "sectorLocalization";
    static SET_ACCOUNT_COST = "setAccountCost";
    static SET_ACCOUNT_STRUCTURE = "setAccountStructure";
    static SET_AGGREGATED_PRODUCTION = "setAggregatedProduction";
    static SET_COST_CENTRE_STRUCTURE = "setCostCentreStructure";
    static SET_HUMAN_RESOURCE_COST = "setHumanResourceCost";
    static SET_JOB_PLAN_STRUCTURE = "setJobPlanStructure";
    static SET_JOB_TITLE_STRUCTURE = "setJobTitleStructure";
    static SHOW_ACCOUNT_DIMENSION = "showAccountDimension";
    static SHOW_COST_CENTRE_REGULATION_TYPE = "showCostCentreRegulationType";
    static SHOW_EQUIPMENT = "showEquipment";
    static SHOW_HELP_TOOLTIPS = "showHelpTooltips";
    static SHOW_REGULATION_RESOURCE = "showRegulationResource";
    static SHOW_REGULATION_TASK = "showRegulationTask";
    static SUBTASKS = "subtasks";
    static TRACING = "tracing";
    static UK_REGULATION_NHSI = "ukRegulationNhsi";
}


export interface FieldActivityDimensionFeatureConfig {
    allowAllocation: boolean;
}

export interface AggregatedProductionFeatureConfig {
    forUk: boolean;
}


export interface TracingFeatureConfig {
    defaultGroupBy: _.Dictionary<string[]>;
}


export interface SectorLocalizationConfig {
    suffix: string;
}


export interface DefaultLevelsPatientBillFeatureConfig {
    activityTab: string[];
    costDimensionTab: string[];
}


// ----------------------------------------------------------------------------------
export interface HidePagesFeatureConfiguration {
    pages: string[];
}
