// Must be in sync with Costing.Api/Rule/Constants.cs
export enum RuleSection {
    SubaccountSpecification,
    AccountTransfer,
    ResourceSpecification,
    ResourceTransfer,
    TaskSpecification,
    SupportCostTransfer,
    SubtaskSpecification,
    MapToProduction,
}

export const rulesMenu = {
    OrganisationCosts: [
        RuleSection.SubaccountSpecification,
        RuleSection.AccountTransfer,
    ],
    AllocateToResource: [
        RuleSection.ResourceSpecification,
        RuleSection.ResourceTransfer,
    ],
    AllocateSupportCosts: [
        RuleSection.TaskSpecification,
        RuleSection.SupportCostTransfer,
    ],
    AllocateToProduction: [
        RuleSection.SubtaskSpecification,
        RuleSection.MapToProduction
    ]
};
