import { Filters } from "@shared/types/filters";
import { CostComparePanelConfig } from "./types";


export class DataSets {
    static REFERENCE = "references";
    static COST_BENCHMARK_TYPE = "costBenchmarkType";
    static COST_CENTRE_BENCHMARK_TYPE = "costCentreBenchmarkType";
    static COST_REGULATION_TYPE = "costRegulationType";
}


export const costTypePanelConfig: CostComparePanelConfig[] = [
    {
        filterBy: Filters.ACCOUNT_BENCHMARK_TYPE,
        dataSet: DataSets.COST_BENCHMARK_TYPE,
        title: "APP._Filters.AccountBenchmarkType"
    },
    {
        filterBy: Filters.COST_CENTRE_BENCHMARK_TYPE,
        dataSet: DataSets.COST_CENTRE_BENCHMARK_TYPE,
        title: "APP._Filters.CostCentreBenchmarkType"
    },
    {
        filterBy: Filters.ACCOUNT_REGULATION_TYPE,
        dataSet: DataSets.COST_REGULATION_TYPE,
        title: "APP._Filters.AccountRegulationType"
    }
];


export enum ViewType {
    Table = 1,
    Chart = 2,
}

export enum ComparisonColumn {
    REFERENCE = "REFERENCE",
    CURRENT_YEAR = "CURRENT_YEAR"
}

export enum ComparisonType {
    VALUE = "VALUE",
    UNIT_VALUE = "UNIT_VALUE"
}

export enum DiffValues {
    UnitAbsoluteDiff = "unitAbsoluteDiff",
    UnitRelativeDiff = "unitRelativeDiff",
    CostAbsoluteDiff = "costAbsoluteDiff",
    CostRelativeDiff = "costRelativeDiff",
}

export enum ValueType {
    Cost = 0,
    Fte = 1,
    TheatreTime = 2
}
