import { InjectionToken } from "@angular/core";

export interface PasteResult<TKey, TRow> {
    values: TRow[];
    updatedValues: TRow[];
    newValues: TRow[];
    deletedKeys: TKey[];
}

export const APP_VERSION_TITLE = new InjectionToken<string>("APP_VERSION_TITLE");
