import _ from "lodash";
import { Injectable } from "@angular/core";

import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";

import { Rule } from "../gateway/rules-gateway.types";
import { RulesPivotLevel1, RulesPivotLevel2 } from "./rules-pivot.types";
import { RuleSectionComponentBase } from "../../base/rule-section-component-base";


@Injectable()
export class RulesPivot extends PivotProviderBase {

    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "id",
            mergedKey: (row: Rule) => row.groupId,
            levelId: "row1",
            attachedColumns: [
                (
                    target: RulesPivotLevel1<RulesPivotLevel2<Rule>>,
                    row: Rule,
                    parentNode,
                    context: RuleSectionComponentBase<RulesPivotLevel2<Rule>>
                ) => {
                    // Find a group
                    const group = _.find(context._ruleGroups, { id: row.groupId });
                    if (group == null) {
                        throw new Error(`Rule group ${row.groupId} is not found`);
                    }

                    target.name = group.name;
                    target.position = group.position;
                    target.isEnabled = group.isEnabled;
                    target.isStandard = group.isStandard;
                }
            ],
            defaultOrderBy: ["position", "id"],
            filtersKeepChildless: true,

            filters: (context: RuleSectionComponentBase<RulesPivotLevel2<Rule>>) => ({
                filter: (filter, row1: RulesPivotLevel1<RulesPivotLevel2<Rule>>) => context._filterValue.length === 0 ||
                    (row1 == null || row1.filteredChildren == null || row1.children.some((row2: RulesPivotLevel2<Rule>) =>
                        row2.name.toLowerCase().includes(context._filterValue.toLowerCase())))
            }),

            children: {
                column: "position",
                store: "children",
                levelId: "row2",
                defaultOrderBy: ["position", "id"],

                filters: (context: RuleSectionComponentBase<RulesPivotLevel2<Rule>>) => ({
                    filter: (filter, row2: RulesPivotLevel2<Rule>) =>
                        context._filterValue === "" || row2.name.toLowerCase().includes(context._filterValue.toLowerCase()),
                }),
            },
        };
    }
}
