import { Injectable } from "@angular/core";
import { ExportBase } from "@shared/bases/export-base";
import { IAppDefinitions } from "@shared";
import { Column, TracingNodeExt } from "@shared/dialogs/tracing-dialog/tracing-dialog.types";
import { IXlsxFile, IXlsxWorksheet } from "@logex/framework/lg-exports/lg-excel-exports/xlsx.types";
import { IColumnExportDefinition } from "@logex/framework/lg-exports/lg-excel-exports/lg-excel.types";
import { forkJoin, Subject } from "rxjs";

const EXCEL_HIGHLIGHT_COLOR = "fff2e2";

interface ExportGroup {
    groupName: string;
    columnMap: Map<string, ExportColumn>;
    rows: ExportRow[];
    central: boolean;
}

interface ExportRow {
    data: Record<string, string | number>;
    active: boolean;
}

type ExportColumn = {
    dimension: string;
    name: string;
    hAlign: "left" | "right";
    format?: string;
};

type PreparedExportMap = Map<number, ExportGroup>;

@Injectable()
export class TracingDialogExport extends ExportBase {

    _translationNamespace = "APP._TracingDialog";


    // ----------------------------------------------------------------------------------
    //
    export(columns: Column[], data: TracingNodeExt[]): void {
        const infobox = this.getDefaultInfobox();
        const subjectsToComplete: Array<Subject<void>> = [];

        const preparedData = this._getPreparedExportData(columns, data);
        const worksheet: IXlsxWorksheet = {
            data: [],
            name: this.translate(".DialogTitle"),
            showGridLines: false
        };

        this._lgXlsx.renderInfoBox(worksheet, 2, infobox.column, infobox.info, undefined, infobox.labelSpan, infobox.textSpan, infobox.textAlign);

        let startingColumn = 0;
        preparedData.forEach(group => {
            const columnDefinitions: Array<IColumnExportDefinition<{ item: ExportRow; }>> = [];

            Array.from(group.columnMap).forEach(([dimension, column], i) => {
                columnDefinitions.push({
                    name: column.name,
                    nameGroup: i === 0 ? group.groupName : true,
                    separator: true,
                    contentFn: x => x.item.data[dimension],
                    hAlign: column.hAlign,
                    format: column.format,
                    bgColors: {
                        headerGroup: group.central ? EXCEL_HIGHLIGHT_COLOR : undefined,
                        oddEven: x => x.item.active ? EXCEL_HIGHLIGHT_COLOR : undefined
                    }
                });
            });

            const renderSubject = new Subject<void>();
            subjectsToComplete.push(renderSubject);

            this._lgXlsx.renderArray(
                worksheet,
                7,
                startingColumn,
                columnDefinitions,
                group.groupName,
                group.rows,
                undefined,
                () => {
                    renderSubject.next();
                    renderSubject.complete();
                },
            );

            startingColumn = startingColumn + group.columnMap.size + 1;
        });

        const file: IXlsxFile = {
            worksheets: [worksheet],
            creator: "LOGEX",
            lastModifiedBy: "LOGEX",
            created: new Date(),
            modified: new Date(),
            activeWorksheet: 0
        };
        const exportName = this.translate(".DialogTitle");

        forkJoin(subjectsToComplete).subscribe(() => {
            this._lgXlsx.renderBigHeader(worksheet, 0, 0, this.translate(".DialogTitle"), undefined);

            this._lgXlsx.save(file, `${this.getFilename(exportName)}.xlsx`, () => {
            });
        });
    }

    private _getPreparedExportData(columns: Column[], data: TracingNodeExt[]): PreparedExportMap {
        const preparedExportData: PreparedExportMap = new Map();
        const flatData: TracingNodeExt[] = [];
        let centralColumnPassed = false;

        for (const item of data) {
            if (item.grouped) {
                flatData.push(...item.grouped);
            } else {
                flatData.push(item);
            }
        }

        flatData.sort((a, b) => a.column - b.column);


        for (const item of flatData) {
            const originalColumn = columns.find(x => x.column === item.column);

            let currentGroup = preparedExportData.get(item.column);
            if (preparedExportData.get(item.column) == null) {
                const groupNameLc = originalColumn.titleLc;
                preparedExportData.set(item.column, { groupName: this.translate(groupNameLc), columnMap: new Map(), rows: [], central: item.central });
                currentGroup = preparedExportData.get(item.column);

                for (const dimension of item.dimensions) {
                    currentGroup.columnMap.set(
                        dimension.dimension,
                        { dimension: dimension.dimension, name: this.translate(dimension.nameLc), hAlign: "left" }
                    );

                    if (dimension.type !== "string") {
                        currentGroup.columnMap.set(
                            `${dimension.dimension}Name`,
                            {
                                dimension: `${dimension.dimension}Name`,
                                name: `${this.translate(dimension.nameLc)} ${this.translate(".Export.Name")}`,
                                hAlign: "left",
                            }
                        );
                    }
                }
                currentGroup.columnMap.set(
                    "cost",
                    {
                        dimension: "cost",
                        name: this.translate("APP._.Cost"),
                        hAlign: "right",
                        format: "money",
                    }
                );
            }

            const rowData: Record<string, string | number> = {};
            item.dimensions.forEach(dimension => {
                rowData[dimension.dimension] = dimension.type === "string"
                    ? dimension.value?.toString()
                    : dimension.value != null
                        ? this._definitions.getDisplayCode(dimension.type as keyof IAppDefinitions, dimension.value.toString())
                        : "";

                if (dimension.type !== "string") {
                    rowData[`${dimension.dimension}Name`] = dimension.value != null
                        ? this._definitions.getDisplayName(dimension.type as keyof IAppDefinitions, dimension.value)
                        : "";
                }
            });

            // For left columns we export outgoing cost. For right and for center columns we export incoming cost.
            centralColumnPassed = centralColumnPassed || item.central;
            rowData["cost"] = centralColumnPassed ? item.incomingCost : item.outgoingCost;

            currentGroup.rows.push({ data: rowData, active: originalColumn.activeNode?.dimensions === item.dimensions });
        }

        return preparedExportData;
    }
}
