/* eslint-disable */
export enum AppIcons {
    AddBelow = "icon-add-below",
    Duplicate = "icon-duplicate",
    ExportDownload = "icon-export-download",
    ExportSidebar = "icon-export-sidebar",
    ProductLogo_costing = "icon-product-logo_costing",
    Reload = "icon-reload",
    SidebarAnalyse = "icon-sidebar-analyse",
    SidebarData = "icon-sidebar-data",
    SidebarModel = "icon-sidebar-model",
    SidebarRules = "icon-rule",
}
