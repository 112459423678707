import * as _ from "lodash";
import { Directive, inject, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { LG_APP_SESSION, LG_FEATURES, LG_LAST_FILTER_STORAGE } from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LoaderArgumentsMap } from "@logex/load-manager";

import { PageServerGatewayBase } from "@shared/bases/page-server-gateway-base";
import { AppSession } from "@shared/types/app-session";
import { PageTelemetryService } from "@shared/services/page-telemetry.service";

import { AppDefinitions, DefinitionKey } from "../app-definitions.service";
import { AuthorizationBase } from "./authorization-base";
import { FilterFactory } from "../filter-factory";
import { FILTERS_LAYOUT, FiltersLayoutGroup } from "../types";
import { AppFeatures } from "../app-features";
import { omitFilters, PageComponentLoadManagerBase } from "@logex/fadp";


// ----------------------------------------------------------------------------------
@Directive()
export abstract class AppPageComponentBase<TPermissions extends AuthorizationBase>
    extends PageComponentLoadManagerBase<AppDefinitions>
    implements OnInit, OnDestroy {

    public _lgTranslate = inject(LgTranslateService);
    public _session = inject<AppSession>(LG_APP_SESSION);
    filterFactory = inject(FilterFactory);
    protected _pageTelemetry = inject(PageTelemetryService);
    protected _features = inject(LG_FEATURES);
    protected _lastFilterStorage = inject(LG_LAST_FILTER_STORAGE);

    public abstract _authorization: TPermissions;

    constructor() {
        super();

        this._timeCreated = performance.now();

        this._promptDialog = this._promptDialog.bindViewContainerRef(this._viewContainerRef);

        this._filter = this._configureFilters(this.filterFactory)
            .create(this, this._getFiltersLayout());
        this._filter.onChanged.subscribe(() => {
            // Refiltering should be performed only when page is prepared. Otherwise we can start reloading datasets during page loading
            if (this._prepared$.value) {
                this._refilter();
            }
        });
    }


    // ----------------------------------------------------------------------------------
    // Fields
    _prepared$ = new BehaviorSubject(false);
    _bootstrapDataLoaded$ = new BehaviorSubject(false);
    _destroyed$ = new ReplaySubject<void>(1);
    protected _gateway: PageServerGatewayBase;

    _filter: LgFilterSet<any, any>;
    protected abstract _filterStateStorageKey: string;

    // Timing
    protected _timeCreated: number;
    protected _timePrepared: number;
    protected _timeBootstrapDataLoaded: number;

    //
    protected _omitFilters = omitFilters;


    // ----------------------------------------------------------------------------------
    // Page activation

    protected _prepare(): Observable<any[]> {
        this._retrieveFilterState();

        return super._prepare();
    }


    protected _getRequiredDefinitions(): DefinitionKey[] {
        return [];
    }


    /**
     * When overridden should return default view-related settings for the page.
     */
    protected _getDefaultViewSettings(): any {
        return {};
    }


    /**
     * When overridden should return default data-related settings for the page.
     */
    protected _getDefaultPageSettings(): any {
        return {};
    }


    protected _addLoaders(args: LoaderArgumentsMap): void {
    }


    get _isReadonly(): boolean {
        return !this._authorization.edit || this._session.scenario.isLocked || false;
    }


    protected _activate(): void {

        // Supply telemetry
        this._timePrepared = performance.now();
        this._trackTime("Loading.Prepare", this._timePrepared - this._timeCreated);

        requestAnimationFrame(() =>
            this._prepared$.next(true));


        super._activate();

        /* if ( !this._pageSettings ) this._pageSettings = {} as any;
        _.merge( this._pageSettings, this.getDefaultPageSettings(), ( objValue, srcValue, key, obj, src ) => objValue );*/
    }


    // ----------------------------------------------------------------------------------
    //
    protected _getLoaderArguments(): LoaderArgumentsMap {
        return {
            clientId: () => this._session.clientId,
            scenarioId: () => this._session.scenarioId,
            filters: () => {
                let filters = _.omitBy(this._getLoaderFiltersArgument(), x => x == null);

                filters = this._addAllowedResourcesFilters(filters);

                return _.isEmpty(filters) ? undefined
                    : filters;
            },
        };
    }


    protected _addAllowedResourcesFilters(filters: _.Dictionary<string[] | number[] | boolean[]>): _.Dictionary<string[] | number[] | boolean[]> {
        return filters;
    }


    protected _addResourceFilter<TCode extends string | number | boolean>(
        filters: _.Dictionary<string[] | number[] | boolean[] | TCode[]>, filterName: string, allowedCodes: TCode[]
    ): _.Dictionary<string[] | number[] | boolean[] | TCode[]> {

        if (allowedCodes == null) return filters;

        filters = filters ?? {};

        const resourceFilter = filters[filterName] as TCode[];
        if (!_.isEmpty(resourceFilter)) {
            const lookup = new Set(allowedCodes);
            filters[filterName] = _.filter(resourceFilter, x => lookup.has(x));
        } else {
            filters[filterName] = allowedCodes;
        }

        return filters;
    }


    protected _getLoaderFiltersArgument(): _.Dictionary<string[] | number[] | boolean[]> {
        return undefined;
    }


    /**
     * When bootstrap data is loaded, calls [[[initFilters]] and sets [[isReady]].
     */
    protected _onBootstrapDataLoaded(): void {
        // Supply telemetry
        this._timeBootstrapDataLoaded = performance.now();
        this._trackTime("Loading.BootstrapDataLoaded", this._timeBootstrapDataLoaded - this._timePrepared);
        this._trackTime("Loading.Ready", this._timeBootstrapDataLoaded - this._timeCreated);

        super._onBootstrapDataLoaded();
    }


    protected _trackTime(name: string, duration: number) {
        this._pageTelemetry.trackTime(name, duration);
    }


    // ----------------------------------------------------------------------------------
    // Filters

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    protected _configureFilters(filterFactory: FilterFactory) {
        return filterFactory.define();
    }


    protected _getFiltersLayout(): FiltersLayoutGroup[] {
        const layout = FILTERS_LAYOUT;

        this._hideFiltersBasedOnFeature(layout);

        return layout;
    }


    protected _hideFiltersBasedOnFeature(layout: FiltersLayoutGroup[]): void {
        const featureConfig: _.Dictionary<{ lc: string; hidden?: boolean; }>
            = this._features.getFeature(AppFeatures.FILTERS)?.configuration?.filters;
        if (featureConfig != null) {
            for (const group of layout) {
                for (const filter of group.filters) {
                    const filterConfig = featureConfig[filter.filter];
                    if (filterConfig?.hidden) {
                        filter.hidden = true;
                    }
                }
            }
        }
    }


    protected _refilter(): void {
        this._loadVisibleDataSets();
    }


    _clearAllFilters(): void {
        this._filter.clearAll();
    }


    protected _retrieveFilterState(): void {
        this._filter.deserialize(this._lastFilterStorage.get(this._filterStateStorageKey), true);
    }


    protected _storeFilterState(): void {
        this._lastFilterStorage.store(this._filterStateStorageKey, this._filter.serialize());
    }


    // ----------------------------------------------------------------------------------
    //
    public ngOnDestroy(): void {
        this._storeFilterState();

        super.ngOnDestroy();
    }
}
