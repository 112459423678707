import { Component, inject, Input, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { LoadManager } from "@logex/load-manager";
import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR } from "../base/types";
import { SupportCostTransferRulesGateway } from "./gateways/support-cost-transfer-rules-gateway";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { TransferCostDriverInfoExt } from "../base/gateway/transfer-rule-with-method-gateway.type";
import { TransferRuleWithMethodEditorComponentBase } from "../base/transfer-rule-with-method-editor-component-base";

@Component({
    selector: "mod-support-cost-transfer-rules-editor",
    templateUrl: "./rule-editor.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.SupportCostTransfer"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        LoadManager,
        SupportCostTransferRulesGateway,
    ],
})
export class SupportCostTransferRulesEditorComponent extends TransferRuleWithMethodEditorComponentBase {

    protected _gateway = inject(SupportCostTransferRulesGateway);

    @Input() id: number;
    @Input() isReadonly: boolean;
    @Input() name: string;
    @Input() costDrivers: TransferCostDriverInfoExt[];
    @Input() costDriverId: number;
    @Input() sourceSelectorConfig: RuleFilterInfo[];
    @Input() filters: Record<string, unknown[]>;
    @Input() targetFilters: Record<string, unknown[]>;
    @Input() targetSelectorConfig: RuleFilterInfo[];
    @Input() methodId: number;

    protected _methodDefinition = this._definitions.resourceTaskTransferMethod;
    
}
