/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import { RulesGateway, SaveRulesResult } from "../../bases/rules-base/gateways/rules-gateway";
import {
    RuleGroup,
    SaveRulesArguments,
    SelectCostDriverArguments,
    SelectFiltersDataArguments,
    SelectFiltersSchema,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../bases/rules-base/gateways/rules-gateway.types";

import { CostDriverInfoExt, RuleExt } from "./edit-account-split-rules-gateway.types";


@Injectable()
export class EditAccountSplitRulesGateway extends AppServerGatewayBase implements RulesGateway<RuleExt, CostDriverInfoExt> {

    selectRules(args: SelectRulesArguments) {
        return this._get<RuleExt[]>("rules/accountSplit",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    selectRuleGroups(args: SelectRuleGroupArguments) {
        return this._get<RuleGroup[]>("rules/accountSplit/groups",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    selectCostDrivers(args: SelectCostDriverArguments) {
        return this._get<CostDriverInfoExt[]>("rules/accountSplit/costDriver",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    selectFiltersSchema(args: SelectFiltersSchemaArguments) {
        return this._get<SelectFiltersSchema>("rules/accountSplit/filters/schema",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    selectFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountSplit/filters/data",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    selectTargetFilterData(args: SelectFiltersDataArguments) {
        return this._get<RuleFilterOption[]>("rules/accountSplit/filters/targetData",
            { params: args });
    }


    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    saveRules(args: SaveRulesArguments<RuleExt>) {
        return this._post<SaveRulesResult>("rules/accountSplit",
            { body: args });
    }
}
