import * as _ from "lodash";
import { Component, inject, Injectable, TemplateRef, ViewChild } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { getDialogFactoryBase, IDialogComponent, LgDialogFactory, LgDialogRef, LgPromptDialog } from "@logex/framework/ui-core";
import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { IPasteButtonColumnInfo } from "@logex/framework/ui-toolbox";
import { LgPivotInstance, LgPivotInstanceFactory, LogexPivotService } from "@logex/framework/lg-pivot";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import { IDataCell, PasteDialogConfiguration } from "@logex/framework/ui-toolbox/copy-paste/copy-paste.types";

import { AppDefinitions } from "@shared/app-definitions.service";
import { HelpTooltip, PasteResult } from "@shared";
import { AppSession } from "@shared/types/app-session";
import { getMatchedPastedData } from "@shared/helpers/copyPaste/pasteHelpers";

import { ResourceManagementGateway } from "./gateways/resource-management-gateway";
import { ResourceItem } from "./gateways/resource-management-gateway.types";
import { ResourceItemKey, ResourcePivotLevel1 } from "./pivots/resource-management-pivot.types";
import { ResourcePivot } from "./pivots/resource-management-pivot.service";


export interface ResourceManagementDialogArguments {
    isReadonly: boolean;
}

export interface ResourceManagementDialogResult {
    doReload: boolean;
}


export interface ResourceManagementDialogComponent extends DialogMixin<ResourceManagementDialogComponent>,
    ModalResultDialogMixin<ResourceManagementDialogArguments, ResourceManagementDialogResult> {
}


@Component({
    selector: "mod-resource-management-dialog",
    templateUrl: "./resource-management-dialog.component.tshtml",
    providers: [
        ...useTranslationNamespace("APP._ResourceManagementDialog"),
    ],
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class ResourceManagementDialogComponent implements IDialogComponent<ResourceManagementDialogComponent> {

    _promptDialog = inject(LgPromptDialog);
    _definitions = inject(AppDefinitions);
    _dialogRef = inject(LgDialogRef<ResourceManagementDialogComponent>);
    _lgTranslate = inject(LgTranslateService);
    pivot = inject(ResourcePivot);
    protected _pivotService = inject(LogexPivotService);
    private _gateway = inject(ResourceManagementGateway);
    private _session = inject<AppSession>(LG_APP_SESSION);

    constructor() {
        const _pivotInstanceFactory = inject(LgPivotInstanceFactory);

        this._initMixins();

        this._copyToClipboard = this._copyToClipboard.bind(this);
        this._pasteFromClipboard = this._pasteFromClipboard.bind(this);
        this._preprocessClipboardData = this._preprocessClipboardData.bind(this);

        this._pivot = _pivotInstanceFactory.create<ResourcePivotLevel1, any>(this.pivot, this);
    }


    // ----------------------------------------------------------------------------------
    @ViewChild("headerTemplate", { static: true }) _dialogHeaderTemplate: TemplateRef<any>;

    _dialogClass = "lg-dialog lg-dialog--3col";
    _title = this._lgTranslate.translate(".DialogTitle");

    _isSaving = false;
    _isLoading = false;
    _isModified = false;

    _pivot: LgPivotInstance<ResourcePivotLevel1, any>;
    _originalData: ResourceItem[];
    _countData: _.Dictionary<number>;

    _helpTooltip = HelpTooltip;

    _clipboardDefCopy: IPasteButtonColumnInfo[] = this._getClipboardDef(true);
    _clipboardDefPaste: IPasteButtonColumnInfo[] = this._getClipboardDef(false);

    _pasteConfiguration: PasteDialogConfiguration = {
        allowNullsToZeros: false,
        allowSkipErrors: true,
        allowPasteMode: true,
        nullsToZeros: false,
        skipErrors: false,
        pasteMode: "update"
    };


    // ----------------------------------------------------------------------------------
    async _activate(): Promise<void> {
        this._initialized = true; // Set earlier so that we can switch tabs

        await this._load();
    }


    protected async _load(): Promise<void> {
        this._isLoading = true;

        this._gateway
            .selectOverview({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId
            })
            .subscribe(data => {
                this._originalData = _.cloneDeep(data);
                this._pivot.build(data);
            })
            .add(() => {
                this._isLoading = false;
            });

        this._gateway
            .selectCount({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId
            })
            .subscribe(data => {
                this._countData = _(data)
                    .keyBy(x => x.code)
                    .mapValues(x => x.used)
                    .value();
            });
    }


    // ----------------------------------------------------------------------------------
    protected _getClipboardDef(forCopy: boolean): IPasteButtonColumnInfo[] {
        let config: IPasteButtonColumnInfo[] = [
            {
                field: "code",
                name: this._lgTranslate.translate(".ResourcePivot.CodeHeader"),
                key: true,
                type: "string",
                unique: true,
                validateKey: (data: IDataCell): void => {
                    if (data.value.length < 4 || data.value.length > 10) {
                        data.valid = false;
                        data.error = this._lgTranslate.translate(".CopyPasteValidation.WrongCodeLength");
                    }
                }
            },
            {
                field: "name",
                name: this._lgTranslate.translate(".ResourcePivot.NameHeader"),
                key: false,
                type: "string",
                unique: true,
            },
        ];

        if (forCopy) {
            config = [
                ...config,
                {
                    field: "infoMark",
                    name: this._lgTranslate.translate("APP._.InfoMark"),
                    key: false,
                    type: "null",
                },
                {
                    field: "used",
                    name: this._lgTranslate.translate(".ResourcePivot.UsedHeader"),
                    key: false,
                    type: "string",
                    unique: true
                },
            ];
        }

        return config;
    }


    _copyToClipboard(): ResourcePivotLevel1[] {
        return this._pivot.filtered
            .filter(x => !x.isReadonly)
            .map(x => Object.assign({}, x, {
                code: x.code,
                name: x.name
            }));
    }


    _preprocessClipboardData(args: { columns: IPasteButtonColumnInfo[]; data: IDataCell[][]; }): IDataCell[][] {
        for (const row of args.data) {
            const nameCell = row[1];
            if (nameCell.value != null && nameCell.value.length >= 128) {
                nameCell.valid = false;
                nameCell.error = this._lgTranslate.translate(".CopyPasteValidation.WrongNameLength");
            }
        }
        return args.data;
    }


    _pasteFromClipboard(result: PasteResult<ResourceItemKey, ResourcePivotLevel1>): void {
        const processedData = getMatchedPastedData(this._pivot.all, result, "code");

        this._pivot.build(Array.from(processedData.values()));

        this._isModified = true;
    }


    // ----------------------------------------------------------------------------------
    //
    _addRow(): void {
        this._isModified = true;
        this._pivot.reattachLeafNode({
            code: "",
            name: "",
        } as ResourcePivotLevel1);
        this._pivot.refilter();
    }


    _markModified(row: ResourcePivotLevel1): void {
        row.isModified = true;
        this._isModified = true;
    }


    _deleteRow(row: ResourcePivotLevel1): void {
        this._pivot.removeLeafNode(row);
        this._pivot.refilter();

        this._isModified = true;
    }


    _isNameValid(row: ResourcePivotLevel1): boolean {
        return !!row.name && row.name.length < 128
            && !this._pivot.all
                .filter(x => x.code !== row.code)
                .map(x => x.name.trim())
                .includes(row.name.trim());
    }


    _isCodeValid(row: ResourcePivotLevel1): boolean {
        return row.code && row.code.length >= 4 && row.code.length <= 10
            && this._pivot.all
                .filter(x => x.code === row.code)
                .length === 1;
    }


    // ----------------------------------------------------------------------------------
    //
    private async _doSave(): Promise<boolean> {
        try {
            this._isSaving = true;

            const originalCodes = _.map(this._originalData, x => x.code);
            const currentCodes = _.map(this._pivot.all, x => x.code);

            const removedCodes = _.difference(originalCodes, currentCodes);
            const modifiedCodes = this._pivot.all
                .filter(x => x.isModified)
                .map(x => x.code);

            if (!removedCodes.length && !modifiedCodes.length) return true;

            const modifiedItems = this._pivot.all
                .filter(x => modifiedCodes.includes(x.code));

            await firstValueFrom(this._gateway
                .saveOverview({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    modified: modifiedItems
                        .map(x => ({
                            code: x.code,
                            name: x.name
                        }))
                        .concat(removedCodes.map(code => ({
                            code,
                            name: null
                        })))
                }))
                .catch(() => {
                    this._isSaving = false;
                });

            await firstValueFrom(this._definitions.reload("resource"));

            this._isSaving = false;

            this._resolve({ doReload: true });

            return true;
        } catch {
            return false;
        }
    }


    async _save(): Promise<boolean> {
        return this._doSave();
        // If _doSave returns true, then dialog-mixin will automatically close the dialog
    }


    // ----------------------------------------------------------------------------------
    //
    public _isChanged(): boolean {
        return this._isModified;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get _isValid(): boolean {
        return this._pivot?.all != null
            && this._pivot.all
                .filter(x => !x.isReadonly)
                .every(x =>
                    this._isNameValid(x)
                    && this._isCodeValid(x));
    }
}


@Injectable()
export class ResourceManagementDialog extends getDialogFactoryBase(ResourceManagementDialogComponent, "show") {
    constructor() {
        const _factory = inject(LgDialogFactory);

        super(_factory);
    }
}
