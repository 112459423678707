import _ from "lodash";
import { Injectable } from "@angular/core";
import { ILgLastFilterStorage } from "@logex/framework/lg-application";


@Injectable()
export class LastFilterStorageService implements ILgLastFilterStorage {

    private _prevStateStorageKey: string;
    private _state: _.Dictionary<string>;

    private _pagesAfterAca = ["allocateToProduction", "productionValues", "allocate-cost-analytics",
        "outputAnalytics", "costPerActivity", "allocate-to-production-analytics"];

    private _productionUnitPages = ["productionValues", "costPerActivity", "costPerProduct"];

    private _skipTransformWhenAllEnabled = [
        ["targetCostCentreTopgroupCode", "costCentreTopgroupCode"],
        ["targetCostCentreGroupCode", "costCentreGroupCode"],
        ["targetCostCentreSubgroupCode", "costCentreSubgroupCode"],
        ["targetCostCentreCode", "costCentreCode"],
        ["targetResourceRootCode", "resourceRootCode"],
        ["targetResourceCode", "resourceCode"],
        ["targetResourceTypeCode", "resourceTypeCode"],
        ["targetTaskCode", "taskCode"],
    ];

    // Non zero only filter is enabled by default for characteristics and production values pages
    private _nonZeroOnlyFilter?: string = "1";


    constructor() {
        this._state = {};
    }


    private _transform(mapping: _.Dictionary<string>): _.Dictionary<string> {
        _.each(mapping, (to, from) => {
            if (this._state[from] != null) {
                this._state[to] = this._state[from];
                delete this._state[from];
            }
        });

        return this._state;
    }


    get(filterStateStorageKey: string): _.Dictionary<string> {


        if (!filterStateStorageKey) return this._state;

        this._state["nonZeroOnly"] = this._nonZeroOnlyFilter;

        // Entering 2.3 from lower pages
        if (filterStateStorageKey === "allocate-cost-analytics" && this._pagesAfterAca.includes(this._prevStateStorageKey)) {
            return this._transform({
                "costCentreTopgroupCode": "targetCostCentreTopgroupCode",
                "costCentreGroupCode": "targetCostCentreGroupCode",
                "costCentreSubgroupCode": "targetCostCentreSubgroupCode",
                "costCentreCode": "targetCostCentreCode",
                "resourceRootCode": "targetResourceRootCode",
                "resourceCode": "targetResourceCode",
                "resourceTypeCode": "targetResourceTypeCode",
                "regulationResourceTopgroupCode": "targetRegulationResourceTopgroupCode",
                "regulationResourceGroupCode": "targetRegulationResourceGroupCode",
                "regulationResourceSubgroupCode": "targetRegulationResourceSubgroupCode",
                "regulationResourceCode": "targetRegulationResourceCode",
                "taskCode": "targetTaskCode",
                "regulationTaskCode": "targetRegulationTaskCode",
                "productionUnitCostCentreCode": "targetCostCentreCode",
            });
        }


        // Entering page with Production Unit CC filters
        if (this._productionUnitPages.includes(filterStateStorageKey)) {
            return this._transform({
                "targetCostCentreCode": "productionUnitCostCentreCode",
                "costCentreCode": "productionUnitCostCentreCode",
            });
        }


        // Leaving page with Production Unit CC filters to 2.3
        if (filterStateStorageKey === "allocate-cost-analytics" && this._productionUnitPages.includes(this._prevStateStorageKey)) {
            return this._transform({
                "productionUnitCostCentreCode": "targetCostCentreCode",
            });
        }


        // Leaving page with Production Unit CC filters to the other pages
        if (filterStateStorageKey !== "allocate-cost-analytics" && this._productionUnitPages.includes(this._prevStateStorageKey)) {
            return this._transform({
                "productionUnitCostCentreCode": "costCentreCode",
            });
        }


        // Leaving 2.3
        if (this._prevStateStorageKey === "allocate-cost-analytics") {

            // When both, Cost Centre & Cost Centre after transfer filters are enabled -
            // don't transform when going to a higher page
            if (
                _.some(this._skipTransformWhenAllEnabled, pair =>
                    _.every(pair, x => this._state[x] != null))
                && !this._pagesAfterAca.includes(filterStateStorageKey)
            ) {
                return this._state;
            }

            return this._transform({
                "targetCostCentreTopgroupCode": "costCentreTopgroupCode",
                "targetCostCentreGroupCode": "costCentreGroupCode",
                "targetCostCentreSubgroupCode": "costCentreSubgroupCode",
                "targetCostCentreCode": "costCentreCode",
                "targetResourceRootCode": "resourceRootCode",
                "targetResourceCode": "resourceCode",
                "targetResourceTypeCode": "resourceTypeCode",
                "targetRegulationResourceTopgroupCode": "regulationResourceTopgroupCode",
                "targetRegulationResourceGroupCode": "regulationResourceGroupCode",
                "targetRegulationResourceSubgroupCode": "regulationResourceSubgroupCode",
                "targetRegulationResourceCode": "regulationResourceCode",
                "targetTaskCode": "taskCode",
                "targetRegulationTaskCode": "regulationTaskCode",
            });
        }

        return this._state;
    }


    store(filterStateStorageKey: string, currentState: _.Dictionary<string>): void {
        this._state = currentState;
        this._prevStateStorageKey = filterStateStorageKey;
        this._nonZeroOnlyFilter = this._state["nonZeroOnly"];
    }
}
