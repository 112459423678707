import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";

import {
    LgAcceptLanguageInterceptor,
    LgAuthorizationInterceptor,
    LgMessageBusConnectionIdInterceptor,
    NavigationProcessing
} from "@logex/framework/lg-application";
import { LgFormatFloatPipe, LgFormatMoneyPipe, LgMarkSymbolsPipe, UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgChartsModule } from "@logex/framework/lg-charts";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { FlexibleModule, WidgetTypesRegistry } from "@logex/flexible";

import { UtilsModule } from "@modules/utils";
import { TracingDialogExport } from "@shared/dialogs/tracing-dialog/services/tracing-dialog.export";
import { DrilldownBreadcrumbSimpleComponent } from "@shared/components/drilldown-breadcrumb-simple/drilldown-breadcrumb-simple.component";
import { AsAccountDimension1Pipe } from "@shared/pipes/as-account-dimension-1.pipe";
import { DrilldownPivotTableComponent } from "@shared//components/drilldown-pivot-table/drilldown-pivot-table.component";

import { AsAccountBenchmarkTypePipe } from "./pipes/as-account-benchmark-type.pipe";
import { AsAccountGroupPipe } from "./pipes/as-account-group.pipe";
import { AsAccountPipe } from "./pipes/as-account.pipe";
import { AsAccountRegulationTypePipe } from "./pipes/as-account-regulation-type.pipe";
import { AsAccountSubgroupPipe } from "./pipes/as-account-subgroup.pipe";
import { AsAccountTypePipe } from "./pipes/as-account-type.pipe";
import { AsActivityGroupPipe } from "./pipes/as-activity-group.pipe";
import { AsActivityPipe } from "./pipes/as-activity.pipe";
import { AsActivitySubgroupPipe } from "./pipes/as-activity-subgroup.pipe";
import { AsActivityTopgroupPipe } from "./pipes/as-activity-top-group";
import { AsCaregiverPipe } from "./pipes/as-caregiver";
import { AsCostCentreGroupPipe } from "./pipes/as-cost-centre-group.pipe";
import { AsCostCentrePipe } from "./pipes/as-cost-centre.pipe";
import { AsCostCentreRegulationTypePipe } from "./pipes/as-cost-centre-regulation-type.pipe";
import { AsCostCentreSubgroupPipe } from "./pipes/as-cost-centre-subgroup.pipe";
import { AsCostCentreTopgroupPipe } from "./pipes/as-cost-centre-top-group.pipe";
import { AsCostCentreTypePipe } from "./pipes/as-cost-centre-type.pipe";
import { AsCostDriverPipe } from "./pipes/as-cost-driver.pipe";
import { AsDefinitionPipe } from "./pipes/as-definition.pipe";
import { AsDiagnosisPipe } from "./pipes/as-diagnosis.pipe";
import { AsProductGroupPipe } from "./pipes/as-product-group.pipe";
import { AsProductPipe } from "./pipes/as-product.pipe";
import { AsProductTopgroupPipe } from "./pipes/as-product-topgroup.pipe";
import { AsFinancialStatementCategoryPipe } from "./pipes/as-financial-statement-category.pipe";
import { AsFinancialStatementGroupPipe } from "./pipes/as-financial-statement-group.pipe";
import { AsJobTitlePipe } from "./pipes/as-job-title.pipe";
import { AsMachinePipe } from "./pipes/as-machine.pipe";
import { AsMaterialPipe } from "./pipes/as-material.pipe";
import { AsProductionUnitPipe } from "./pipes/as-production-unit.pipe";
import { AsProductionUnitTypePipe } from "./pipes/as-production-unit-type.pipe";
import { AsRegulationResourceCodePipe } from "./pipes/as-regulation-resource-code.pipe";
import { AsResourceBenchmarkTypePipe } from "./pipes/as-resource-benchmark-type.pipe";
import { AsResourcePipe } from "./pipes/as-resource.pipe";
import { AsResourceTypePipe } from "./pipes/as-resource-type.pipe";
import { AsRoomPipe } from "./pipes/as-room.pipe";
import { AsSegmentPipe } from "./pipes/as-segment.pipe";
import { AsSpecialismPipe } from "./pipes/as-specialism.pipe";
import { AsSubAccountPipe } from "./pipes/as-sub-account.pipe";
import { AsSubmissionResourcePipe } from "./pipes/as-submission-resource.pipe";
import { AsSubtaskPipe } from "./pipes/as-subtask.pipe";
import { AsTaskPipe } from "./pipes/as-task.pipe";


import { AppNavigationProcessing } from "./services/app-navigation-processing.service";
import { EditAccountRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-account-transfer-rules/gateways/edit-account-transfer-rules-gateway";
import { EditResourceRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-resource-transfer-rules/gateways/edit-resource-transfer-rules-gateway";
import { EditResourceTaskRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-resource-task-transfer-rules/gateways/edit-resource-task-transfer-rules-gateway";
import { EditAccountToResourceRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-account-to-resource-rules/gateways/edit-account-to-resource-rules-gateway";
import { EditResourceToTaskRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-resource-to-task-rules/gateways/edit-resource-to-task-rules-gateway";
import { EditResourceTaskToActivityRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-resource-task-to-activity-rules/gateways/edit-resource-task-to-activity-rules-gateway";
import { EditRulesDialogAuthorization } from "./dialogs/edit-rules-dialog/services/authorization.service";
import { TaskManagementDialog, TaskManagementDialogComponent } from "./dialogs/task-management-dialog/task-management-dialog.component";
import { TaskManagementGateway } from "./dialogs/task-management-dialog/gateways/task-management-gateway";
import { ResourceTaskOverviewPivot } from "./dialogs/task-management-dialog/pivots/task-management-pivot.service";
import { TracingChartComponent } from "./dialogs/tracing-dialog/components/tracing-chart/tracing-chart.component";
import { TracingDialog, TracingDialogComponent } from "./dialogs/tracing-dialog/tracing-dialog.component";
import { TracingDialogGateway } from "./dialogs/tracing-dialog/gateways/tracing-dialog-gateway";
import { CostCompareChartComponent } from "./components/cost-compare-chart/cost-compare-chart.component";

import { CostComparePanelComponent } from "./components/cost-compare-panel/cost-compare-panel.component";
import { CostComparePivot } from "./components/cost-compare-panel/pivots/cost-compare-pivot";
import { CostDistributionAreaChartComponent } from "./components/cost-distribution-area-chart/cost-distribution-area-chart.component";
import { RulesPivot } from "./dialogs/edit-rules-dialog/components/bases/rules-base/pivots/rules-pivot.service";
import { EditAccountSplitRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-split-rules/gateways/edit-account-split-rules-gateway";
import {
    SubAccountManagementDialog,
    SubAccountManagementDialogComponent
} from "./dialogs/sub-account-management-dialog/sub-account-management-dialog.component";
import { SubAccountManagementGateway } from "./dialogs/sub-account-management-dialog/gateways/sub-account-management-gateway";
import { SubAccountPivot } from "./dialogs/sub-account-management-dialog/pivots/sub-account-management-pivot.service";
import { EditResourceTaskSplitRulesGateway } from "./dialogs/edit-rules-dialog/components/edit-resource-task-split-rules/gateways/edit-resource-task-split-rules-gateway";
import { SubtaskManagementDialog, SubtaskManagementDialogComponent } from "./dialogs/subtask-management-dialog/subtask-management-dialog.component";
import { SubtaskManagementGateway } from "./dialogs/subtask-management-dialog/gateways/subtask-management-gateway";
import { ResourceSubtaskOverviewPivot } from "./dialogs/subtask-management-dialog/pivots/subtask-management-pivot.service";
import { CounterModule } from "./components/counter/counter.module";
import { ResourceManagementDialog, ResourceManagementDialogComponent } from "./dialogs/resource-management-dialog/resource-management-dialog.component";
import { ResourceManagementGateway } from "./dialogs/resource-management-dialog/gateways/resource-management-gateway";
import { ResourcePivot } from "./dialogs/resource-management-dialog/pivots/resource-management-pivot.service";
import { TracingWidgetComponent } from "./components/tracing-widget/tracing-widget.component";


@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        FormsModule,
        LgChartsModule.forRoot({ useNewColorPalette: true, useNewLabels: true }),
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        UiCoreModule,
        UiToolboxModule,
        UtilsModule,
        CounterModule,
        FlexibleModule,
    ],
    declarations: [
        AsAccountBenchmarkTypePipe,
        AsAccountDimension1Pipe,
        AsAccountGroupPipe,
        AsAccountPipe,
        AsAccountRegulationTypePipe,
        AsAccountSubgroupPipe,
        AsAccountTypePipe,
        AsActivityGroupPipe,
        AsActivityPipe,
        AsActivitySubgroupPipe,
        AsActivityTopgroupPipe,
        AsCaregiverPipe,
        AsCostCentreGroupPipe,
        AsCostCentrePipe,
        AsCostCentreRegulationTypePipe,
        AsCostCentreSubgroupPipe,
        AsCostCentreTopgroupPipe,
        AsCostCentreTypePipe,
        AsCostDriverPipe,
        AsDefinitionPipe,
        AsDiagnosisPipe,
        AsFinancialStatementCategoryPipe,
        AsFinancialStatementGroupPipe,
        AsJobTitlePipe,
        AsMachinePipe,
        AsMaterialPipe,
        AsProductGroupPipe,
        AsProductPipe,
        AsProductTopgroupPipe,
        AsProductionUnitPipe,
        AsProductionUnitTypePipe,
        AsRegulationResourceCodePipe,
        AsResourceBenchmarkTypePipe,
        AsResourcePipe,
        AsResourceTypePipe,
        AsRoomPipe,
        AsSegmentPipe,
        AsSpecialismPipe,
        AsSubAccountPipe,
        AsSubmissionResourcePipe,
        AsSubtaskPipe,
        AsTaskPipe,
        CostCompareChartComponent,
        CostComparePanelComponent,
        CostDistributionAreaChartComponent,
        DrilldownBreadcrumbSimpleComponent,
        DrilldownPivotTableComponent,
        ResourceManagementDialogComponent,
        SubAccountManagementDialogComponent,
        SubtaskManagementDialogComponent,
        TaskManagementDialogComponent,
        TracingChartComponent,
        TracingDialogComponent,
        TracingWidgetComponent,
    ],
    exports: [
        AsAccountBenchmarkTypePipe,
        AsAccountDimension1Pipe,
        AsAccountGroupPipe,
        AsAccountPipe,
        AsAccountRegulationTypePipe,
        AsAccountSubgroupPipe,
        AsAccountTypePipe,
        AsActivityGroupPipe,
        AsActivityPipe,
        AsActivitySubgroupPipe,
        AsActivityTopgroupPipe,
        AsCaregiverPipe,
        AsCostCentreGroupPipe,
        AsCostCentrePipe,
        AsCostCentreRegulationTypePipe,
        AsCostCentreSubgroupPipe,
        AsCostCentreTopgroupPipe,
        AsCostCentreTypePipe,
        AsCostDriverPipe,
        AsDefinitionPipe,
        AsDiagnosisPipe,
        AsFinancialStatementCategoryPipe,
        AsFinancialStatementGroupPipe,
        AsJobTitlePipe,
        AsMachinePipe,
        AsMaterialPipe,
        AsProductGroupPipe,
        AsProductPipe,
        AsProductTopgroupPipe,
        AsProductionUnitPipe,
        AsProductionUnitTypePipe,
        AsRegulationResourceCodePipe,
        AsResourceBenchmarkTypePipe,
        AsResourcePipe,
        AsResourceTypePipe,
        AsRoomPipe,
        AsSegmentPipe,
        AsSpecialismPipe,
        AsSubAccountPipe,
        AsSubmissionResourcePipe,
        AsSubtaskPipe,
        AsTaskPipe,
        CostCompareChartComponent,
        CostComparePanelComponent,
        CostDistributionAreaChartComponent,
        CounterModule,
        DrilldownBreadcrumbSimpleComponent,
        DrilldownPivotTableComponent,
        UtilsModule,
    ],
    providers: [
        // todo: move to app root
        {
            provide: NavigationProcessing,
            useClass: AppNavigationProcessing
        },
        { provide: HTTP_INTERCEPTORS, useClass: LgAcceptLanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LgMessageBusConnectionIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LgAuthorizationInterceptor, multi: true },
        CostComparePivot,
        EditAccountRulesGateway,
        EditAccountSplitRulesGateway,
        EditAccountToResourceRulesGateway,
        EditResourceRulesGateway,
        EditResourceTaskRulesGateway,
        EditResourceTaskSplitRulesGateway,
        EditResourceTaskToActivityRulesGateway,
        EditResourceToTaskRulesGateway,
        EditRulesDialogAuthorization,
        LgFormatFloatPipe,
        LgFormatMoneyPipe,
        LgMarkSymbolsPipe,
        ResourceManagementDialog,
        ResourceManagementGateway,
        ResourcePivot,
        ResourceSubtaskOverviewPivot,
        ResourceTaskOverviewPivot,
        RulesPivot,
        SubAccountManagementDialog,
        SubAccountManagementGateway,
        SubAccountPivot,
        SubtaskManagementDialog,
        SubtaskManagementGateway,
        TaskManagementDialog,
        TaskManagementGateway,
        TracingDialog,
        TracingDialogGateway,
        TracingDialogExport,
        WidgetTypesRegistry
    ]
})
export class SharedModule {
    // empty
}
