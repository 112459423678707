import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";

import { SaveArguments, SelectArguments, SubAccountItem } from "./sub-account-management-gateway.types";


@Injectable()
export class SubAccountManagementGateway extends AppServerGatewayBase {

    selectSubAccounts(args: SelectArguments) {
        return this._postQuery<SubAccountItem[]>("subAccount/list",
            { body: args });
    }

    saveOverview(args: SaveArguments) {
        return this._post("subAccount/save",
            { body: args });
    }
}
