import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ReloadTriggerService {
    
    trigger = new Subject<void>();

}
