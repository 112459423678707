import { IScenario } from "@logex/framework/lg-application";
import { AppSession as AppSessionBase } from "@logex/fadp";

export interface AppScenario extends IScenario {
    description: string;
    date: Date;
    startDate: Date;
    endDate: Date;
    period: string;
    isDefault: boolean;
    isDeleted: boolean;
    isLocked: boolean;
    isPublished: boolean;
    countryCode: string;
}


export class AppSession extends AppSessionBase<AppScenario> {
}
