import { TransferRuleSectionComponentBase } from "./transfer-rule-section-component-base";
import { TransferRuleEditableFieldsExt } from "./gateway/transfer-rule-with-method-gateway.type";
import { TransferRulesWithMethodPivotLevel2 } from "@shared/dialogs/rules-dialog/components/base/pivots/rules-with-method-pivot.types";

export abstract class TransferRuleWithMethodSectionComponentBase 
    extends TransferRuleSectionComponentBase<TransferRulesWithMethodPivotLevel2> {

    protected _newRule(groupId: number, position: number): TransferRulesWithMethodPivotLevel2 {
        return {
            ...super._newRule(groupId, position),
            methodId: null,
        };
    }

    protected _getRulePropsForSaving(rule: TransferRuleEditableFieldsExt): TransferRuleEditableFieldsExt {
        return {
            ...super._getRulePropsForSaving(rule),
            methodId: rule.methodId,
        }
    }
}
