import { inject, Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { firstValueFrom, forkJoin } from "rxjs";

import {
    IAuthenticationService,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LG_FEATURES,
    LG_USERFLOW_SERVICE,
} from "@logex/framework/lg-application";
import { LG_LOCALIZATION_SETTINGS, LgTranslateStartupService } from "@logex/framework/lg-localization";

import { AppScenario } from "@shared/types/app-session";
import { configureMatomoCustomDimensions } from "@shared/services/matomo-configuration";
import { AppFeatures, SectorLocalizationConfig } from "@shared/app-features";
import { AppLgLocalizationSettings } from "@shared/services/app-lg-localization-settings";
import { rebuildNavigationSubject } from "@shared/app-navigation";
import { BootHelperServiceBase, Client } from "@logex/fadp";
import { AppUser } from "@shared";


@Injectable({providedIn: "root"})
export class BootHelperService extends BootHelperServiceBase<AppUser, AppScenario> {

    protected _authorizationService = inject(LG_AUTHORIZATION_SERVICE);

    private _authenticationService = inject<IAuthenticationService>(LG_AUTHENTICATION_SERVICE);
    private _localizationSettings = inject<AppLgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    private _translateStartup = inject(LgTranslateStartupService);
    private _userflowService = inject(LG_USERFLOW_SERVICE);
    private _features = inject(LG_FEATURES);
    private _datePipe = inject(DatePipe);


    protected async _fillMiscSessionInfo(client: Client, scenario: AppScenario) {

        // Get application features for scenario
        await this._features.load({
            clientId: client.id,
            scenarioId: scenario.id
        });

        // Configure section localization if set
        const sectorLocalizationSuffix = this._features.getFeature<SectorLocalizationConfig>(AppFeatures.SECTOR_LOCALIZATION)?.configuration?.suffix;
        if (sectorLocalizationSuffix != null) {
            await this._translateStartup.addLanguageOverlay(this._localizationSettings.preferredLanguage, sectorLocalizationSuffix);
            rebuildNavigationSubject.next();
        }

        // Resource restrictions
        [this._userInfo.costCentresRead, this._userInfo.costCentresModify] = await firstValueFrom(forkJoin([
            this._authorizationService.getUserResources("costcentre", "read"),
            this._authorizationService.getUserResources("costcentre", "modify"),
        ]));

        // Configure UserFlow with currently selected user and client organization
        this._userflowService.ready$.subscribe(() => {
            this._userflowService.patchUserAttrs({
                name: this._userInfo.name,
                email: this._authenticationService.user.login,
                organization: this._userInfo.ziekenhuiscode.toString()
            });
        });
    }

    protected async _configureMatomoDimensions(): Promise<void> {
        configureMatomoCustomDimensions(this._matomoTracker, {
            session: this._session,
            userInfo: this._userInfo,
            appInfo: this._appInfo,
        });
    }

    protected override async _fillScenario(client: Client, scenario: AppScenario): Promise<void> {
        await super._fillScenario(client, scenario);

        const startDate = new Date(scenario.startDate);
        const startMonthName = this._datePipe.transform(
            new Date(startDate.getFullYear(), startDate.getMonth()),
            "MMMM"
        );
        const endDate = new Date(scenario.endDate);
        const endMonthName = this._datePipe.transform(
            new Date(endDate.getFullYear(), endDate.getMonth()),
            "MMMM"
        );

        this._session.scenario = {
            id: scenario.id,
            clientId: client.id,
            description: scenario.description,
            date: new Date(scenario.date),
            name: scenario.name,
            startDate,
            endDate,
            period: `${startMonthName} ${startDate.getFullYear()} - ${endMonthName} ${endDate.getFullYear()}`,
            isDefault: scenario.isDefault,
            isLocked: scenario.isLocked,
            isDeleted: scenario.isDeleted,
            isPublished: scenario.isPublished,
            countryCode: scenario.countryCode
        };
    }
}
