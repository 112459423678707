import { Pipe } from "@angular/core";
import { AsPipeBase } from "@logex/fadp";
import { AppDefinitions } from "@shared/app-definitions.service";

@Pipe({
    name: "asResourceType"
})
export class AsResourceTypePipe extends AsPipeBase<AppDefinitions> {

    constructor() {
        super("resourceType");
    }

}
