import { IAppInfo } from "@logex/framework/lg-application";

export class AppInfo implements IAppInfo {
    environment: string;
    fullAppName: string;
    overrideCurrencyPrefix: string;
    overrideCurrencySuffix: string;
    productId: string;
    toolInstanceName: string;
    userManualUrl: string;
    versionNumber: string;

    doNotDoGaTracking(): boolean {
        return false;
    }

    isProduction(): boolean {
        return !!(this.environment && this.environment.toLowerCase() === "production");
    }

}
