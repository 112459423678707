import { NgModule } from "@angular/core";
import { CounterComponent } from "./counter.component";
import { UtilsModule } from "@modules/utils";
import { CommonModule } from "@angular/common";


@NgModule({
    imports: [
        UtilsModule,
        CommonModule
    ],
    declarations: [
        CounterComponent,
    ],
    exports: [
        CounterComponent,
    ],
})
export class CounterModule {
    // empty
}
