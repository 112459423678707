import { Component, inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { SubaccountSpecificationRulesGateway } from "./gateway/subaccount-specification-rules-gateway.service";
import { RuleEditorComponentBase } from "../base/rule-editor-component-base";
import { of } from "rxjs";
import * as _ from "lodash";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownBoundToDefinition } from "@shared/helpers";
import { CostDriverInfoExt } from "./gateway/subaccount-specification-rules-gateway.types";
import { LoadManager } from "@logex/load-manager";
import { SOURCE_RULE_FILTER_SELECTOR } from "../base/types";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

@Component({
    selector: "mod-subaccount-specification-rule-editor",
    templateUrl: "./rule-editor.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.SubaccountSpecification"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        LoadManager,
        SubaccountSpecificationRulesGateway,
    ],
})
export class SubaccountSpecificationRulesEditorComponent extends RuleEditorComponentBase implements OnInit {

    protected _gateway = inject(SubaccountSpecificationRulesGateway);

    @Input() id: number;
    @Input() isReadonly: boolean;
    @Input() name: string;
    @Input() costDrivers: CostDriverInfoExt[];
    @Input() costDriverId: number;
    @Input() sourceSelectorConfig: RuleFilterInfo[];
    @Input() filters: Record<string, unknown[]>;
    @Input() subAccounts: string[];

    protected _subAccountDropdown: IDropdownDefinition<any>;
    protected _maxSubAccounts: number;
    protected _allowedSources: string[];


    ngOnInit() {
        super.ngOnInit();

        this._refillSubAccountDropdown();
    }


    private _refillSubAccountDropdown(): void {
        this._subAccountDropdown = dropdownBoundToDefinition({
            definitions: this._definitions,
            section: "subAccount",
        });
    }


    protected _getFormValues(): Record<string, unknown> {
        return {
            ...super._getFormValues(),
            subAccounts: this.subAccounts,
        }
    }


    protected _updateRuleAfterChanges(): void {
        super._updateRuleAfterChanges();

        const costDriverDict = _.keyBy(this.costDrivers, x => x.costDriverId);

        const costDriverInfo = costDriverDict[this.costDriverId];
        this._maxSubAccounts = costDriverInfo?.maxSubAccounts;

        this._allowedSources = costDriverInfo?.uids || [];
        for (const source of Object.keys(this.filters || {})) {
            if (!this._allowedSources.includes(source)) {
                delete this.filters[source];
            }
        }

        this.subAccounts = this._maxSubAccounts === 0
            ? null
            : (this.subAccounts || []).slice(0, this._maxSubAccounts);
    }


    protected _isSourceAllowed(field: string): boolean {
        return this._allowedSources?.includes(field);
    }


    protected _getSubAccountOptionsCallback() {
        return () => of(_.keys(this._definitions.subAccount));
    }

    protected _getDisplayNameCallback() {
        return id => this._definitions.getDisplayName("subAccount", id);
    }

    protected _getOrderByCallback() {
        return id => this._definitions.getOrderBy("subAccount", id);
    }

}
