/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";

import { NodeInfo, RelatedNodeInfo, Schema, SelectNodeArguments, SelectRelatedNodesArguments } from "./tracing-dialog-gateway.types";
import { RequestArguments } from "../../../bases/app-server-gateway-base.types";


@Injectable()
export class TracingDialogGateway extends AppServerGatewayBase {

    selectSchema(args: RequestArguments) {
        return this._get<Schema>("tracing/schema",
            { params: args });
    }


    selectNode(args: SelectNodeArguments) {
        return this._postMaybeStaleQuery<NodeInfo[]>("tracing/node",
            { body: args });
    }


    selectForwardNodes(args: SelectRelatedNodesArguments) {
        return this._postMaybeStaleQuery<RelatedNodeInfo[]>("tracing/forward",
            { body: args });
    }


    selectBackwardNodes(args: SelectRelatedNodesArguments) {
        return this._postMaybeStaleQuery<RelatedNodeInfo[]>("tracing/backward",
            { body: args });
    }
}
