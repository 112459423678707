import { PasteResult } from "@shared";


interface PasteResultKey {
    [key: string]: any,
}

interface DefaultPasteResultKey {
    code: string,
}

interface PasteResultData {
    isReadonly?: boolean,
    isModified?: boolean
}

export function getMatchedPastedData<T extends PasteResultData & K, K extends PasteResultKey = DefaultPasteResultKey>(
    sourceData: T[],
    pastedData: PasteResult<K, T>,
    codeKey: keyof K,
    deleteKeys = true
): Map<string, T> {
    const sourceDataByCode = new Map<string, T>();
    for (const row of sourceData) {
        sourceDataByCode.set(row[codeKey], row);
    }

    pastedData.updatedValues.forEach(updatedRow => {
        const sourceRow = sourceDataByCode.get(updatedRow[codeKey]);
        if (sourceRow.isReadonly) {
            return;
        }
        updatedRow.isModified = true;
        sourceDataByCode.set(updatedRow[codeKey], updatedRow);
    })

    pastedData.newValues.forEach(newRow => {
        newRow.isModified = true;
        sourceDataByCode.set(newRow[codeKey], newRow);
    })

    pastedData.deletedKeys.forEach(deletedRow => {
        const sourceRow = sourceDataByCode.get(deletedRow[codeKey]);
        if (sourceRow.isReadonly) {
            return;
        }
        if (deleteKeys) {
            sourceDataByCode.delete(deletedRow[codeKey]);
        } else {
            const emptyRow = { [codeKey]: deletedRow[codeKey] } as any;
            sourceDataByCode.set(deletedRow[codeKey], emptyRow);
        }
    })

    return sourceDataByCode;
}


// ----------------------------------------------------------------------------------
//
interface CorrectionPasteResultData extends PasteResultData {
    cost: number,
    fraction: number,
    comment: string
}

export function getUpdatedAndDeletedPastedCorrectionData<T extends CorrectionPasteResultData & K, K extends PasteResultKey>(
    sourceData: T[],
    pastedData: PasteResult<K, T>,
    getCode: (row: K) => string,
): Map<string, T> {
    const sourceDataByCode = new Map<string, T>();
    for (const row of sourceData) {
        sourceDataByCode.set(getCode(row), { ...row, fraction: row.fraction ? row.fraction / 100 : null });
    }

    pastedData.updatedValues.forEach(updatedRow => {
        const sourceRow = sourceDataByCode.get(getCode(updatedRow));
        if (sourceRow.isReadonly) {
            return;
        }

        const row = { ...sourceDataByCode.get(getCode(updatedRow)) };

        if (updatedRow.cost != null) {
            row.cost = updatedRow.cost;
            row.fraction = null;
        } else if (updatedRow.fraction != null) {
            row.cost = null;
            row.fraction = updatedRow.fraction / 100;
        }

        if (updatedRow.comment != null) {
            row.comment = updatedRow.comment;
        }

        sourceDataByCode.set(getCode(updatedRow), row);
    })

    pastedData.deletedKeys.forEach(deletedRow => {
        const sourceRow = sourceDataByCode.get(getCode(deletedRow));
        if (sourceRow.isReadonly) {
            return;
        }
        sourceDataByCode.delete(getCode(deletedRow));
    })

    return sourceDataByCode;
}
